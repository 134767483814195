import styled from 'styled-components';
import { Container, Row } from 'react-bootstrap';
import { StyledButton } from '../../componentes/general/styles/Boton.styles';

// Contenedor principal
export const StyledContainer = styled(Container)`
  height: 88vh;
  padding: 1rem 1rem 1rem 0;
  width: 100%;
  
  user-select: none;
  display: grid;
  grid-template-columns: 1fr 0.8fr;
  grid-template-rows: auto 1fr;
  grid-template-areas: "Title Title"
                       "ControlPanel Products";
  overflow-y: hidden;

  @media screen and (max-width:1200px){
    grid-template-columns: 1fr 1fr;

    @media screen and (max-width: 900px){
      display: flex;
      flex-direction: column;
    }
  }

  h1 {
    text-align: center;
    color: var(--white-color);
    margin-bottom: 1rem;
  }

  // Modificación de la scrollbar para Chrome y Edge
  ::-webkit-scrollbar{
    width: 7px;
    background-color: rgb(105,133,169);
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb{
    background-color: rgba(230,230,230);
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb:hover{
    background-color: rgba(150,150,150);
    border-radius: 5px;
  }
`;

// Estilos de las filas
export const StyledRow = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: center;
  gap: 30px;
  grid-area: ${props => props.gridarea};

  @media screen and (max-width: 900px){
    width: ${props => props.ancho||"100vw"};
  }
`;

export const StyledControlPanel= styled(StyledRow)`
  @media screen and (max-width: 900px){
    position: fixed;
    z-index: 2;
    height: 100%;
    top: 7.5vh;
    background: linear-gradient(
    rgb(54, 54, 82),
    15%,
    rgba(84, 106, 144, 0.9),
    60%,
    rgba(68, 111, 151, 0.9)
    );
    justify-content: center;
    gap: 0;
    display: flex;
    flex-direction: row;
    overflow: auto;
    left: ${props => props.mostrarpanel ? '0':'-100vw'};
    transform: ${props => props.state ? 'rotate(180deg)': 'rotate(0)'};
    transition: 0.5s ease all;
    
    div{
      max-width: 90%;    
      padding: 2rem 10px 10px 10px;
      max-height: none;
    }
  }
`;

// Estilos de las columnas
export const StyledCol = styled.div`
  max-height: 66vh;
  width: ${props => props.width};

  max-width: 800px;
  display: flex;
  flex-direction: column;
  align-items: ${p => p.productos ? 'flex-start' : 'center'};


  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
  }

  h1{
    @media screen and (max-width: 400px){
      font-size: 24px;

      @media screen and (max-width: 350px){
        font-size: 22px;
        width: 100vw;
        display: flex;
        justify-content: left;
        padding-left: 2rem;
      }
    }
  }


  overflow-y: auto;
`;


// Estilos del boton
export const StyledBoton = styled(StyledButton)`
  max-width: 200px;
  height: 50px;
  margin-bottom: 2rem;
  color: white;
  font-weight: bold;
  font-size: 0.9rem;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledBotonPanel = styled(StyledButton)`
  display: none;
  position:  absolute;
  top: 5rem;
  right: 1rem;
  z-index: 3;
  width: 20px;
  color: var(--active-blue-button);

  @media screen and (max-width: 900px) {
    display: flex;
    justify-content: center;
  }

  i{
    transform: ${props => props.state ? 'rotate(180deg)': 'rotate(0)'};
    transition: 0.5s ease all;
  }
`;