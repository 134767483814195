import { useState } from "react";

export const useMostrarModalDevolucion = (devoluciones = []) => {
  const [devolucion, setDevolucion] = useState({});

  const [mostrarModalDevolucion, setMostrarModalDevolucion] = useState(false);

  const manejarMostrarModalDevolucion = (devolucionId) => {
    const devolucionSeleccionada = devoluciones.find(
      (d) => d.id === devolucionId
    );
    setDevolucion(devolucionSeleccionada);
    setMostrarModalDevolucion(true);
  };

  const manejarCerrarVentana = () => {
    setMostrarModalDevolucion(false);
  };

  return {
    devolucion,
    mostrarModalDevolucion,
    manejarMostrarModalDevolucion,
    manejarCerrarVentana,
  };
};
