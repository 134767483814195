import { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-hot-toast";

export const useProductosSalidaRuta = (productos) => {
  const [productosDisponibles, setProductosDisponibles] = useState([]);
  const [productosSalidaRuta, setProductosSalidaRuta] = useState([]);

  // useEffect para inicializar los productosDisponibles a todos los productos en almacen
  useEffect(() => {
    if (!productos) return;
    setProductosDisponibles(productos);
  }, [productos]);

  const manejarSeleccionarProducto = (productoId) => {
    const productoSeleccionado = productosDisponibles.find(
      (p) => p.id === productoId
    );

    // Siempre que se selecciona un producto debe de tener un status confirmado de false y una cantidad de 0
    const productoActualizado = {
      ...productoSeleccionado,
      confirmado: false,
      cantidadSalidaRuta: 0,
    };

    // Remover producto de productos disponibles
    const nuevosProductosDisponibles = productosDisponibles.filter(
      (p) => p.id !== productoId
    );
    setProductosDisponibles(nuevosProductosDisponibles);

    // Agregar producto a productos salida ruta
    const nuevosProductosSalidaRuta = [
      productoActualizado,
      ...productosSalidaRuta,
    ];
    setProductosSalidaRuta(nuevosProductosSalidaRuta);
  };

  const manejarCambioCantidad = (nuevaCantidad, productoId) => {
    // Seleccionar producto cuya cantidad se quiere modificar
    const productoSeleccionado = productosSalidaRuta.find(
      (pv) => pv.id === productoId
    );

    // Obtener la cantidad disponible (cantidad en stock) de este producto
    const cantidadDisponible = productoSeleccionado.CANTIDAD;

    // si la nueva cantidad es superior a la cantidad disponible, hacer la nueva cantidad igual a la cantidad disponible
    if (nuevaCantidad > cantidadDisponible) {
      toast.error(
        `La cantidad seleccionada debe ser inferior o igual a ${cantidadDisponible}`,
        { duration: 4000 }
      );

      nuevaCantidad = cantidadDisponible;
    } else if (nuevaCantidad <= 0) {
      // React does not like it when a controlled input's value becomes null. If you'd like to clear the input, consider using an empty string "" instead. However, this can cause a problem if you need to differentiate between an empty input and a 0.

      // This way, you avoid setting a controlled input value to null, which React advises against, and you're also not setting it to 0, so the user can clear the input.
      nuevaCantidad = "";
    }

    //// Actualizar productos salida ruta con la nueva cantidad del producto seleccionado

    // Crear copia de productos salida ruta
    const nuevosProductosSalidaRuta = [...productosSalidaRuta];

    // Obtener posicion del producto seleccionado
    const indexProducto = productosSalidaRuta.findIndex(
      (producto) => producto.id === productoId
    );

    nuevosProductosSalidaRuta[indexProducto] = {
      ...productosSalidaRuta[indexProducto],
      cantidadSalidaRuta: nuevaCantidad,
    };
    setProductosSalidaRuta(nuevosProductosSalidaRuta);
  };

  const manejarConfirmarProducto = (productoId) => {
    const nuevosProductosSalidaRuta = productosSalidaRuta.map((p) => {
      if (p.id === productoId) {
        p.confirmado = !p.confirmado;
      }
      return p;
    });

    setProductosSalidaRuta(nuevosProductosSalidaRuta);
  };

  const manejarCancelarProducto = (productoId) => {
    const productoSeleccionado = {
      ...productosSalidaRuta.find((p) => p.id === productoId),
    };

    const nuevosProductosSalidaRuta = productosSalidaRuta.filter(
      (p) => p.id !== productoId
    );
    setProductosSalidaRuta(nuevosProductosSalidaRuta);

    const nuevosProductosDisponibles = [
      productoSeleccionado,
      ...productosDisponibles,
    ];
    setProductosDisponibles(nuevosProductosDisponibles);
  };

  const isValid = getIsValid(productosSalidaRuta);

  return {
    productosDisponibles,
    productosSalidaRuta,
    manejarSeleccionarProducto,
    manejarCambioCantidad,
    manejarConfirmarProducto,
    manejarCancelarProducto,

    isValid,
  };
};

// Funcion para desabilitar el boton para continuar con la salida a ruta
const getIsValid = (productosSalidaRuta) => {
  return (
    productosSalidaRuta.length > 0 &&
    productosSalidaRuta.every((p) => p.confirmado && p.cantidadSalidaRuta > 0)
  );
};
