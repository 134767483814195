import React, { useRef, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
//JM: Implementar Actions de la pagina de ventasSalidaRuta y activar este import
//import { registrarVenta } from "../../actions/ventaActions";
import {
  StyledButtonCancelar,
  StyledButtonConfirmar,
  StyledCol,
  StyledFormGroup,
  StyledModal,
  StyledRow,
} from "./styles/VentanaDevolucion.styles";
import { realizarDevolucionSalidaRuta } from "../../actions/salidaRutaActions";
// import { registrarSalidaRutaVenta } from "../../actions/salidaRutaActions";

const VentanaDevolucion = ({
  producto,
  salidaRuta,
  mostrarVenta,
  setMostrarVenta,
}) => {
  // Funcion para disparar acciones
  const dispatch = useDispatch();

  // Obtener fecha y hora de la venta
  const { date, hour } = getCurrentDate();

  const [observaciones, setObservaciones] = useState("");

  const [cantidad, setCantidad] = useState(0);

  // Estado deshabilitacion del boton
  const [disabledState, setDisabledState] = useState(false);

  //Referencias de los botones
  const confirmarRef = useRef(null);
  const cancelarRef = useRef(null);

  // Funcion para realizar la venta
  const manejarRealizarDevolucion = (e) => {
    e.preventDefault();

    if (cantidad > producto.CANTIDAD_DISPONIBLE) {
      toast.error(
        `La cantidad a devolver debe ser menos a la cantidad disponible de ${producto.CANTIDAD_DISPONIBLE}`
      );

      return;
    }

    // Disparar la accion para realizar aviso de visita y cerrar ventana
    const devolucion = {
      PRODUCTO_SALIDA_RUTA: producto.id,
      CANTIDAD_DEVOLUCION: cantidad,

      REPARTIDOR: salidaRuta.REPARTIDOR_NOMBRE,
      ATIENDE: salidaRuta.ATIENDE,

      SALIDA_RUTA: salidaRuta.id,
      PRODUCTO_DEVOLUCION: producto.PRODUCTO_RUTA,
      PRODUCTO_NOMBRE: producto.PRODUCTO_NOMBRE,
      OBSERVACIONES: observaciones,
      // STATUS: PENDIENTE,
      // ADMINISTRADOR: NULL,
    };
    
    setDisabledState(!disabledState);

    dispatch(realizarDevolucionSalidaRuta(devolucion, salidaRuta.id));

    // console.log(avisoVisita, salidaRuta.id);
    setMostrarVenta(false);
  };

  return (
    salidaRuta && (
      <StyledModal
        centered
        show={mostrarVenta}
        onHide={() => setMostrarVenta(false)}
      >
        <Modal.Header>
          <h4>Realizar devolucion de producto: {producto.PRODUCTO_NOMBRE}</h4>
        </Modal.Header>
        <Modal.Body>
          <StyledRow>
            <StyledCol xs={6}>
              <Form onSubmit={manejarRealizarDevolucion}>
                <StyledFormGroup controlId="observaciones">
                  <Form.Label>Motivo del devolución</Form.Label>
                  <Form.Control
                    autoComplete="off"
                    required
                    type="text"
                    onChange={(e) => setObservaciones(e.target.value)}
                  ></Form.Control>
                </StyledFormGroup>
                <StyledFormGroup controlId="cantidad">
                  <Form.Label>Cantidad a devolver</Form.Label>
                  <Form.Control
                    required
                    type="number"
                    onChange={(e) => setCantidad(Number(e.target.value))}
                    step="any"
                  ></Form.Control>
                </StyledFormGroup>
                <div>
                  <StyledButtonConfirmar
                    color="green"
                    ref={confirmarRef}
                    type="submit"
                    disabled={disabledState}
                    onFocus={() => confirmarRef.current.blur()}
                  >
                    Confirmar
                  </StyledButtonConfirmar>
                </div>
              </Form>
            </StyledCol>
          </StyledRow>
        </Modal.Body>
        <Modal.Footer>
          <StyledButtonCancelar
            color="red"
            variant="danger"
            onClick={() => setMostrarVenta(false)}
            ref={cancelarRef}
            onFocus={() => cancelarRef.current.blur()}
          >
            Cancelar
          </StyledButtonCancelar>
        </Modal.Footer>
      </StyledModal>
    )
  );
};

const getCurrentDate = () => {
  const currentDate = new Date();

  const date = currentDate.toLocaleDateString();
  const hour = currentDate.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });

  return { date, hour };
};

export default VentanaDevolucion;
