import {
  FAIL_SALIDA_RUTA_ACTUALIZAR,
  FAIL_SALIDA_RUTA_AVISO_VISITA,
  FAIL_SALIDA_RUTA_DETALLES,
  FAIL_SALIDA_RUTA_DEVOLUCION,
  FAIL_SALIDA_RUTA_LISTA,
  FAIL_SALIDA_RUTA_RECARGA,
  FAIL_SALIDA_RUTA_REGISTRAR,
  FAIL_SALIDA_RUTA_VENTA,
  REQUEST_SALIDA_RUTA_ACTUALIZAR,
  REQUEST_SALIDA_RUTA_AVISO_VISITA,
  REQUEST_SALIDA_RUTA_DETALLES,
  REQUEST_SALIDA_RUTA_DEVOLUCION,
  REQUEST_SALIDA_RUTA_LISTA,
  REQUEST_SALIDA_RUTA_RECARGA,
  REQUEST_SALIDA_RUTA_REGISTRAR,
  REQUEST_SALIDA_RUTA_VENTA,
  RESET_SALIDA_RUTA_ACTUALIZAR,
  RESET_SALIDA_RUTA_AVISO_VISITA,
  RESET_SALIDA_RUTA_DETALLES,
  RESET_SALIDA_RUTA_DEVOLUCION,
  RESET_SALIDA_RUTA_LISTA,
  RESET_SALIDA_RUTA_RECARGA,
  RESET_SALIDA_RUTA_REGISTRAR,
  RESET_SALIDA_RUTA_VENTA,
  SUCCESS_SALIDA_RUTA_ACTUALIZAR,
  SUCCESS_SALIDA_RUTA_AVISO_VISITA,
  SUCCESS_SALIDA_RUTA_DETALLES,
  SUCCESS_SALIDA_RUTA_DEVOLUCION,
  SUCCESS_SALIDA_RUTA_LISTA,
  SUCCESS_SALIDA_RUTA_RECARGA,
  SUCCESS_SALIDA_RUTA_REGISTRAR,
  SUCCESS_SALIDA_RUTA_VENTA,
} from "../constantes/salidaRutaConstantes";

// Exito contiene informacion del backend
export const salidaRutaListaReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case REQUEST_SALIDA_RUTA_LISTA:
      return { loading: true };

    case SUCCESS_SALIDA_RUTA_LISTA:
      return {
        loading: false,
        salidaRutas: payload.salida_rutas,
        page: payload.page,
        pages: payload.pages,
      };

    case FAIL_SALIDA_RUTA_LISTA:
      return { loading: false, error: action.payload };

    case RESET_SALIDA_RUTA_LISTA:
      return {};

    default:
      return state;
  }
};

// Exito contiene informacion del backend
export const salidaRutaDetallesReducer = (state = {}, action) => {
  switch (action.type) {
    case REQUEST_SALIDA_RUTA_DETALLES:
      return { loading: true };

    case SUCCESS_SALIDA_RUTA_DETALLES:
      return { loading: false, salidaRuta: action.payload };

    case FAIL_SALIDA_RUTA_DETALLES:
      return { loading: false, error: action.payload };

    case RESET_SALIDA_RUTA_DETALLES:
      return {};

    default:
      return state;
  }
};

// En la salidaRuta exito si contiene informacion del backend
export const salidaRutaActualizarReducer = (state = {}, action) => {
  switch (action.type) {
    case REQUEST_SALIDA_RUTA_ACTUALIZAR:
      return { loading: true };

    case SUCCESS_SALIDA_RUTA_ACTUALIZAR:
      return { loading: false, success: true };

    case FAIL_SALIDA_RUTA_ACTUALIZAR:
      return { loading: false, error: action.payload };

    case RESET_SALIDA_RUTA_ACTUALIZAR:
      return {};

    default:
      return state;
  }
};

// En la salidaRuta exito si contiene informacion del backend
export const salidaRutaRegistrarReducer = (state = {}, action) => {
  switch (action.type) {
    case REQUEST_SALIDA_RUTA_REGISTRAR:
      return { loading: true };

    case SUCCESS_SALIDA_RUTA_REGISTRAR:
      return { loading: false, salidaRuta: action.payload };

    case FAIL_SALIDA_RUTA_REGISTRAR:
      return { loading: false, error: action.payload };

    case RESET_SALIDA_RUTA_REGISTRAR:
      return {};

    default:
      return state;
  }
};

// En la salidaRuta borrar no es posible
export const salidaRutaVentaReducer = (state = {}, action) => {
  switch (action.type) {
    case REQUEST_SALIDA_RUTA_VENTA:
      return { loading: true };
    case SUCCESS_SALIDA_RUTA_VENTA:
      return { loading: false, venta: action.payload };
    case FAIL_SALIDA_RUTA_VENTA:
      return { loading: false, error: action.payload };
    case RESET_SALIDA_RUTA_VENTA:
      return {};
    default:
      return state;
  }
};

export const salidaRutaAvisoVisitaReducer = (state = {}, action) => {
  switch (action.type) {
    case REQUEST_SALIDA_RUTA_AVISO_VISITA:
      return { loading: true };
    case SUCCESS_SALIDA_RUTA_AVISO_VISITA:
      return { loading: false, success: true };
    case FAIL_SALIDA_RUTA_AVISO_VISITA:
      return { loading: false, error: action.payload };
    case RESET_SALIDA_RUTA_AVISO_VISITA:
      return {};
    default:
      return state;
  }
};

export const salidaRutaDevolucionReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case REQUEST_SALIDA_RUTA_DEVOLUCION:
      return { loading: true };
    case SUCCESS_SALIDA_RUTA_DEVOLUCION:
      return { loading: false, devolucion: payload };
    case FAIL_SALIDA_RUTA_DEVOLUCION:
      return { loading: false, error: payload };
    case RESET_SALIDA_RUTA_DEVOLUCION:
      return {};
    default:
      return state;
  }
};

export const salidaRutaRecargaReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case REQUEST_SALIDA_RUTA_RECARGA:
      return { loading: true };
    case SUCCESS_SALIDA_RUTA_RECARGA:
      return { loading: false, success: true };
    case FAIL_SALIDA_RUTA_RECARGA:
      return { loading: false, error: payload };
    case RESET_SALIDA_RUTA_RECARGA:
      return {};
    default:
      return state;
  }
};
