import { Table } from "react-bootstrap";
import styled from "styled-components";
import { StyledButton } from "../../general/styles/Boton.styles";

// Estilos de la tabla
export const TableStyled = styled(Table)`
  height: 100%;
  margin: 0;
  padding: 0;

  thead {
    height: 8vh;
  }

  tbody {
    height: 62vh;
    display: block;
    overflow: auto;

    -ms-overflow-style: none;
    scrollbar-width: none;
  
    &::-webkit-scrollbar{
      display: none;
    }
  }

  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
    color: white;
  }

  th,
  td {
    text-align: center;
    vertical-align: middle;
    color: white !important;
  }

  @media screen and (min-width: 900px) and (max-width: 975px){
    font-size: 14px;
  }

  @media screen and (max-width: 600px){
    font-size: 14px;
    @media screen and (max-width: 430px){
      font-size: 13.5px;
      @media screen and (max-width: 360px){
        font-size: 12.5px;
      }
    }
  }
`;

export const StyledBotonContainer = styled.div`
  display: flex;  
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;


export const StyledBoton = styled(StyledButton)`
    width: 35px;
    height: 30px;
    padding: 0;
    font-size: 20px;
    left: 0.5vw;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${props => props.fontcolor};
`;

export const StyledLockContainer = styled(StyledButton)`
  padding: 0;
  height: 35px;
  width: 35px;
  font-size: 25px;
  justify-content: center;
  align-items: center;
  display: flex;
  color: ${props => props.fontcolor};
  left: 0.5vw;

  &:disabled{
    color: ${props => props.fontcolor};
    background-color: var(--disabled-standard-color);
  }
`;
