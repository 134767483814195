import React, { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios"
import Loader from "../componentes/general/Loader";
import Mensaje from "../componentes/general/Mensaje";
import { RESET_SALIDA_RUTA_REGISTRAR } from "../constantes/salidaRutaConstantes";
import FormularioProductoSalidaRuta from "../componentes/SalidaRuta/FormularioProductoSalidaRuta";
import { pedirProductosLista } from "../actions/productoActions";
import { registrarSalidaRuta } from "../actions/salidaRutaActions";
import { toast } from "react-hot-toast";

import {
  StyledContainer,
  StyledCol,
  StyledRow,
  StyledBoton,
  StyledBotonPanel,
  StyledControlPanel,
} from "./styles/RealizarSalidaRutaProductos.styles";
import { useProductosSalidaRuta } from "../lib/hooks/useProductosSalidaRuta";
import { crearProductosSalidaRuta } from "../lib/utilis/salidaRutas";
// import VentanaMostrarModalSalidaRuta from "../componentes/SalidaRuta/VentanaMostrarModalSalidaRuta";
import FormularioSalidaRutaProductos from "../componentes/RealizarSalidaRutaProductos/FormularioSalidaRutaProductos";
import TicketSalidaRuta from "../componentes/SalidaRuta/TicketSalidaRuta";

const RealizarSalidaRutaProductos = () => {
  // Funcion para disparar las acciones
  const dispatch = useDispatch();
  // Funcion para navegar en la aplicacion
  const navigate = useNavigate();

  // Obtener productos del Redxu store
  const productoLista = useSelector((state) => state.productoLista);
  const {
    loading: productosLoading,
    productos,
    error: productosError,
  } = productoLista;

  // Hook para mostrar y ocultar panel de control en pantallas pequenas
  const [mostrarPanel, setMostrarPanel] = useState(true);

  // Obtener referencia de los botones
  const regresarRef = useRef(null);
  const panelMovilRef = useRef(null);
  const componentRef = useRef(null);

  // Estado deshabilitacion del boton
  const [disabledState, setDisabledState] = useState(false);

  // Obtener estado de registrar salida ruta del Redux store
  const salidaRutaRegistrar = useSelector((state) => state.salidaRutaRegistrar);
  const {
    loading: registrarLoading,
    salidaRuta,
    error: registrarError,
  } = salidaRutaRegistrar;

  // Estado para mostrar la salida ruta una vez que ya se ha registrado
  // const [mostrarModalSalidaRuta, setMostrarModalSalidaRuta] = useState(false);

  // Custom hook para obtener los productos de salida ruta
  const {
    productosDisponibles,
    productosSalidaRuta,
    manejarSeleccionarProducto,
    manejarCambioCantidad,
    manejarConfirmarProducto,
    manejarCancelarProducto,
    isValid,
  } = useProductosSalidaRuta(productos);

  // useEffect para mostrar registro de salida ruta
  // useEffect(() => {
  //   if (salidaRuta) {
  //     setMostrarModalSalidaRuta(true);
  //   }
  // }, [salidaRuta]);

  useEffect(() => {
    if (registrarLoading) {
      toast.loading("Registrando salida ruta");
    }

    if (salidaRuta) {
      toast.remove();
      // setMostrarModalSalidaRuta(true);
      toast.success("Salida ruta registrada exitosamente");
    }

    if (registrarError) {
      toast.dismiss();
      toast.error("Error al registrar salida ruta");
    }
  }, [registrarLoading, registrarError, salidaRuta]);

  // useEffect para pedir productos del Redux store
  useEffect(() => {

    const source = axios.CancelToken.source()

    if (!productos) {
      dispatch(pedirProductosLista(source.token));
    }

    return () => {

      source.cancel("Petición de lista productos cancelada")
    }
  }, [dispatch, productos]);

  const manejarRealizarSalidaRuta = (e) => {
    e.preventDefault();
    setDisabledState(!disabledState);

    const salidaRutaClientes = localStorage.getItem("salidaRuta")
      ? JSON.parse(localStorage.getItem("salidaRuta"))
      : null;

    const nuevosProductosSalidaRuta =
      crearProductosSalidaRuta(productosSalidaRuta);

    const salidaRutaProductos = {
      ...salidaRutaClientes,
      // Lo unico que agregamos aqui es la lista con los productos de salida ruta
      salidaRutaProductos: nuevosProductosSalidaRuta,
      STATUS: "PENDIENTE",
    };

    dispatch(registrarSalidaRuta(salidaRutaProductos));
  };

  // const manejarCerrarVentana = () => {
  //   // setMostrarModalSalidaRuta(false);
  //   dispatch({ type: RESET_SALIDA_RUTA_REGISTRAR });
  //   localStorage.removeItem("salidaRuta");
  //   navigate("/salida-rutas");
  // };

  const manejarRegresar = () => {
    navigate("/realizar-salida-ruta-clientes");
  };

  // Funcion para realizar la impresion del ticket
  const manejarPrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => {
      dispatch({ type: RESET_SALIDA_RUTA_REGISTRAR });
      localStorage.removeItem("salidaRuta");
      navigate("/salida-rutas");
    },
  });

  useEffect(() => {
    if (salidaRuta) {
      manejarPrint();
    }
  }, [manejarPrint, salidaRuta]);

  // Renderizar loading si se esta cargando la lista de productos
  if (productosLoading)
    return (
      <Loader />
    );

  // Renderizar mensaje de error si el servidor regresa un error al pedir la lista de productos
  if (productosError)
    return (
      <StyledContainer fluid>
        <Mensaje variant="danger">
          Hubo un error al cargar la lista de productos
        </Mensaje>
      </StyledContainer>
    );

  return (
    productos && (
      <>
        <StyledContainer fluid>
          <StyledRow gridarea={"Title"}>
            <StyledCol>
              <h1>Realizar Salida Ruta</h1>
              <div>
                <StyledBoton
                  variant="primary"
                  color="green"
                  ref={regresarRef}
                  onFocus={() => regresarRef.current.blur()}
                  onClick={manejarRegresar}
                >
                  Regresar
                </StyledBoton>
              </div>
            </StyledCol>
          </StyledRow>

          <div>
            <StyledBotonPanel
              onClick={() => setMostrarPanel((state) => !state)}
              state={mostrarPanel}
              color="standard"
              ref={panelMovilRef}
              onFocus={() => panelMovilRef.current.blur()}
            >
              <i className="fa-solid fa-arrow-right" />
            </StyledBotonPanel>
          </div>

          <StyledControlPanel
            gridarea={"ControlPanel"}
            mostrarpanel={mostrarPanel}
          >
            <StyledCol width={"100%"}>
              <FormularioSalidaRutaProductos
                manejarRealizarSalidaRuta={manejarRealizarSalidaRuta}
                manejarSeleccionarProducto={manejarSeleccionarProducto}
                productosDisponibles={productosDisponibles}
                isValid={isValid}
                disabledState={disabledState}
              />
            </StyledCol>
          </StyledControlPanel>

          <StyledRow gridarea={"Products"}>
            <StyledCol productos width={"90%"}>
              {productosSalidaRuta.map((ps) => (
                <FormularioProductoSalidaRuta
                  key={ps.id}
                  producto={ps}
                  manejarCambioCantidad={manejarCambioCantidad}
                  manejarConfirmarProducto={manejarConfirmarProducto}
                  manejarCancelarProducto={manejarCancelarProducto}
                />
              ))}
            </StyledCol>
          </StyledRow>
        </StyledContainer>

        {/* Mostrar salida ruta */}
        {/* Voy a usar un componente que pertenece a la pagina SalidaRuta */}
        {/* {mostrarModalSalidaRuta && (
          <VentanaMostrarModalSalidaRuta
            salidaRuta={salidaRuta}
            mostrarModalSalidaRuta={mostrarModalSalidaRuta}
            manejarCerrarVentana={manejarCerrarVentana}
          />
        )} */}
        <div style={{ display: "none" }}>
          <TicketSalidaRuta datosSalidaRuta={salidaRuta} ref={componentRef} />
        </div>
      </>
    )
  );
};

export default RealizarSalidaRutaProductos;
