import { RESET_PRODUCTO_LISTA } from "../constantes/productoConstantes";
import {
  FAIL_SALIDA_RUTA_ACTUALIZAR,
  FAIL_SALIDA_RUTA_AVISO_VISITA,
  FAIL_SALIDA_RUTA_DETALLES,
  FAIL_SALIDA_RUTA_DEVOLUCION,
  FAIL_SALIDA_RUTA_LISTA,
  FAIL_SALIDA_RUTA_RECARGA,
  FAIL_SALIDA_RUTA_REGISTRAR,
  FAIL_SALIDA_RUTA_VENTA,
  REQUEST_SALIDA_RUTA_ACTUALIZAR,
  REQUEST_SALIDA_RUTA_AVISO_VISITA,
  REQUEST_SALIDA_RUTA_DETALLES,
  REQUEST_SALIDA_RUTA_DEVOLUCION,
  REQUEST_SALIDA_RUTA_LISTA,
  REQUEST_SALIDA_RUTA_RECARGA,
  REQUEST_SALIDA_RUTA_REGISTRAR,
  REQUEST_SALIDA_RUTA_VENTA,
  RESET_SALIDA_RUTA_DETALLES,
  RESET_SALIDA_RUTA_LISTA,
  SUCCESS_SALIDA_RUTA_ACTUALIZAR,
  SUCCESS_SALIDA_RUTA_AVISO_VISITA,
  SUCCESS_SALIDA_RUTA_DETALLES,
  SUCCESS_SALIDA_RUTA_DEVOLUCION,
  SUCCESS_SALIDA_RUTA_LISTA,
  SUCCESS_SALIDA_RUTA_RECARGA,
  SUCCESS_SALIDA_RUTA_REGISTRAR,
  SUCCESS_SALIDA_RUTA_VENTA,
} from "../constantes/salidaRutaConstantes";
import axiosInstance from "../lib/axiosConfig";
import axios from "axios";
import { actualizarAccessToken } from "./sesionActions";

// Creador de acciones para pedir los salidaRutas del backend
export const pedirSalidaRutasLista =
  (search = "", role = "", cancelToken) =>
  async (dispatch) => {
    dispatch({ type: REQUEST_SALIDA_RUTA_LISTA });

    // setTimeout(async () => {
    try {
      let newSearch;
      if (search) {
        newSearch = search + "&role=" + role;
      } else {
        newSearch = "?role=" + role;
      }

      const { data } = await axiosInstance.get(
        `api/salida-rutas/${newSearch}`,
        {
          cancelToken,
        }
      );

      dispatch({ type: SUCCESS_SALIDA_RUTA_LISTA, payload: data });
    } catch (error) {
      if (axios.isCancel(error)) console.log(error.message);
      // Si el backend responde con un error 401 (no autorizado) intentar actualizar el token
      if (error.response && error.response.status === 401) {
        dispatch(
          actualizarAccessToken(
            pedirSalidaRutasLista,
            search,
            role,
            cancelToken
          )
        );
      } else {
        dispatch({ type: FAIL_SALIDA_RUTA_LISTA, payload: error.message });
      }
    }
    // }, 1500);
  };

// Creador de acciones para pedir el salidaRuta con el id del backend
export const obtenerSalidaRutaDetalles = (id) => async (dispatch) => {
  dispatch({ type: REQUEST_SALIDA_RUTA_DETALLES });

  try {
    const { data } = await axiosInstance.get(`api/salida-rutas/${id}/`);

    dispatch({ type: SUCCESS_SALIDA_RUTA_DETALLES, payload: data });
  } catch (error) {
    // Si el backend responde con un error 401 (no autorizado) intentar actualizar el token
    if (error.response && error.response.status === 401) {
      dispatch(actualizarAccessToken(obtenerSalidaRutaDetalles, id));
    } else {
      dispatch({ type: FAIL_SALIDA_RUTA_DETALLES, payload: error.message });
    }
  }
};

// Creador de acciones para actualizar salidaRuta del backend
export const cancelarSalidaRuta = (salidaRutaId) => async (dispatch) => {
  dispatch({ type: REQUEST_SALIDA_RUTA_ACTUALIZAR });

  try {
    const { data } = await axiosInstance.put(
      `api/cancelar-salida-ruta/${salidaRutaId}/`
    );

    dispatch({ type: SUCCESS_SALIDA_RUTA_ACTUALIZAR });
    dispatch({ type: RESET_SALIDA_RUTA_DETALLES });
    dispatch({ type: RESET_SALIDA_RUTA_LISTA });
    dispatch({ type: RESET_PRODUCTO_LISTA });
  } catch (error) {
    // Si el backend responde con un error 401 (no autorizado) intentar actualizar el token
    if (error.response && error.response.status === 401) {
      dispatch(actualizarAccessToken(cancelarSalidaRuta, salidaRutaId));
    } else {
      dispatch({ type: FAIL_SALIDA_RUTA_ACTUALIZAR, payload: error.message });
    }
  }
};

// Creador de acciones para registrar un nuevo salidaRuta en el backend
export const registrarSalidaRuta = (salidaRuta) => async (dispatch) => {
  dispatch({ type: REQUEST_SALIDA_RUTA_REGISTRAR });

  try {
    const { data } = await axiosInstance.post(
      "api/crear-salida-ruta/",
      salidaRuta
    );

    dispatch({ type: SUCCESS_SALIDA_RUTA_REGISTRAR, payload: data });
    dispatch({ type: RESET_SALIDA_RUTA_LISTA });
    dispatch({ type: RESET_PRODUCTO_LISTA });
  } catch (error) {
    // Si el backend responde con un error 401 (no autorizado) intentar actualizar el token

    if (error.response && error.response.status === 401) {
      dispatch(actualizarAccessToken(registrarSalidaRuta, salidaRuta));
    } else {
      dispatch({ type: FAIL_SALIDA_RUTA_REGISTRAR, payload: error.message });
    }
  }
};

export const registrarSalidaRutaVenta =
  (salidaRutaVenta, salidaRutaId) => async (dispatch) => {
    dispatch({ type: REQUEST_SALIDA_RUTA_VENTA });

    try {
      const { data } = await axiosInstance.post(
        `api/crear-venta-salida-ruta/${salidaRutaId}/`,
        salidaRutaVenta
      );

      dispatch({ type: SUCCESS_SALIDA_RUTA_VENTA, payload: data });
      dispatch({ type: RESET_SALIDA_RUTA_DETALLES });
      dispatch({ type: RESET_SALIDA_RUTA_LISTA });
      // dispatch({ type: RESET_PRODUCTO_LISTA });
    } catch (error) {
      // Si el backend responde con un error 401 (no autorizado) intentar actualizar el token
      if (error.response && error.response.status === 401) {
        dispatch(
          actualizarAccessToken(
            registrarSalidaRutaVenta,
            salidaRutaVenta,
            salidaRutaId
          )
        );
      } else {
        dispatch({ type: FAIL_SALIDA_RUTA_VENTA, payload: error.message });
      }
    }
  };

export const realizarAvisoVisita =
  (avisoVisita, salidaRutaId) => async (dispatch) => {
    dispatch({ type: REQUEST_SALIDA_RUTA_AVISO_VISITA });

    try {
      const { data } = await axiosInstance.put(
        `api/realizar-aviso-visita/${salidaRutaId}/`,
        avisoVisita
      );

      dispatch({ type: SUCCESS_SALIDA_RUTA_AVISO_VISITA });
      dispatch({ type: RESET_SALIDA_RUTA_LISTA });
    } catch (error) {
      // Si el backend responde con un error 401 (no autorizado) intentar actualizar el token

      if (error.response && error.response.status === 401) {
        dispatch(
          actualizarAccessToken(realizarAvisoVisita, avisoVisita, salidaRutaId)
        );
      } else {
        dispatch({
          type: FAIL_SALIDA_RUTA_AVISO_VISITA,
          payload: error.message,
        });
      }
    }
  };

export const realizarDevolucionSalidaRuta =
  (devolucion, salidaRutaId) => async (dispatch) => {
    dispatch({ type: REQUEST_SALIDA_RUTA_DEVOLUCION });

    try {
      const { data } = await axiosInstance.post(
        `api/devolver-producto-salida-ruta/${salidaRutaId}/`,
        devolucion
      );

      console.log("devolucion del back", data);

      dispatch({ type: SUCCESS_SALIDA_RUTA_DEVOLUCION, payload: data });
      dispatch({ type: RESET_SALIDA_RUTA_DETALLES });
      dispatch({ type: RESET_SALIDA_RUTA_LISTA });
      dispatch({ type: RESET_PRODUCTO_LISTA });
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(
          actualizarAccessToken(
            realizarDevolucionSalidaRuta,
            devolucion,
            salidaRutaId
          )
        );
      } else {
        dispatch({
          type: FAIL_SALIDA_RUTA_DEVOLUCION,
          payload: error.message,
        });
      }
    }
  };

export const realizarRecargaSalidaRuta =
  (recarga, salidaRutaId) => async (dispatch) => {
    dispatch({ type: REQUEST_SALIDA_RUTA_RECARGA });

    try {
      const { data } = await axiosInstance.put(
        `api/realizar-recarga-salida-ruta/${salidaRutaId}/`,
        recarga
      );

      dispatch({ type: SUCCESS_SALIDA_RUTA_RECARGA });
      dispatch({ type: RESET_SALIDA_RUTA_LISTA });
      dispatch({ type: RESET_PRODUCTO_LISTA });
      dispatch({ type: RESET_SALIDA_RUTA_DETALLES });
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(
          actualizarAccessToken(
            realizarRecargaSalidaRuta,
            recarga,
            salidaRutaId
          )
        );
      } else {
        dispatch({
          type: FAIL_SALIDA_RUTA_RECARGA,
          payload: error.message,
        });
      }
    }
  };
