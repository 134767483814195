// import axios from "axios";
import {
  FAIL_CLIENTE_LISTA,
  REQUEST_CLIENTE_LISTA,
  SUCCESS_CLIENTE_LISTA,
} from "../constantes/clienteConstantes";
import { actualizarAccessToken } from "./sesionActions";
import axiosInstance from "../lib/axiosConfig";
import axios from "axios";

// Creador de acciones para pedir los clientes del backend
export const pedirClientesLista = (cancelToken) => async (dispatch) => {
  dispatch({ type: REQUEST_CLIENTE_LISTA });

  // setTimeout(async () => {
  // Intentar pedir lista de productos al backend
  try {
    // Recibir la respuesta del backend y guardarla en data
    const { data } = await axiosInstance.get(
      "api/clientes-salida-ruta",
      {
        cancelToken,
      }
    );

    dispatch({ type: SUCCESS_CLIENTE_LISTA, payload: data });
  } catch (error) {
    if (axios.isCancel(error)) console.log(error.message);
    // Si el backend responde con un error 401 (no autorizado) intentar actualizar el token
    if (error.response && error.response.status === 401) {
      dispatch(actualizarAccessToken(pedirClientesLista, cancelToken));
    } else {
      dispatch({ type: FAIL_CLIENTE_LISTA, payload: error.message });
    }
  }
  // }, 1500);
};
