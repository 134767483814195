import { useState } from "react";
import { obtenerValoresFiltroDevoluciones } from "../utilis/devoluciones";

export const useFiltrosDevoluciones = () => {
  // Estado para los filtros de la lista de salida rutas
  const [filtrosDevoluciones, setFiltrosDevoluciones] = useState(
    obtenerValoresFiltroDevoluciones
  );

  const { buscar, filtrarPor, ordenarPor, fechaInicio, fechaFinal } =
    filtrosDevoluciones;

  // Funcion para modificar el valor con keys dinamicas
  const modificarFiltro = (e) =>
    setFiltrosDevoluciones((prevFiltrosDevoluciones) => ({
      ...prevFiltrosDevoluciones,
      [e.target.name]: e.target.value,
    }));

  // Generar el url
  const url = `/devoluciones?filtrarpor=${filtrarPor}&buscar=${buscar}&ordenarpor=${ordenarPor}&fechainicio=${fechaInicio}&fechafinal=${fechaFinal}`;

  return {
    buscar,
    filtrarPor,
    ordenarPor,
    fechaInicio,
    fechaFinal,
    url,
    modificarFiltro,
  };
};
