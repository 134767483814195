export const REQUEST_DEVOLUCION_LISTA = "REQUEST_DEVOLUCION_LISTA";
export const SUCCESS_DEVOLUCION_LISTA = "SUCCESS_DEVOLUCION_LISTA";
export const FAIL_DEVOLUCION_LISTA = "FAIL_DEVOLUCION_LISTA";
export const RESET_DEVOLUCION_LISTA = "RESET_DEVOLUCION_LISTA";

export const REQUEST_DEVOLUCION_DETALLES = "REQUEST_DEVOLUCION_DETALLES";
export const SUCCESS_DEVOLUCION_DETALLES = "SUCCESS_DEVOLUCION_DETALLES";
export const FAIL_DEVOLUCION_DETALLES = "FAIL_DEVOLUCION_DETALLES";
export const RESET_DEVOLUCION_DETALLES = "RESET_DEVOLUCION_DETALLES";

export const REQUEST_DEVOLUCION_ACTUALIZAR = "REQUEST_DEVOLUCION_ACTUALIZAR";
export const SUCCESS_DEVOLUCION_ACTUALIZAR = "SUCCESS_DEVOLUCION_ACTUALIZAR";
export const FAIL_DEVOLUCION_ACTUALIZAR = "FAIL_DEVOLUCION_ACTUALIZAR";
export const RESET_DEVOLUCION_ACTUALIZAR = "RESET_DEVOLUCION_ACTUALIZAR";
