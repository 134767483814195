import { useState } from "react";

import { toast } from "react-hot-toast";

export const useProductosVentaSalidaRuta = (
  productosCliente,
  manejarCambiarProductosCliente
) => {
  const [productosVenta, setProductosVenta] = useState([]);

  const manejarLimpiarProductosVenta = () => setProductosVenta([]);

  const manejarSeleccionarProducto = (productoId) => {
    // El producto seleccionado se obtiene de la lista de productos del cliente
    const productoSeleccionado = productosCliente.find(
      (p) => p.id === productoId
    );

    // El producto seleccionado debe tener un status de confirmado false y cantidadVenta de 0
    const productoActualizado = {
      ...productoSeleccionado,
      confirmado: false,
      cantidadVenta: 0,
    };

    // Remover el producto seleccionado de los productos del cliente
    const nuevosProductosCliente = productosCliente.filter(
      (p) => p.id !== productoId
    );
    manejarCambiarProductosCliente(nuevosProductosCliente);

    // Agregar el producto seleccionado a los productos de la venta
    const nuevosProductosVenta = [productoActualizado, ...productosVenta];
    setProductosVenta(nuevosProductosVenta);
  };

  const manejarCambioCantidad = (e, nuevaCantidad, productoId) => {
    // El stopPropagation evita que se muestre el modal con la infomacion del producto
    e.stopPropagation();

    toast.dismiss();

    // Obtener producto seleccionado para cambiar su cantidad
    const productoSeleccionado = productosVenta.find(
      (pv) => pv.id === productoId
    );

    // Obtener cantidad disponible del producto seleccionado
    const cantidadDisponible = productoSeleccionado.producto_cantidad;

    // Si la nueva cantidad excede la cantidad disponible, hacer la nuevaCantidad igual a la cantidad disponible
    if (nuevaCantidad > cantidadDisponible) {
      toast.error(
        `La cantidad seleccionada debe ser inferior o igual a ${cantidadDisponible}`,
        { duration: 4000 }
      );

      nuevaCantidad = cantidadDisponible;
    } else if (nuevaCantidad <= 0) {
      nuevaCantidad = "";
    }

    // Actualizar lista de productos venta con la nueva cantidad del producto seleccionado

    // Crear una copia del arreglo de productos
    const nuevosProductosVenta = [...productosVenta];
    const indexProducto = productosVenta.findIndex(
      (producto) => producto.id === productoId
    );

    // Actualizar el precio con el index seleccionado
    nuevosProductosVenta[indexProducto] = {
      ...productosVenta[indexProducto],
      cantidadVenta: nuevaCantidad,
    };
    setProductosVenta(nuevosProductosVenta);
  };

  const manejarConfirmarProducto = (e, productoId) => {
    // Es stopPropagation evita que se muestre el modal con la infomacion del producto
    e.stopPropagation();

    const nuevosProductosVenta = productosVenta.map((p) => {
      if (p.id === productoId) {
        p.confirmado = !p.confirmado;
      }
      return p;
    });

    setProductosVenta(nuevosProductosVenta);
  };

  const manejarCancelarProducto = (e, productoId) => {
    // Es stopPropagation evita que se muestre el modal con la infomacion del producto
    e.stopPropagation();

    // Selecciona producto a cancelar

    const productoSeleccionado = {
      ...productosVenta.find((p) => p.id === productoId),
    };

    // Remover producto de producto venta
    const nuevosProductosVenta = productosVenta.filter(
      (p) => p.id !== productoId
    );
    setProductosVenta(nuevosProductosVenta);

    // Agregar producto a productos del cliente
    const nuevosProductosCliente = [productoSeleccionado, ...productosCliente];
    manejarCambiarProductosCliente(nuevosProductosCliente);
  };

  // Determinar si los productos de venta son validos
  const isValid = getIsValid(productosVenta);

  return {
    productosVenta,
    manejarLimpiarProductosVenta,
    manejarSeleccionarProducto,
    manejarCambioCantidad,
    manejarConfirmarProducto,
    manejarCancelarProducto,
    isValid,
  };
};

const getIsValid = (productosVenta) => {
  return (
    productosVenta.length > 0 &&
    productosVenta.every((p) => p.confirmado) &&
    productosVenta.every((p) => p.cantidadVenta > 0)
  );
};
