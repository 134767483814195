import { obtenerFechaActual } from "./salidaRutas";

export const guardarFiltrosDevoluciones = (
  buscar,
  filtrarPor,
  ordenarPor,
  fechaInicio,
  fechaFinal
) => {
  localStorage.setItem("devolucionBuscar", JSON.stringify(buscar));
  localStorage.setItem("devolucionFiltrarPor", JSON.stringify(filtrarPor));
  localStorage.setItem("devolucionOrdenarPor", JSON.stringify(ordenarPor));
  localStorage.setItem("devolucionFechaInicio", JSON.stringify(fechaInicio));
  localStorage.setItem("devolucionFechaFinal", JSON.stringify(fechaFinal));
};

export const obtenerValoresFiltroDevoluciones = () => {
  const buscar = JSON.parse(localStorage.getItem("devolucionBuscar") || '""');
  const filtrarPor = JSON.parse(
    localStorage.getItem("devolucionFiltrarPor") || '"atiende"'
  );
  const ordenarPor = JSON.parse(
    localStorage.getItem("devolucionOrdenarPor") || '"fecha_recientes"'
  );
  const fechaInicio = JSON.parse(
    localStorage.getItem("devolucionFechaInicio") || '""'
  );
  const fechaFinal = JSON.parse(
    localStorage.getItem("devolucionFechaFinal") || `"${obtenerFechaActual()}"`
  );

  return {
    buscar,
    filtrarPor,
    ordenarPor,
    fechaInicio,
    fechaFinal,
  };
};
