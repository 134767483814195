import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { pedirClientesLista } from "../actions/clienteActions";
import Loader from "../componentes/general/Loader";
import Mensaje from "../componentes/general/Mensaje";

import { pedirRutasSalidaRutaLista } from "../actions/rutaActions";
import FormularioClienteSalidaRuta from "../componentes/RealizarSalidaRutaClientes/FormularioClienteSalidaRuta";

// Importar los estilos de la pagina
import {
  StyledContainer,
  StyledCol,
  StyledRow,
  StyledControlPanel,
  StyledBotonPanel,
} from "./styles/RealizarSalidaRutaClientes.styles";
import { pedirUsuariosLista } from "../actions/usuarioActions";
import { useSalidaRuta } from "../lib/hooks/useSalidaRuta";
import FormularioSalidaRutaClientes from "../componentes/RealizarSalidaRutaClientes/FormularioSalidaRutaClientes";
import { getRepartidorName } from "../lib/utilis/rutas";

const RealizarSalidaRutaClientes = () => {
  // Funcion para disparar acciones
  const dispatch = useDispatch();
  // Funcion para navegar a otra pagina
  const navigate = useNavigate();

  // Hook para mostrar y ocultar panel de control en pantallas pequenas
  const [mostrarPanel, setMostrarPanel] = useState(true);

  // Obtener referencia del boton
  const panelMovilRef = useRef(null);

  // Pedir estado de clientes del Redux store
  const clienteLista = useSelector((state) => state.clienteLista);
  const {
    clientes,
    loading: clientesLoading,
    error: clientesError,
  } = clienteLista;

  // Pedir estado de rutas del Redux store. Estas son rutas padre con un formato especial para poder obtener los cliente que se encuentrar en las rutas dias de esas rutas padre. El formato de cada ruta en esta lista es:
  // {
  // NOMBRE: "RUTA 1",
  // id: 4,
  // ruta_dias: [{id:5, repartidor_id: 2, DIA: "LUNES"}, ..., {id:12, repartidor_id: 2, DIA: "DOMINGO"}]
  // }
  const rutaSalidaRutaLista = useSelector((state) => state.rutaSalidaRutaLista);
  const {
    rutasSalidaRuta,
    loading: rutasLoading,
    error: rutasError,
  } = rutaSalidaRutaLista;

  // Obtener estado de usuarios (el repartidor se escoge de entre los usuarios) del Redux store
  const usuarioLista = useSelector((state) => state.usuarioLista);
  const {
    usuarios,
    loading: usuariosLoading,
    error: usuariosError,
  } = usuarioLista;

  // Custom hook para crear salidaRuta y modificarla (salidaRuta incluye los clientes seleccionados, el repartidor, y las observaciones)
  const {
    // Salida ruta
    salidaRuta,
    manejarModificarSalidaRuta,

    // Repartidor
    manejarModificarRepartidor,

    // Clientes
    clientesFormulario,
    manejarSeleccionarCliente,
    manejarCancelarCliente,
    manejarModificarStatusCliente,

    // Observaciones
    manejarModificarObservaciones,

    isValid,
  } = useSalidaRuta(clientes, rutasSalidaRuta);

  // useEffect para pedir rutasSalidaRuta si no existen en el Redux store
  useEffect(() => {
    if (!rutasSalidaRuta) {
      dispatch(pedirRutasSalidaRutaLista());
    }
  }, [rutasSalidaRuta, dispatch]);

  // useEffect para pedir clientes si no existen en el Redux store
  useEffect(() => {

    const source = axios.CancelToken.source()

    if (!clientes) {
      dispatch(pedirClientesLista(source.token));
    }


    return () => {
      source.cancel("Petición de lista clientes cancelada")
    }
  }, [clientes, dispatch]);

  // useEffect para cargar los usuarios si no existen en el Redux store
  useEffect(() => {

    const source = axios.CancelToken.source()
    if (!usuarios) {
      dispatch(pedirUsuariosLista(source.token));
    }

    return () => {
      source.cancel("Petición de lista usuarios cancelada")
    }
  }, [usuarios, dispatch]);

  const manejarContinuar = (e) => {
    e.preventDefault();

    // Guardar salidaRuta en el localStorage
    localStorage.setItem(
      "salidaRuta",
      JSON.stringify({
        ATIENDE,
        RUTA: salidaRuta.rutaDayId,
        RUTA_NOMBRE: salidaRuta.rutaNombre,
        REPARTIDOR: salidaRuta.repartidorId,
        REPARTIDOR_NOMBRE: getRepartidorName(usuarios, salidaRuta.repartidorId),
        OBSERVACIONES: salidaRuta.observaciones,
        salidaRutaClientes: salidaRuta.clientes.map((c) => {
          return { clienteId: c.id };
        }),
      })
    );

    // Navegar a la pagina de seleccion de productos
    navigate("/realizar-salida-ruta-productos");
  };

  // Renderizar loading si se esta cargando la lista de clientes
  if (clientesLoading)
    return (
      <Loader />
    );

  // Renderizar mensaje de error si el servidor regresa un error al pedir la lista de clientes
  if (clientesError)
    return (
      <StyledContainer>
        <Mensaje variant="danger">
          Hubo un error al cargar la lista de clientes
        </Mensaje>
      </StyledContainer>
    );

  // Renderizar loading si se esta cargando la lista de usuarios
  if (usuariosLoading)
    return (
      <Loader />
    );

  // Renderizar mensaje de error si el servidor regresa un error al pedir la lista de usuarios
  if (usuariosError)
    return (
      <StyledContainer>
        <Mensaje variant="danger">
          Hubo un error al cargar la lista de usuarios
        </Mensaje>
      </StyledContainer>
    );

  // Renderizar loading si se esta cargando la lista de rutas
  if (rutasLoading)
    return (
      <Loader />
    );

  // Renderizar mensaje de error si el servidor regresa un error al pedir la lista de rutas
  if (rutasError)
    return (
      <StyledContainer>
        <Mensaje variant="danger">
          Hubo un error al cargar la lista de rutas
        </Mensaje>
      </StyledContainer>
    );

  // Obtener el cajero que atiende desde el localStorage
  const ATIENDE = JSON.parse(localStorage.getItem("name"));

  // Solo renderizamos el formulario y los clientes de salida ruta si toda ha sido cargado exitosamente
  return (
    rutasSalidaRuta &&
    usuarios &&
    clientes && (
      <StyledContainer fluid>
        <StyledRow gridarea="Title" ancho={"100%"}>
          <StyledCol>
            <h1>Realizar Salida Ruta</h1>
          </StyledCol>
        </StyledRow>

        <div>
          <StyledBotonPanel
            onClick={() => setMostrarPanel((state) => (!state))}
            state={mostrarPanel.toString()}
            color="standard"
            ref={panelMovilRef}
            onFocus={() => panelMovilRef.current.blur()}
          >
            <i className="fa-solid fa-arrow-right" />
          </StyledBotonPanel>
        </div>

        <StyledControlPanel
          gridarea={"ControlPanel"}
          mostrarpanel={mostrarPanel.toString()}
        >
          {/* Primer columna es para el formulario */}
          <StyledCol width={"100%"}>
            <FormularioSalidaRutaClientes
              manejarContinuar={manejarContinuar}
              salidaRuta={salidaRuta}
              manejarModificarSalidaRuta={manejarModificarSalidaRuta}
              rutasSalidaRuta={rutasSalidaRuta}
              clientesFormulario={clientesFormulario}
              usuarios={usuarios}
              isValid={isValid}
              manejarModificarRepartidor={manejarModificarRepartidor}
              manejarModificarObservaciones={manejarModificarObservaciones}
              manejarSeleccionarCliente={manejarSeleccionarCliente}
            />
          </StyledCol>
        </StyledControlPanel>
        <StyledRow gridarea={"Clients"}>
          {/* Segunda columna es para los clientes de salida ruta */}
          <StyledCol width={"90%"}>
            {salidaRuta.clientes.map((c) => (
              <FormularioClienteSalidaRuta
                key={c.id}
                cliente={c}
                ruta={salidaRuta}
                manejarConfirmarCliente={manejarModificarStatusCliente}
                manejarCancelarCliente={manejarCancelarCliente}
              />
            ))}
          </StyledCol>
        </StyledRow>
      </StyledContainer>
    )
  );
};

export default RealizarSalidaRutaClientes;
