import { useState } from "react";

export const useMostralModalSalidaRuta = (salidaRutas = []) => {
  const [salidaRuta, setSalidaRuta] = useState({});

  const [mostrarModalSalidaRuta, setMostrarModalSalidaRuta] = useState(false);

  const manejarMostrarModalSalidaRuta = (salidaRutaId) => {
    const salidaRutaSeleccionada = salidaRutas.find(
      (v) => v.id === salidaRutaId
    );
    setSalidaRuta(salidaRutaSeleccionada);
    setMostrarModalSalidaRuta(true);
  };

  const manejarCerrarVentana = () => {
    setMostrarModalSalidaRuta(false);
  };

  return {
    salidaRuta,
    mostrarModalSalidaRuta,
    manejarMostrarModalSalidaRuta,
    manejarCerrarVentana,
  };
};
