export const crearProductosSalidaRuta = (productosSalidaRuta) => {
  const nuevosProductosSalidaRuta = productosSalidaRuta.map((pv) => {
    const productoId = pv.id;

    const cantidadSalidaRuta = Number(pv.cantidadSalidaRuta);

    return { productoId, cantidadSalidaRuta };
  });

  return nuevosProductosSalidaRuta;
};

// Funcion para crear la fecha actual
export const obtenerFechaActual = () => {
  const date = new Date();
  let dia = date.getDate();
  dia = String(dia).padStart(2, "0");
  let mes = date.getMonth() + 1;
  mes = String(mes).padStart(2, "0");
  const anio = date.getFullYear();

  // This arrangement can be altered based on how we want the date's format to appear.
  return `${anio}-${mes}-${dia}`;
};

// Funcion para guardar el valor de los filtros en el local storage
export const guardarFiltros = (
  buscar,
  filtrarPor,
  ordenarPor,
  fechaInicio,
  fechaFinal
) => {
  localStorage.setItem("buscar", JSON.stringify(buscar));
  localStorage.setItem("filtrarPor", JSON.stringify(filtrarPor));
  localStorage.setItem("ordenarPor", JSON.stringify(ordenarPor));
  localStorage.setItem("fechaInicio", JSON.stringify(fechaInicio));
  localStorage.setItem("fechaFinal", JSON.stringify(fechaFinal));
};

// Funcion para obtener los valores de filtros salida a rutas del local storage
export const obtenerValoresFiltroSalidaRutas = () => {
  const buscar = JSON.parse(localStorage.getItem("buscar") || '""');
  const filtrarPor = JSON.parse(
    localStorage.getItem("filtrarPor") || '"atiende"'
  );
  const ordenarPor = JSON.parse(
    localStorage.getItem("ordenarPor") || '"fecha_recientes"'
  );
  const fechaInicio = JSON.parse(localStorage.getItem("fechaInicio") || '""');
  const fechaFinal = JSON.parse(
    localStorage.getItem("fechaFinal") || `"${obtenerFechaActual()}"`
  );

  return {
    buscar,
    filtrarPor,
    ordenarPor,
    fechaInicio,
    fechaFinal,
  };
};

// Ticket salida ruta

const mesesAbreviados = {
  0: "Ene",
  1: "Feb",
  2: "Mar",
  3: "Abr",
  4: "May",
  5: "Jun",
  6: "Jul",
  7: "Ago",
  8: "Sep",
  9: "Oct",
  10: "Nov",
  11: "Dic",
};

const formatearNumeroConCero = (factor) => factor.toString().padStart(2, "0");

export const obtenerFechaActualConFormato = () => {
  const fecha = new Date();

  return `${formatearNumeroConCero(fecha.getDate())}/${
    mesesAbreviados[fecha.getMonth()]
  }/${fecha.getFullYear()} ${formatearNumeroConCero(
    fecha.getHours()
  )}:${formatearNumeroConCero(fecha.getMinutes())}`;
};

export const getFormatoFecha = (date) => {
  const formattedDate = new Date(date);

  // Extracting fecha (date)
  const fecha = `${formatearNumeroConCero(formattedDate.getDate())}/${
    mesesAbreviados[formattedDate.getMonth()]
  }/${formattedDate.getFullYear()}`;

  return fecha;
};

export const getFormatoHora = (date) => {
  const formattedDate = new Date(date);

  const hora = `${formatearNumeroConCero(
    formattedDate.getHours()
  )}:${formatearNumeroConCero(formattedDate.getMinutes())}`;

  return hora;
};

export const informacionTienda = {
  LOCAL: {
    CALLE: "Culiver City",
    NUMERO: "3",
    COLONIA: "Barrio de Santo Santiago",
    MUNICIPIO: "Uruapan",
    ESTADO: "Mich",
    CP: "60030",
    RFC: "OIGA7111294F1",
  },
};

export const totalizarCantidadProductos = (productos) =>
  productos.reduce((total, producto) => total + producto.CANTIDAD_VENTA, 0);

export const sumarPreciosProductos = (productos) =>
  productos.reduce((subtotal, producto) => subtotal + producto.PRECIO_VENTA, 0);
