import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../componentes/general/Loader";
import Mensaje from "../componentes/general/Mensaje";
import { RESET_RUTA_DETALLES } from "../constantes/rutaConstantes";
import TablaRutaDias from "../componentes/RutaDiasLista/TablaRutaDias";
// Estoy importando un estilo de otro componente :(. Me da flojera escribir el estilo de este componente.
import {
  StyledContainer,
  StyledRow,
  StyledCol,
} from "./styles/RutaDiasLista.styles";
import { pedirRutaDiasLista } from "../actions/rutaActions";

// Estoy importando un estilo de otro componente :(. Me da flojera escribir el estilo de este componente.
import { StyledBoton } from "./styles/RutaDetalles.styles";

import VentanaMostrarModalRutaDias from "../componentes/RutaDiasLista/VentanaMostrarModalRutaDias";

// Este custom hook lo use aqui y en RutaLista
import { useMostrarModalRuta } from "../lib/hooks/useMostrarModalRuta";
import { useRef } from "react";

const RutasDiasLista = () => {
  // Funcion para disparar las acciones
  const dispatch = useDispatch();
  // Funcion para nevagar en la aplicacion
  const navigate = useNavigate();

  // Obtener el id de la ruta. No ruta but ruta dia
  const params = useParams();
  const rutaId = params.id;

  // Obtener la referencia del boton
  const regresarRef = useRef(null);

  // Obtener el estado desde el Redux store
  const rutaDiasLista = useSelector((state) => state.rutaDiasLista);
  const { loading, rutaDias, error } = rutaDiasLista;

  // Custom hook para los detalles de la ruta
  const {
    mostrarModalRuta,
    ruta,
    manejarCerrarVentana,
    manejarMostrarModalRuta,
  } = useMostrarModalRuta(rutaDias);

  // Lo interesant de este pedirRutaDiasLista es que solo pide las ruta dias de la ruta con id igual a rutaId
  useEffect(() => {
    // Si no hay rutas, disparar la accion de pedir rutas
    if (!rutaDias || Number(rutaId) !== rutaDias[0].RUTA) {
      dispatch(pedirRutaDiasLista(rutaId));
    }
  }, [dispatch, rutaDias, rutaId]);

  const manejarRutaDetalles = (id) => {
    // Redireccionar a la pagina del rutas
    dispatch({ type: RESET_RUTA_DETALLES });
    // navigate(`/rutas-dias/${id}?=${rutaId}`);
    navigate(`/rutas-dias/${id}`);
  };

  // Esto aun no lo implemento pero creo que es buena idea
  // const manejarResetRuta = (id) => {
  //   if (
  //     window.confirm(
  //       "¿Está seguro de resetear esta ruta? Se vaciará la lista de clientes en esta ruta"
  //     )
  //   ) {
  //     alert(`Ruta id: ${id}`);
  //   } else {
  //     alert("Operación cancelada");
  //   }
  // };

  const manejarRegresar = () => {
    // Redireccionar a la pagina de productos
    dispatch({ type: RESET_RUTA_DETALLES });
    navigate("/rutas");
  };

  // Renderizar loading si se estan cargando las rutas
  if (loading)
    return (
      <Loader />
    );

  // Renderizar mensaje de error si el servidor regresa un error al pedir la lista de rutas
  if (error)
    return (
      <StyledContainer fluid>
        <StyledRow>
          <StyledCol>
            <Mensaje variant="danger">
              Hubo un error al cargar la lista de rutas
            </Mensaje>
          </StyledCol>
        </StyledRow>
      </StyledContainer>
    );

  return (
    rutaDias && (
      <>
        <StyledContainer fluid>
          <h1>Ruta {rutaDias[0].NOMBRE}</h1>
          <div>
            <StyledBoton
              color="green"
              ref={regresarRef}
              onFocus={() => regresarRef.current.blur()}
              onClick={manejarRegresar}
            >
              Regresar
            </StyledBoton>
          </div>
          <StyledRow>
            <StyledCol>
              {/*Tabla de de rutas*/}
              <TablaRutaDias
                rutas={rutaDias}
                manejarMostrarDetallesRuta={manejarMostrarModalRuta}
                manejarRutaDetalles={manejarRutaDetalles}
                // manejarResetRuta={manejarResetRuta}
              />
            </StyledCol>
          </StyledRow>
        </StyledContainer>

        {/* Mostrar ventana con detalles de la ruta */}
        {mostrarModalRuta && (
          <VentanaMostrarModalRutaDias
            ruta={ruta}
            mostrarRuta={mostrarModalRuta}
            manejarCerrarVentana={manejarCerrarVentana}
          />
        )}
      </>
    )
  );
};

export default RutasDiasLista;
