import React, { useRef } from "react";
import { formatearFecha } from "../../utilitis";
import { useReactToPrint } from "react-to-print";

// Estilos del componente
import {
  StyledModal,
  StyledModalBody,
  StyledModalHeader,
  StyledModalFooter,
  StyledBoton,
  StyledModalTitle,
  StyledBotonPrint,
} from "./styles/VentanaMostrarModalSalidaRuta.styles";
import TicketSalidaRuta from "./TicketSalidaRuta";

const VentanaMostrarModalSalidaRuta = ({
  salidaRuta,
  mostrarModalSalidaRuta,
  manejarCerrarVentana,
}) => {
  // Obtener referencia del boton
  const cerrarRef = useRef(null);
  const componentRef = useRef(null);

  // Funcion para realizar la impresion del ticket
  const manejarPrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    salidaRuta &&
    salidaRuta.productos && (
      <>
        <StyledModal
          scrollable
          centered
          show={mostrarModalSalidaRuta}
          onHide={manejarCerrarVentana}
        >
          <StyledModalHeader closeButton>
            <StyledModalTitle>
              Detalles de Salida a Ruta #{salidaRuta.id}
            </StyledModalTitle>
          </StyledModalHeader>

          <StyledModalBody>
            <p>
              <strong>ATIENDE:</strong> {salidaRuta.ATIENDE}
            </p>
            <p>
              <strong>REPARTIDOR:</strong> {salidaRuta.REPARTIDOR_NOMBRE}
            </p>
            <p>
              <strong>FECHA:</strong> {formatearFecha(salidaRuta.FECHA)}
            </p>
            <p>
              <strong>STATUS:</strong> {salidaRuta.STATUS}
            </p>
            <p>
              <strong>OBSERVACIONES:</strong> {salidaRuta.OBSERVACIONES}
            </p>
            <p>
              <strong>PRODUCTOS DE SALIDA RUTA:</strong>
            </p>
            <ol>
              {salidaRuta.productos.map((ps) => (
                <li key={ps.id}>
                  {ps.PRODUCTO_NOMBRE}:
                  <ul>
                    <li>CANTIDAD RUTA: {ps.CANTIDAD_RUTA}</li>
                    <li>CANTIDAD DISPONIBLE: {ps.CANTIDAD_DISPONIBLE}</li>
                    <li>STATUS: {ps.STATUS}</li>
                  </ul>
                </li>
              ))}
            </ol>

            <p>
              <strong>CLIENTES DE SALIDA RUTA:</strong>
            </p>

            <ol>
              {salidaRuta.clientes.map((cs) => (
                <li key={cs.id}>
                  {cs.CLIENTE_NOMBRE}:
                  <ul>
                    <li>STATUS: {cs.STATUS}</li>
                  </ul>
                </li>
              ))}
            </ol>
          </StyledModalBody>

          <StyledModalFooter>
            <StyledBotonPrint
              style={{ textAlign: "left" }}
              color="green"
              onClick={manejarPrint}
            >
              Reimprimir ticket
            </StyledBotonPrint>
            <StyledBoton
              color="blue"
              disabled={false}
              ref={cerrarRef}
              onFocus={() => cerrarRef.current.blur()}
              variant="secondary"
              onClick={() => {
                manejarCerrarVentana();
              }}
            >
              Cerrar
            </StyledBoton>
          </StyledModalFooter>
        </StyledModal>
        <div style={{ display: "none" }}>
          <TicketSalidaRuta datosSalidaRuta={salidaRuta} ref={componentRef} />
        </div>
      </>
    )
  );
};

export default VentanaMostrarModalSalidaRuta;
