import { useState } from 'react';
import { obtenerValoresFiltroSalidaRutas } from '../utilis/salidaRutas';

export const useFiltroSalidaRutas = () => {
  // Estado para los filtros de la lista de salida rutas
  const [filtrosSalidaRutas, setFiltrosSalidaRutas] = useState(
    obtenerValoresFiltroSalidaRutas
  );

  const { buscar, filtrarPor, ordenarPor, fechaInicio, fechaFinal } = filtrosSalidaRutas;

  // Funcion para modificar el valor con keys dinamicas
  const modificarFiltro = (e) =>
    setFiltrosSalidaRutas(prevFiltrosSalidaRutas => ({
      ...prevFiltrosSalidaRutas, [e.target.name] : e.target.value
    }));

  
  // Generar el url
  const url = `/salida-rutas?filtrarpor=${filtrarPor}&buscar=${buscar}&ordenarpor=${ordenarPor}&fechainicio=${fechaInicio}&fechafinal=${fechaFinal}`;

  return {
    buscar,
    filtrarPor,
    ordenarPor,
    fechaInicio,
    fechaFinal,
    url,
    modificarFiltro
  };
};