import React, { useEffect } from "react";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { useSelector } from "react-redux";
import { LinkContainer } from "react-router-bootstrap";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import {
  StyledImage,
  StyledLinkContainerEmpresa,
  StyledNavbar,
  StyledNavDropdown,
  StyledText,
  StyledTextHGP,
  StyledTextUsername,
} from "./styles/Encabezado.styles";
import { BASE_URL } from "../../constantes/constantes";

const Encabezado = () => {
  // Funcion para obtener pathname
  const location = useLocation();

  // Funcion para obtener informacion del
  const usuarioInfo = useSelector((state) => state.usuarioInfo);
  const { token } = usuarioInfo;

  const [username, setUsername] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [role, setRole] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    if (token) {
      setUsername(JSON.parse(localStorage.getItem("username")));
      // setImagen(JSON.parse(localStorage.getItem("imagen")));
      setIsAdmin(JSON.parse(localStorage.getItem("isAdmin")));
      setIsAuthenticated(true);

      setRole(JSON.parse(localStorage.getItem("role")));
    }
  }, [token]);

  return (
    <StyledNavbar expand="lg" bg="light" variant="light">
      <Container className="d-flex">
        {/* Imagen de la cuenta */}
        {token && (
          <StyledNavDropdown
            bgcolor={
              location.pathname === "/cuenta" || location.pathname === "/corte"
                ? "#1E90FF"
                : "black"
            }
            title={<StyledTextUsername>{username}</StyledTextUsername>}
            id="basic-nav-dropdown"
          >
            <LinkContainer to="/cuenta">
              <NavDropdown.Item>Manejar cuenta</NavDropdown.Item>
            </LinkContainer>
            <NavDropdown.Item>Generar corte</NavDropdown.Item>
          </StyledNavDropdown>
        )}

        {/* Logo de la empresa */}
        <StyledLinkContainerEmpresa to="/">
          <Nav.Link className="text-center">
            <StyledImage
              src={`${BASE_URL}/static/imagenes/general/logo.png`}
              alt="imagen de usuario"
              width="100px"
              style={{ marginRight: "20px" }}
            />
            <StyledTextHGP
              color={location.pathname === "/" ? "#1E90FF" : "black"}
            >
              Hielo Gran Pacífico
            </StyledTextHGP>
          </Nav.Link>
        </StyledLinkContainerEmpresa>

        {/* Menu de navegacion */}
        <Navbar.Toggle aria-controls="basic-navbar-nav" />

        <Navbar.Collapse
          id="basic-navbar-nav"
          className="justify-content-end"
          style={{ flexGrow: 0 }}
        >
          <Nav>
            {!token ? (
              <LinkContainer to="/login">
                <Nav.Link>Login</Nav.Link>
              </LinkContainer>
            ) : role !== "REPARTIDOR" ? (
              <>
                <StyledNavDropdown
                  bgcolor={
                    location.pathname === "/rutas" ||
                    location.pathname === "/registrar-ruta"
                      ? "#1E90FF"
                      : "black"
                  }
                  title={<StyledText>Rutas</StyledText>}
                  id="basic-nav-dropdown"
                >
                  <LinkContainer to="/rutas">
                    <NavDropdown.Item>Lista de Rutas</NavDropdown.Item>
                  </LinkContainer>
                  {isAdmin && (
                    <LinkContainer to="/registrar-ruta">
                      <NavDropdown.Item>Registrar Ruta</NavDropdown.Item>
                    </LinkContainer>
                  )}
                </StyledNavDropdown>

                <StyledNavDropdown
                  bgcolor={
                    location.pathname === "/salida-rutas" ||
                    location.pathname === "/realizar-salida-ruta-clientes"
                      ? "#1E90FF"
                      : "black"
                  }
                  title={<StyledText>Salidas a Ruta</StyledText>}
                  id="basic-nav-dropdown"
                >
                  <LinkContainer to="/salida-rutas">
                    <NavDropdown.Item>Lista de Salidas a Ruta</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to="/realizar-salida-ruta-clientes">
                    <NavDropdown.Item>Realizar Salida a Ruta</NavDropdown.Item>
                  </LinkContainer>
                </StyledNavDropdown>
                <StyledNavDropdown
                  bgcolor={
                    location.pathname === "/devoluciones" ? "#1E90FF" : "black"
                  }
                  title={<StyledText>Devoluciones</StyledText>}
                  id="basic-nav-dropdown"
                >
                  <LinkContainer to="/devoluciones">
                    <NavDropdown.Item>Lista de Devoluciones</NavDropdown.Item>
                  </LinkContainer>
                </StyledNavDropdown>
              </>
            ) : (
              <>
                <StyledNavDropdown
                  bgcolor={
                    location.pathname === "/salida-rutas" ? "#1E90FF" : "black"
                  }
                  title={<StyledText>Salidas a Ruta</StyledText>}
                  id="basic-nav-dropdown"
                >
                  <LinkContainer to="/salida-rutas">
                    <NavDropdown.Item>Lista de Salidas a Ruta</NavDropdown.Item>
                  </LinkContainer>
                </StyledNavDropdown>
              </>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </StyledNavbar>
  );
};

export default Encabezado;
