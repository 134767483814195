import { Form } from "react-bootstrap";
import {
  StyledBoton,
  StyledFormGroup,
} from "./styles/FormularioSalidaRutaProductos.styles";
import { useRef } from "react";

const FormularioSalidaRutaProductos = ({
  manejarRealizarSalidaRuta,
  manejarSeleccionarProducto,
  productosDisponibles,
  isValid,
  disabledState,
}) => {

  // Obtener referencia del boton
  const realizarRef = useRef(null);

  return (
    <Form onSubmit={manejarRealizarSalidaRuta}>
      <StyledFormGroup controlId="productosCliente">
        <Form.Label>PRODUCTOS DISPONIBLES</Form.Label>
        <Form.Control
          as="select"
          onChange={(e) => manejarSeleccionarProducto(Number(e.target.value))}
        >
          <option value={0}>Selecciona un producto</option>
          {productosDisponibles.map((pd) => (
            <option key={pd.id} value={pd.id}>
              {pd.NOMBRE}
            </option>
          ))}
        </Form.Control>
      </StyledFormGroup>

      <StyledFormGroup>
        <StyledBoton 
          color="green"
          ref={realizarRef}
          onFocus={() => realizarRef.current.blur()}
          disabled={!isValid || disabledState} 
          type="submit"
        >
          Realizar Salida Ruta
        </StyledBoton>
      </StyledFormGroup>
    </Form>
  );
};
export default FormularioSalidaRutaProductos;
