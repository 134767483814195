import styled from "styled-components";

export const StyledPrincipal = styled.div`
  width: 320px;
  height: auto;
  color: black;
  background: rgba(220, 220, 220, 0.9);
  padding: 8px;
  padding-top: 15px;
  font-weight: 400;
  border-radius: 0;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: flex-start;
  row-gap: 5px;
`;

export const StyledContenedorLogo = styled.div`
  width: 100%;
  border-radius: none;
  padding: 0 65px 0 65px;
`;

export const StyledLogo = styled.img`
  width: 150px;
`;

export const StyledTexto = styled.p`
  font-weight: ${(props) => props.fontWeight};
  font-size: ${(props) => props.fontSize};
  color: black;
  text-align: ${(props) => props.textAlign};
  width: 100%;
  overflow-x: hidden;
  height: ${(props) => props.height};
  top: ${(props) => props.top};
  position: relative;
  margin-bottom: ${(props) => props.bottom};
  margin-top: ${(props) => props.top};

  &::-webkit-scrollbar {
      display: none;
  }

  & span {
    display: inline-block;
    position: relative;
    top: ${(props) => props.topSpan};
    height: ${(props) => props.heightSpan};
    font-weight: ${(props) => props.spanFontWeight};
    color: ${(props) => props.color};
  }
`;

export const StyledGridDosSecciones = styled.div`
  display: grid;
  width: 100%;
  height: auto;
  grid-template-columns: 100px 1fr;
  grid-gap: 8px;
`;

export const StyledContenedorProducto = styled.div`
  width: 100%;
  height: auto;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 100px 1fr;
`;
