import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components'

// Estilos del contenedor principal
export const StyledContainer = styled(Container)`
    height: 88vh;
    padding: 1rem 0;
    overflow: auto;

    h1 {
        text-align: center;
        color: var(--white-color);
        margin-bottom: 1rem
    }

    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar{
        display: none;
    }
`;

// Estilos de las filas
export const StyledRow = styled(Row)`
    height: 80%;
    width: 100vw;
    display: flex;
    align-items: center;
    --bs-gutter-x: 0;
`;

// Estilos de las columnas
export const StyledCol = styled(Col)`
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
    height: 100%;
`;
 