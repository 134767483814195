import { keyframes, styled } from "styled-components";
import { BASE_URL } from "../../constantes/constantes";

const LoaderContainer = styled.div`
  width: 100%;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

//Loader de puntos elevables

// Definicion de las animaciones
const shadowAnimation = keyframes`
  from {
    box-shadow: 0.1em 0.1em 0 0.1em black, 0.3em 0 0.3em rgba(0, 0, 0, 0.3);
  }
  25% {
    box-shadow: 0.1em 0.1em 0 0.1em black, 0.8em 0 0.8em rgba(0, 0, 0, 0.5);
  }
  50%, to {
    box-shadow: 0.1em 0.1em 0 0.1em black, 0.3em 0 0.3em rgba(0, 0, 0, 0.3);
  }
`;

const pushInOut1Animation = keyframes`
  from {
    background-color: var(--bg-dot);
    transform: translate(0, 0);
  }
  25% {
    background-color: var(--primary2-dot);
    transform: translate(-71%, -71%);
  }
  50%, to {
    background-color: var(--bg-dot);
    transform: translate(0, 0);
  }
`;

const pushInOut2Animation = keyframes`
  from {
    background-color: var(--bg-dot);
    clip-path: polygon(0 75%, 100% 75%, 100% 100%, 0 100%);
  }
  25% {
    background-color: var(--primary1-dot);
    clip-path: polygon(0 25%, 100% 25%, 100% 100%, 0 100%);
  }
  50%, to {
    background-color: var(--bg-dot);
    clip-path: polygon(0 75%, 100% 75%, 100% 100%, 0 100%);
  }
`;

// Contenedor del circulo
const LoaderCirclePL = styled.div`
  --bg-dot: rgba(0,0,0,0);
  --primary1-dot: rgba(0,0,0,0);
  --primary2-dot: rgba(0,0,0,0);
  box-shadow: 2em 0 2em rgba(0, 0, 0, 0.6) inset, -2em 0 2em rgba(255, 255, 255, 0.15) inset;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  transform: rotateX(30deg) rotateZ(45deg);
  width: 14em;
  height: 14em;
  color: white;
  border-radius: 50%;
  background-color: rgba(0,0,0,0.3);
`;

// Puntos elevables
const LoaderPLDot = styled.div`
  animation-name: ${shadowAnimation};
  box-shadow: 0.1em 0.1em 0 0.1em black, 0.3em 0 0.3em rgba(0, 0, 0, 0.5);
  top: calc(50% - 0.75em);
  left: calc(50% - 0.75em);
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  position: absolute;

  &:before,
  &:after {
    animation-duration: 2s;
    animation-iteration-count: infinite;
    position: absolute;
    content: "";
    display: block;
    left: 0;
    width: inherit;
    transition: background-color var(--trans-dur);
  }

  &:before {
    animation-name: ${pushInOut1Animation};
    background-color: var(--bg);
    border-radius: inherit;
    box-shadow: 0.05em 0 0.1em rgba(255, 255, 255, 0.7) inset;
    height: inherit;
    z-index: 1;
  }

  &:after {
    animation-name: ${pushInOut2Animation};
    background-color: var(--primary1);
    border-radius: 0.75em;
    box-shadow: 0.1em 0.3em 0.2em rgba(255, 255, 255, 0.8) inset, 0 -0.4em 0.2em #2e3138 inset, 0 -1em 0.25em rgba(0, 0, 0, 0.3) inset;
    bottom: 0;
    clip-path: polygon(0 75%, 100% 75%, 100% 100%, 0 100%);
    height: 3em;
    transform: rotate(-45deg);
    transform-origin: 50% 2.25em;
  }

  &:nth-child(1) {
    transform: rotate(0deg) translateX(5em) rotate(0deg);
    z-index: 5;
    &:before,
    &:after {
      animation-delay: 0s;
    }
  }

  &:nth-child(2) {
    transform: rotate(-30deg) translateX(5em) rotate(30deg);
    z-index: 4;
    &:before,
    &:after {
      animation-delay: -0.1666666667s;
    }
  }

  &:nth-child(3) {
    transform: rotate(-60deg) translateX(5em) rotate(60deg);
    z-index: 5;
    &:before,
    &:after {
      animation-delay: -0.3333333333s;
    }
  }

  &:nth-child(4) {
    transform: rotate(-90deg) translateX(5em) rotate(90deg);
    z-index: 4;
    &:before,
    &:after {
      animation-delay: -0.5s;
    }
  }

  &:nth-child(5) {
    transform: rotate(-120deg) translateX(5em) rotate(120deg);
    z-index: 5;
    &:before,
    &:after {
      animation-delay: -0.6666666666s;
    }
  }

  &:nth-child(6) {
    transform: rotate(-150deg) translateX(5em) rotate(150deg);
    z-index: 4;
    &:before,
    &:after {
      animation-delay: -0.83333333333s;
    }
  }

  &:nth-child(7) {
    transform: rotate(-180deg) translateX(5em) rotate(180deg);
    z-index: 5;
    &:before,
    &:after {
      animation-delay: -1s;
    }
  }

  &:nth-child(8) {
    transform: rotate(-210deg) translateX(5em) rotate(210deg);
    z-index: 4;
    &:before,
    &:after {
      animation-delay: -1.1666666667s;
    }
  }

  &:nth-child(9) {
    transform: rotate(-240deg) translateX(5em) rotate(240deg);
    z-index: 5;
    &:before,
    &:after {
      animation-delay: -1.3333333333s;
    }
  }

  &:nth-child(10) {
    transform: rotate(-270deg) translateX(5em) rotate(270deg);
    z-index: 4;
    &:before,
    &:after {
      animation-delay: -1.5s;
    }
  }

  &:nth-child(11) {
    transform: rotate(-300deg) translateX(5em) rotate(300deg);
    z-index: 5;
    &:before,
    &:after {
      animation-delay: -1.6666666666s;
    }
  }

  &:nth-child(12) {
    transform: rotate(-330deg) translateX(5em) rotate(330deg);
    z-index: 4;
    &:before,
    &:after {
      animation-delay: -1.8333333333s;
    }
  }
`;

const LoaderPLText = styled.div`
  font-size: 0.75rem;
  max-width: 5rem;
  position: relative;
  text-shadow: 0 0 0.1em white;
  transform: rotateZ(-45deg);
  display: flex;
  flex-wrap: wrap;
`;

export const LoaderPL = () => {
    return(
     <LoaderContainer>
       <LoaderCirclePL>
        {[...Array(12)].map((_,index) => (
         <LoaderPLDot key={index} />
        ))}
       <LoaderPLText>Cargando, por favor espere...</LoaderPLText>
      </LoaderCirclePL>
     </LoaderContainer>
    )
}




// Cells Loader
const StyledLoaderCellsContainer = styled.div`
  --cell-size: 70px;
  --cell-spacing: 3px;
  --cells: 3;
  --total-size: calc(var(--cells)*(var(--cell-size) + 2*var(--cell-spacing)));
  display: flex;
  flex-wrap: wrap;
  width: var(--total-size);
  height: var(--total-size);
`;

// Animaciones
const rippleAnimation = keyframes`
  0%{
    background-color: transparent;
  }
  30%{
    background-color: var(--cell-color);
  }
  60%{
    background-color: transparent;
  }
  100%{
    background-color: transparent;
  }
`;

const StyledCell = styled.div`
  flex: 0 0 var(--cell-size);
  margin: var(--cell-spacing);
  background-color: transparent;
  box-sizing: border-box;
  border-radius: 4px;
  animation: 1.5s ${rippleAnimation} ease infinite;
  animation-delay: ${props => props.delay};
  --cell-color: ${props => props.cellcolor};
`;

const StyledText = styled.p`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  color: white;
  text-shadow: rgba(0,0,0,0.8);
`;

export const CellsLoader = () => {
  const cellColors = [
    '#00FF87',
    '#0CFD95',
    '#17FBA2',
    '#23F9B2',
    '#30F7C3',
    '#3DF5D4',
    '#45F4DE',
    '#53F1F0',
    '#60EFFF',
  ];

  const cellDelays = [
    '0s',
    '100ms',
    '200ms',
    '100ms',
    '200ms',
    '200ms',
    '300ms',
    '300ms',
    '400ms',
  ];

  return(
    <LoaderContainer style={{flexDirection:'column'}}>
      <StyledLoaderCellsContainer>
        {cellColors.map((color,index) => (
          <StyledCell key={index} cellcolor={color} delay={cellDelays[index]}/>
        ))}
      </StyledLoaderCellsContainer>
      <StyledText>Cargando, por favor espere...</StyledText>
    </LoaderContainer>
  );
}

// Server Loader
const BACKGROUND_URL = `${BASE_URL}/static/imagenes/general/logo.png`;
const rotateY3D = keyframes`
  0% {
    transform: translateZ(0px) rotateX(0);
  }

  50% {
    transform: translateZ(0px) rotateX(-360deg);
  }

  100% {
    transform: translateZ(0px) rotateX(-360deg);
  }
`;

const rotateX3D = keyframes`
  0% {
    transform: translateX(0px) rotateY(0);
  }

  50% {
    transform: translateX(0px) rotateY(180deg);
  }

  100% {
    transform: translateX(0px) rotateY(360deg);
  }
`;

const animMove = keyframes`
  0% {
    transform: translateX(20px);
  }

  100% {
    transform: translateX(-10px);
  }
`;

const StyledServerLoaderContainer = styled.div`
  margin: 0 auto;
  display: flex;
  width: 22rem;
  height: 22rem;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  border-radius: 50%;
  border: 0.4rem solid #52c5ff;
  background-image: url(${BACKGROUND_URL});
  background-size: 90% 60%;
  background-repeat: no-repeat;
  background-position: center;
`;

const StyledServer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  animation: ${rotateY3D} 3s ease-in infinite both;
  animation-delay: ${props => props.delay};

  &:nth-child(2) .f::after,
  &:nth-child(3) .f::after {
    position: absolute;
    content: "";
    width: 100%;
    z-index: -1;
    height: 20px;
    background-image: radial-gradient(#54FF6E 2px, transparent 1%);
    background-size: 20px 5px;
    left: 0;
    top: 20%;
    border: 0;
    animation: ${animMove} 2s linear infinite alternate-reverse;
    overflow: hidden;
  }

  &:nth-child(2) .f::before,
  &:nth-child(3) .f::before {
    position: absolute;
    content: "";
    width: 85%;
    z-index: -1;
    height: 20px;
    left: 0;
    top: 20%;
    border: 0;
    background: #181818;
    border: 2px solid #52C5FF;
    overflow: hidden;
  }

  &:nth-child(1),
  &:nth-child(4) {
    & .f::before,
    & .r::before,
    & .l::before {
      position: absolute;
      content: "";
      width: 95%;
      height: 5px;
      left: 0;
      top: 25%;
      border: 1px solid #52C5FF;
      overflow: hidden;
    }

    &:nth-child(1) .b::before,
    &:nth-child(4) .b::before {
      position: absolute;
      content: "";
      width: 95%;
      z-index: -1;
      height: 5px;
      left: 0;
      top: 69%;
      border: 0;
      border: 1px solid #52C5FF;
      overflow: hidden;
    }
  }

  .f {
    transform: rotateY(0deg) translateZ(15px);
    border-radius: 2px;
    overflow: hidden;
    transform-style: preserve-3d;
  }

  .b {
    transform: rotateX(180deg) translateZ(15px);
    border-radius: 2px;
  }

  .f, .b {
    width: 30px;
    height: 100px;
    background: #212121;
    box-shadow: inset 0 0 0 2px #52C5FF;
    border: 2px solid #52C5FF;
  }

  .l {
    transform: rotateY(-90deg) translateZ(0px);
    border-radius: 2px;
  }

  .r {
    transform: rotateY(90deg) translateZ(-30px) translateY(-100px);
    border-radius: 2px;
    height: 5px;
  }

  .l, .r {
    width: 30px;
    height: 100px;
    left: 15px;
    position: relative;
    z-index: -1;
    background: #212121;
    box-shadow: inset 0 0 0 2px #52C5FF;
    border: 2px solid #52C5FF;
  }

  .t {
    transform: rotateX(90deg) translateZ(240px);
    border-radius: 2px;
  }

  .bot {
    transform: rotateX(-90deg) translateZ(-170px);
    border-radius: 2px;
  }

  .t, .bot {
    width: 30px;
    height: 30px;
    top: 25px;
    position: relative;
    z-index: -1;
    background: #212121;
    box-shadow: inset 0 0 0 2px #52C5FF;
  }
`;

const StyledTars = styled.div`
  z-index: 0;
  position: absolute;
  height: 100px;
  width: 130px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  transform-style: preserve-3d;
  animation: ${rotateX3D} 6s ease-in-out infinite;
  animation-delay: 1s;
`;

const StyledShape = styled.div`
  width: 100%;
  height: 100px;
  transform-style: preserve-3d;
  position: relative;
  z-index: -1;

  div{
    display: block;
    position: absolute;
    text-align: center;
  }
`;

const dotsAnimation = keyframes`
  0%, 100% {
    opacity: 0;
    transform: scale(0.2);
  }

  0% {
    opacity: 0;
  }

  25% {
    opacity: 1;
    transform: scale(1);
  }

  50%, 75% {
    opacity: 1;
    transform: scale(1);
  }
`;

const StyledDots = styled.span`
  display: inline-block;
  transition-property: all;
  transition-duration: 3s;
  animation: ${dotsAnimation} 3s linear infinite;
  animation-delay: ${props => props.delay};
`;

export const ServerLoader = () => {
  return (
    <LoaderContainer style={{flexDirection: "column"}}>
      <StyledServerLoaderContainer>
        <StyledTars>  
          <StyledServer delay={'1s'}>
            <StyledShape>
              <div className="f"/>
              <div className="b"/>
              <div className="l"/>
              <div className="r"/>
              <div className="t"/>
              <div className="bot"/>
            </StyledShape>            
          </StyledServer>

          <StyledServer delay={'1.3s'}>
            <StyledShape>
              <div className="f"/>
              <div className="b"/>
              <div className="l"/>
              <div className="r"/>
              <div className="t"/>
              <div className="bot"/>
            </StyledShape>
          </StyledServer>

          <StyledServer delay={'1.6s'}>
            <StyledShape>
              <div className="f"/>
              <div className="b"/>
              <div className="l"/>
              <div className="r"/>
              <div className="t"/>
              <div className="bot"/>
            </StyledShape>
          </StyledServer>

          <StyledServer delay={'1.9s'}>
            <StyledShape>
              <div className="f"/>
              <div className="b"/>
              <div className="l"/>
              <div className="r"/>
              <div className="t"/>
              <div className="bot"/>
            </StyledShape>
          </StyledServer>
         </StyledTars>          
      </StyledServerLoaderContainer>
      <StyledText>Cargando
        <StyledDots delay={'0s'}> .</StyledDots>
        <StyledDots delay={'0.5s'}> .</StyledDots>
        <StyledDots delay={'1s'}> .</StyledDots>
      </StyledText>
    </LoaderContainer>
  );
};

// Loader spinner RGB
const spinAnimation = keyframes`
  to{
    transform: rotate(360deg);
  }
`;

const SpinnerContainer = styled.div`
  background-image: linear-gradient(rgb(186, 66, 255) 35%,rgb(0, 225, 255));
  width: 200px;
  height: 200px;
  animation: ${spinAnimation} 1.7s linear infinite;
  text-align: center;
  border-radius: 100px;
  filter: blur(1px);
  box-shadow: 0px -5px 20px 0px rgb(186, 66, 255), 0px 5px 20px 0px rgb(0, 225, 255);
`;

const SpinnerRGB = styled.div`
  background: linear-gradient(
    rgb(84, 106, 144),
    15%,
    rgb(84, 106, 144),
    60%,
    rgb(68, 111, 151));
  width: 200px;
  height: 200px;
  border-radius: 100px;
  filter: blur(8px);
`;

const StyledLogo = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${BACKGROUND_URL});
  background-repeat: no-repeat;
  background-size: 80% 60%;
  position: fixed;
  top: 15%;
  right: -10%;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  filter: brightness(120%) saturate(120%) opacity(120%);
`;

export const SpinnerRGBLoader = () => {
  return(
    <LoaderContainer>
      <SpinnerContainer>
        <SpinnerRGB/>
        <StyledLogo/>
      </SpinnerContainer>
    </LoaderContainer>
  )
}