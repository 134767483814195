import {
  legacy_createStore as createStore,
  combineReducers,
  applyMiddleware,
} from "redux";

import thunk from "redux-thunk";

import { composeWithDevTools } from "redux-devtools-extension";

import { cuentaActualizarReducer } from "./reducers/cuentaReducers";

import { usuarioInfoReducer } from "./reducers/sesionReducers";

import { usuarioListaReducer } from "./reducers/usuarioReducers";

import { clienteListaReducer } from "./reducers/clienteReducers";

import { productoListaReducer } from "./reducers/productoReducers";

import {
  rutaListaReducer,
  rutaDetallesReducer,
  rutaActualizarReducer,
  rutaRegistrarReducer,
  rutaBorrarReducer,
  rutaDiasListaReducer,
  rutaDiaDetallesReducer,
  clientesRutaDiaReducer,
  rutaDiaActualizarReducer,
  rutaSalidaRutaListaReducer,
} from "./reducers/rutaReducers";

import {
  salidaRutaListaReducer,
  salidaRutaRegistrarReducer,
  salidaRutaDetallesReducer,
  salidaRutaVentaReducer,
  salidaRutaActualizarReducer,
  salidaRutaAvisoVisitaReducer,
  salidaRutaDevolucionReducer,
  salidaRutaRecargaReducer,
} from "./reducers/salidaRutaReducers";

import {
  devolucionListaReducer,
  devolucionDetallesReducer,
  devolucionActualizarReducer,
} from "./reducers/devolucionReducers";

const middleware = [thunk];

const appReducer = combineReducers({
  // Cuenta
  usuarioInfo: usuarioInfoReducer,
  cuentaActualizar: cuentaActualizarReducer,
  // Usuario
  usuarioLista: usuarioListaReducer,
  // Productos
  productoLista: productoListaReducer,
  // Clientes
  clienteLista: clienteListaReducer,
  // Rutas
  rutaLista: rutaListaReducer,
  rutaDetalles: rutaDetallesReducer,
  rutaActualizar: rutaActualizarReducer,
  rutaRegistrar: rutaRegistrarReducer,
  rutaBorrar: rutaBorrarReducer,
  rutaDiasLista: rutaDiasListaReducer,
  rutaDiaDetalles: rutaDiaDetallesReducer,
  clientesRutaDia: clientesRutaDiaReducer,
  rutaDiaActualizar: rutaDiaActualizarReducer,
  rutaSalidaRutaLista: rutaSalidaRutaListaReducer,
  // Saluda Ruta
  salidaRutaLista: salidaRutaListaReducer,
  salidaRutaDetalles: salidaRutaDetallesReducer,
  salidaRutaRegistrar: salidaRutaRegistrarReducer,
  salidaRutaVenta: salidaRutaVentaReducer,
  salidaRutaActualizar: salidaRutaActualizarReducer,
  salidaRutaAvisoVisita: salidaRutaAvisoVisitaReducer,
  salidaRutaDevolucion: salidaRutaDevolucionReducer,
  salidaRutaRecarga: salidaRutaRecargaReducer,
  // Devoluciones
  devolucionLista: devolucionListaReducer,
  devolucionDetalles: devolucionDetallesReducer,
  devolucionActualizar: devolucionActualizarReducer,
});


const rootReducer = (state, action) => {
  if (action.type === "LOGOUT_USUARIO") {

    state = undefined
  }

  return appReducer(state, action)
}

const token = localStorage.getItem("accessToken")
  ? JSON.parse(localStorage.getItem("accessToken"))
  : null;

const initialState = {
  usuarioInfo: {
    token,
  },
};

const store = createStore(
  rootReducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
