import styled from 'styled-components';
import { Form } from 'react-bootstrap';
import { StyledButton } from '../../componentes/general/styles/Boton.styles';

export const Main = styled.div`
  height: 88vh;
  width: 100%;
  padding: 5rem 0rem;
  overflow: auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  h1, h2, h3 {
    color: white;
  }
`;

// Estilos del boton
export const StyledBoton = styled(StyledButton)`
  max-width: 200px;
  width: 100%;
  height: 50px;
  margin: 10px 0;
  font-weight: bold;
  font-size: 0.9rem;
`;

export const StyledButtonContainer =  styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledForm = styled(Form)`
  width: 80%;
  max-width: 400px;

  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    width: 50%;
  }
`;


// Estilos de los campos del formulario
export const StyledFormGroup = styled(Form.Group)`

  width: 80%;
  label {
    color: var(--font-color-label);
    font-weight: var(--font-weight-label);
    font-size: var(--font-size-label);
  }

  input {
    color: var(--font-color-input);
    font-weight: var(--font-weight-input);
    font-size: var(--font-size-input);
    margin-bottom: 2rem;
  }
`;