import { Form } from "react-bootstrap";
import styled, { keyframes } from "styled-components";
import { BASE_URL } from "../../constantes/constantes";
import { StyledButton } from "../../componentes/general/styles/Boton.styles";

const BACKGROUND_URL = `${BASE_URL}/static/imagenes/general/background.jpg`;

export const StyledBackground = styled.div`
  position: relative;
  width: 100%;
  height: 88vh;
  background: linear-gradient(rgba(0, 0, 0, 0), 80%, rgba(0, 0, 0, 0.5)),
    url(${BACKGROUND_URL});
  background-size: 100% 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;

  @media screen and (max-width: 1200px){
    height: 100%;
  }
`;

export const StyledContainer = styled.div`
  position: relative;
  height: 90%;

  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const StyledLogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    height: 90%;
    width: 200px;
    
    @media screen and (max-height:500px){
      height: 90%;
      width: 120px;
    }
  }
`;

export const StyledForm = styled(Form)`
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  align-items: center;
  background-color: rgba(185, 185, 185, 0.4);
  box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.5);
  min-width: 300px;

  height: 350px;
  text-align: center;

  label {
    color: rgb(30, 50, 120);
    font-weight: bold;
  }

  input {
    margin-bottom: 20px;
    min-width: 280px;

    @media screen and (min-height: 300px) and (max-height: 400px){
      margin-bottom: 0px;
      padding: 0.25rem 1.5rem;
    }
  }

  @media screen and (min-height: 300px) and (max-height: 400px){
    height: 250px; 
  }
`;

export const StyledBoton = styled(StyledButton)`
  color: ${props => props.fontcolor};
  @media screen and (min-height: 300px) and (max-height: 400px){
    font-size: 1rem;
    padding: 10px;
    margin-top: 7px;
  }
`;

export const StyledControl = styled.div`
  position: relative;
  margin: 20px 0px 0px 0px;
  width: 100%;
`;

export const StyledInput = styled.input`
  background-color: rgba(255,255,255,0.6);
  border: 0;
  border-radius: 5px;
  height: 70%;
  border-bottom: 2px black solid;
  display: block;
  width: 100%;
  padding: 15px 0;
  font-size: 18px;
  color: black;

  box-shadow: none;
  transition-property: all;
  transition-duration: 0.5s;

  &:focus,
  &:valid {
    outline: 0;
    transition: 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    margin-top: 10px;
    padding: 10px;
    padding-left: 30px;

    border-radius: 5px;
    box-shadow: inset 2px 2px 15px 2px rgba(0,0,0,0.15);
    border-bottom-color: black;
    background-color: rgba(240,240,240,1);
    height: 60%;

    &+label {
      i{
        display: inline-block;
        transform: translateX(-3px) translateY(5px);
      }
    }
  }

  &:focus+label span,
  &:valid+label span {
    color: #002184;
    transform: translateY(-30px) translateX(-1.1rem);
    font-size: 16px;
    font-weight: bold;
  }

  &:focus ~ .bar:before,
  &:focus ~ .bar:after {
    width: 20%;
    transform: scaleX(4);
  }
`;

export const StyledLabel = styled.label`
  position: absolute;
  top: 15px;
  padding-left: 0.8rem;
  left: 0;
  pointer-events: none;

  i {
    display: none;
    color: #001be3;
  }
`;

export const StyledSpan = styled.span`
  display: inline-block;
  font-size: 18px;
  min-width: 5px;
  color: black;
  transition: 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition-delay: ${props => props.delay};
`;

export const StyledBar = styled.span`
  position: relative;
  display: block;
  width: 100%;
  transition: 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);

  &::before, &::after {
    content: ' ';
    height: 4px;
    border-radius: 4px;
    bottom: 20px;
    position: absolute;
    background: #002184;
    transition: 0.5s ease ;
    -moz-transition: 0.5s ease ;
    -webkit-transition: 0.5s ease ;
  }

    &:before {
      left: 50%;
    }

    &:after {
      right: 50%;
    }
`;

export const ForgotPswdContainer = styled.div`
  width: 100%;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #002184;
  padding-top: 20px;
  font-weight: bold;
  cursor: pointer;
  transition-property: ease all;
  transition-duration: 0.5s;

  &:hover{
    filter: brightness(250%);
    font-size: 15px;
  }
`;