import React, { useEffect } from "react";
// Estilos del componente
import {
  StyledModal,
  StyledModalBody,
  StyledModalHeader,
  StyledModalFooter,
} from "./styles/VentanaMostrarRuta.styles";
import { StyledButton } from "../general/styles/Boton.styles";
import { useDispatch, useSelector } from "react-redux";
import { obtenerClientesRutaDia } from "../../actions/rutaActions";
import { useRef } from "react";

const VentanaMostrarModalRutaDias = ({
  ruta,
  mostrarRuta,
  manejarCerrarVentana,
}) => {
  // Funcion para disparar el creador de acciones
  const dispatch = useDispatch();

  // Obtener los clientes del Redux store
  const clientesRutaDia = useSelector((state) => state.clientesRutaDia);
  const { clientesRuta } = clientesRutaDia;

  // Obtener referencia del boton
  const cerrarRef = useRef(null);

  // Obtener los clientes que tiene esta ruta dia en su lista de rutas
  useEffect(() => {
    dispatch(obtenerClientesRutaDia(ruta.id));
  }, [ruta.id, dispatch]);

  return (
    ruta && (
      <StyledModal centered show={mostrarRuta} onHide={manejarCerrarVentana}>
        <StyledModalHeader>
          <h4>Detalles de la ruta #{ruta.id}</h4>
        </StyledModalHeader>

        <StyledModalBody>
          <h5>Datos de la ruta</h5>
          <p>
            <strong>Nombre:</strong> {ruta.NOMBRE}
          </p>
          <p>
            <strong>Dia:</strong> {ruta.DIA}
          </p>

          <p>
            <strong>Repartidor:</strong> {ruta.REPARTIDOR_NOMBRE}
          </p>

          <h5>Clientes de la ruta</h5>

          {clientesRuta &&
            clientesRuta.map((nombre, index) => <p key={index}>{nombre}</p>)}
        </StyledModalBody>

        <StyledModalFooter>
          <StyledButton
            color="blue"
            ref={cerrarRef}
            onFocus={() => cerrarRef.current.blur()}
            onClick={() => {manejarCerrarVentana()}}
          >
            Cerrar
          </StyledButton>
        </StyledModalFooter>
      </StyledModal>
    )
  );
};

export default VentanaMostrarModalRutaDias;
