import {
  FAIL_DEVOLUCION_ACTUALIZAR,
  FAIL_DEVOLUCION_DETALLES,
  FAIL_DEVOLUCION_LISTA,
  REQUEST_DEVOLUCION_ACTUALIZAR,
  REQUEST_DEVOLUCION_DETALLES,
  REQUEST_DEVOLUCION_LISTA,
  RESET_DEVOLUCION_LISTA,
  SUCCESS_DEVOLUCION_ACTUALIZAR,
  SUCCESS_DEVOLUCION_DETALLES,
  SUCCESS_DEVOLUCION_LISTA,
} from "../constantes/devolucionConstantes";
import axiosInstance from "../lib/axiosConfig";
import axios from "axios";
import { actualizarAccessToken } from "./sesionActions";

export const pedirDevolucionesLista =
  (search = "", cancelToken) =>
    async (dispatch) => {
      dispatch({ type: REQUEST_DEVOLUCION_LISTA });
      // setTimeout(async () => {
      try {
        const { data } = await axiosInstance.get(
          `api/devoluciones/${search}`,
          {
            cancelToken,
          }
        );

        dispatch({ type: SUCCESS_DEVOLUCION_LISTA, payload: data });
      } catch (error) {
        if (axios.isCancel(error)) console.log(error.message);
        // Si el backend responde con un error 401 (no autorizado) intentar actualizar el token
        if (error.response && error.response.status === 401) {
          dispatch(actualizarAccessToken(pedirDevolucionesLista, search, cancelToken));
        } else {
          dispatch({ type: FAIL_DEVOLUCION_LISTA, payload: error.message });
        }
      }
      // }, 1500);
    };

export const obtenerDevolucionDetalles =
  (devolucionId) => async (dispatch) => {
    dispatch({ type: REQUEST_DEVOLUCION_DETALLES });

    try {

      const { data } = await axiosInstance.get(
        `api/devoluciones/${devolucionId}`,

      );

      dispatch({ type: SUCCESS_DEVOLUCION_DETALLES, payload: data });
      dispatch({ type: RESET_DEVOLUCION_LISTA });
    } catch (error) {
      // Si el backend responde con un error 401 (no autorizado) intentar actualizar el token
      if (error.response && error.response.status === 401) {
        dispatch(
          actualizarAccessToken(obtenerDevolucionDetalles, devolucionId)
        );
      } else {
        dispatch({ type: FAIL_DEVOLUCION_DETALLES, payload: error.message });
      }
    }
  };

export const actualizarDevolucion =
  (devolucion) => async (dispatch) => {
    dispatch({ type: REQUEST_DEVOLUCION_ACTUALIZAR });

    try {

      const { data } = await axiosInstance.put(
        `api/devoluciones/${devolucion.id}/`,
        devolucion,
      );

      dispatch({ type: SUCCESS_DEVOLUCION_ACTUALIZAR });
      dispatch({ type: RESET_DEVOLUCION_LISTA });
    } catch (error) {
      // Si el backend responde con un error 401 (no autorizado) intentar actualizar el token
      if (error.response && error.response.status === 401) {
        dispatch(actualizarAccessToken(actualizarDevolucion, devolucion));
      } else {
        dispatch({ type: FAIL_DEVOLUCION_ACTUALIZAR, payload: error.message });
      }
    }
  };
