import { Col, Row, Form, Modal } from "react-bootstrap";

import styled from "styled-components";
import { StyledButton } from "../../general/styles/Boton.styles";

export const StyledRow = styled(Row)`
  width: 100%;
  display: flex;
  align-items: center;
  max-width: 120rem;
`;

export const StyledCol = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
`;

export const StyledFormGroup = styled(Form.Group)`
  width: 50%;
  min-width: 200px;
  padding-left: 1rem;
  label {
    color: var(--font-color-input);
    font-weight: var(--font-weight-label);
    font-size: var(--font-size-label);
  }

  input {
    color: var(--font-color-input);
    font-weight: var(--font-weight-input);
    font-size: var(--font-size-input);
    margin-bottom: 1rem;
    height: 45px;
  }
`;

export const StyledButtonConfirmar = styled(StyledButton)`
  position: fixed;
  bottom: 0;
  left: 0;
  margin-bottom: 15px;
  margin-left: 15px;
  font-weight: "bold";
`;

export const StyledButtonCancelar = styled(StyledButton)`
  font-weight: bold;
`;


export const StyledModal = styled(Modal)`

  .modal-dialog {
    width: 400px;
  }

  .modal-header {
    h4{
      font-weight: bold; 
      color: black; 
    }
  }

  .modal-body {
    width: 100%;

    div {
      width: 275px;
    }
  }
`;