import React, { useState } from "react";
import { Form } from "react-bootstrap";
import {
  StyledProductoContenedor,
  StyledProductoInfoContenedor,
  StyledOptionsContainer,
  StyledSeleccionadorCantidad,
  StyledSubtotal,
  StyledSeleccionadorForm,
  StyledButtonsContainer,
} from "./styles/FormularioProductoSalidaRuta.styles";
import BotonOpcionesProducto from "./BotonOpcionesProducto";

import { BASE_URL } from "../../constantes/constantes";
import UseScreenSize from "../../componentes/general/UseScreenSize";

const FormularioProductoSalidaRuta = ({
  producto,
  manejarCambioCantidad,
  manejarConfirmarProducto,
  manejarCancelarProducto,
}) => {
  // Estado para la ventana emergente con la informacion del producto
  const [mostrarProducto, setMostrarProducto] = useState(false);

  // Hook para mostrar ventana con informacion del producto
  const [product, setProduct] = useState({});

  // Funcion para mostrar informacion del producto
  const manejarMostrarDetallesProducto = (producto) => {
    const productoSeleccionado = producto;

    setProduct(productoSeleccionado);
    setMostrarProducto(true);
  };

  // Obtener ancho y alto de la pantalla
  const {ancho, alto } = UseScreenSize();

  return (
    <StyledProductoContenedor
      confirmado={producto.confirmado}
      onClick={() => manejarMostrarDetallesProducto(producto)}
    >

    {/* Informacion del producto */}
    <StyledProductoInfoContenedor
      confirmado={producto.confirmado}
    >
      <span>{producto.NOMBRE} </span>
      <img
        src={`${BASE_URL}${producto.IMAGEN}`}
        alt={producto.NOMBRE}
      />
    </StyledProductoInfoContenedor>

      <StyledOptionsContainer>
        {/* Input para seleccionar cantidad */}
        <StyledSeleccionadorCantidad onClick={(e) => e.stopPropagation()}>
          <StyledSubtotal confirmado={producto.confirmado}>
            <span>
              <span style={{ fontWeight: "bold" }}>Precio: </span>$
              {producto.PRECIO}
            </span>

            <span>
              <span style={{ fontWeight: "bold" }}>Inventario: </span>
              {producto.CANTIDAD.toFixed(2)}
            </span>

            <span>
              <span style={{ fontWeight: "bold" }}>Seleccionados: </span>
              {producto.cantidadSalidaRuta}
            </span>
          </StyledSubtotal>
          <StyledSeleccionadorForm controlId={producto.id}>
            <Form.Control
              disabled={producto.confirmado}
              type="number"
              step={'any'}
              value={producto.cantidadSalidaRuta}
              onChange={(e) => {
                manejarCambioCantidad(e.target.value, producto.id);
              }}
            />
          </StyledSeleccionadorForm>
        </StyledSeleccionadorCantidad>
        <StyledButtonsContainer>
            {!producto.confirmado ? (
              <BotonOpcionesProducto
                color="green"
                producto={producto}
                onClick={() => manejarConfirmarProducto(producto.id)}
                style={{ gridArea: "Confirmar-Modificar" }}
              >
                <i className="fa-solid fa-check" />
              </BotonOpcionesProducto>
            ) : (
              <BotonOpcionesProducto
                color="blue"
                producto={producto}
                onClick={() => manejarConfirmarProducto(producto.id)}
                style={{ gridArea: "Confirmar-Modificar" }}
              >
                <i className="fa-solid fa-pen-to-square" />
              </BotonOpcionesProducto>
            )}

            <BotonOpcionesProducto
              color="red"
              producto={producto}
              onClick={() => manejarCancelarProducto(producto.id)}
              disabled={producto.confirmado}
              style={{ gridArea: "Eliminar" }}
            >
              <i className="fa-solid fa-xmark" />
            </BotonOpcionesProducto>
          </StyledButtonsContainer>
      </StyledOptionsContainer>
    </StyledProductoContenedor>
  );
};

export default FormularioProductoSalidaRuta;
