import React from "react";

import { useMediaQuery } from "react-responsive";
import {
  StyledButtonContainer,
  StyledGenericButton,
  TableStyled,
  StyledTd,
  StyledBoton,
  StyledLockContainer,
} from "./styles/TablaDevoluciones.styles";
import { useRef } from "react";
import useScreenSize from "../general/UseScreenSize";
import { formatearFecha } from "../../utilitis";

const TablaDevoluciones = ({
  devoluciones,
  // manejarMostrarDetallesDevolucion,
  manejarDevolucionDetalles,
  manejarMostrarModalDevolucion,
}) => {
  // Determinar si el ancho de la pantalla es small o menor

  const botonRef = useRef(null);
  const isSmallViewport = useMediaQuery({ maxWidth: 768 });
  const shouldShow = !isSmallViewport;
  const { ancho, alto } = useScreenSize();

  // Obtener referencias de los botones
  const editRef = useRef(null);

  // Verificar si es administrador para renderizar algunas propiedades
  const isAdmin = JSON.parse(localStorage.getItem("isAdmin"));
  // Renderizar tabla de rutas
  return (
    <TableStyled striped hover>
      <thead>
        <tr>
          <>
            {ancho > 600 ? 
              <th>ID</th> : null

            }

            {ancho > 1080 ? 
              <th>ATIENDE</th> :
              null
            }
            <th>PRODUCTO</th>

            {ancho > 1020 ? 
              <th>CANTIDAD</th> : null
            }
          </>

          {ancho > 510 ?
              <th>STATUS</th> : null
          }

          {ancho >= 400 && (
            <>
              <th>REPARTIDOR</th>
            </>
          )}

          <th>FECHA</th>

          {isAdmin && <th>DETALLES</th>}
        </tr>
      </thead>

      <tbody>
        {devoluciones.map((d) => (
          <tr key={d.id} onClick={() => manejarMostrarModalDevolucion(d.id)}>
            <>
              {ancho > 600 ?
                <th>{d.id}</th> : null
              }
              
              {ancho > 1080 ? 
                <th>{d.ATIENDE}</th> : null
              }
              <th>{d.PRODUCTO_NOMBRE}</th>

              {ancho > 1020 ? 
                <th>{d.CANTIDAD_DEVOLUCION}</th> : null
              }
              
            </>

            {ancho > 510 ? 
                <th>{d.STATUS}</th> : null            
            }

            {ancho >= 400 && (
              <>
                <th>{d.REPARTIDOR}</th>
              </>
            )}

            <th>{formatearFecha(d.FECHA)}</th>  

            {isAdmin && (
              <td>
                <StyledButtonContainer>
                  <Icon
                    ref={botonRef}
                    onFocus={() => botonRef.current.blur()}
                    status={d.STATUS}
                    id={d.id}
                    manejarDevolucionDetalles={manejarDevolucionDetalles}
                  />
                </StyledButtonContainer>
              </td>
            )}
          </tr>
        ))}
      </tbody>
    </TableStyled>
  );
};

export default TablaDevoluciones;

const Icon = ({ status, id, manejarDevolucionDetalles }) => {
  switch (status) {
    case "PENDIENTE":
      return (
        <StyledBoton
          color="standard"
          fontcolor="var(--active-blue-button)"
          onClick={() => manejarDevolucionDetalles(id)}
        >
          <i className="fa-regular fa-pen-to-square" />
        </StyledBoton>
      );

    case "REALIZADO":
      return (
        <StyledLockContainer
          color="standard"
          fontcolor="var(--active-green-button)"
          disabled={true}
        >
          <i className="fa fa-check" />
        </StyledLockContainer>
      );
  }
};
