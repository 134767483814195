import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { toast } from "react-hot-toast";

import Loader from "../componentes/general/Loader";
import Mensaje from "../componentes/general/Mensaje";
import {
  StyledCol,
  StyledContainer,
  StyledRow,
  StyledGridContainer,
  StyledBotonPanel,
  StyledTitulo,
  StyledPanelControl,
  StyledContenidoPrincipal,
} from "./styles/DevolucionesLista.styles";

// import VentanaMostrarAjusteInventario from "../componentes/AjusteInventarioLista/VentanaMostrarAjusteInventario";
// import { useMostrarDetallesAjusteInventario } from "../lib/hooks/useMostrarDetallesAjusteInventario";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { pedirDevolucionesLista } from "../actions/devolucionActions";
import TablaDevoluciones from "../componentes/DevolucionesLista/TablaDevoluciones";
import { RESET_DEVOLUCION_DETALLES } from "../constantes/devolucionConstantes";
import { useMostrarModalDevolucion } from "../lib/hooks/useMostrarModalDevolucion";
import VentanaMostrarDevolucion from "../componentes/DevolucionesLista/VentanaMostrarDevolucion";
import FiltroListaDevoluciones from "../componentes/DevolucionesLista/FiltroListaDevoluciones";
import PaginacionDevolucion from "../componentes/DevolucionesLista/PaginacionDevolucion";
// import PaginacionAjusteInventario from "../componentes/AjusteInventarioLista/PaginacionAjusteInventario";

const DevolucionesLista = () => {
  // Funcion para disparar las acciones
  const dispatch = useDispatch();
  // Funcion para nevagar en la aplicacion
  const navigate = useNavigate();

  // Function para obtener el search param en el url
  const location = useLocation();
  const search = location.search;

  // Obtener lista de productos del Redux
  const devolucionLista = useSelector((state) => state.devolucionLista);
  const { loading, devoluciones, error, page, pages } = devolucionLista;

  //Obtener referencias de los botones
  const panelMovilRef = useRef(null);

  // Custom hook para mostrar modal con detalles de salida ruta
  const {
    devolucion,
    mostrarModalDevolucion,
    manejarMostrarModalDevolucion,
    manejarCerrarVentana,
  } = useMostrarModalDevolucion(devoluciones);

  // Hook para mostrar y ocultar panel de control en pantallas pequenas
  const [mostrarFormulario, setMostrarFormulario] = useState(true);

  // useEffect para obtener productos del Redux store
  useEffect(() => {
    const source = axios.CancelToken.source();

    dispatch(pedirDevolucionesLista(search, source.token));


    return () => {
      source.cancel("Petición de lista devoluciones  cancelada");
    };
  }, [dispatch, search]);

  const manejarDevolucionDetalles = (id) => {
    // Redireccionar a la pagina de la salidaRuta
    dispatch({ type: RESET_DEVOLUCION_DETALLES });
    navigate(`/devoluciones/${id}`);
  };

  // Renderizar loading si se estan cargando ajuste inventario
  if (loading)
    return (
      <Loader />
    );

  // Renderizar mensaje de errors si el servidor regresa un error al pedir la lista de productos
  if (error)
    return (
      <StyledContainer fluid>
        <StyledRow>
          <StyledCol>
            <Mensaje variant="danger">
              Hubo un error al cargar la lista de devoluciones
            </Mensaje>
          </StyledCol>
        </StyledRow>
      </StyledContainer>
    );

  // Si se obtienen los productos renderizar la tabla de productos
  return (
    devoluciones && (
      <>
        <StyledGridContainer>
          <StyledBotonPanel
            color="standard"
            fontcolor="var(--active-blue-button)"
            ref={panelMovilRef}
            onFocus={() => panelMovilRef.current.blur()}
            onClick={() => setMostrarFormulario((state) => !state)}
            state={mostrarFormulario}
          >
            <i className="fa-solid fa-arrow-right" />
          </StyledBotonPanel>
          <StyledTitulo>Devoluciones</StyledTitulo>
          {/* Panel de control para filtrar y ordenar ventas */}
          <StyledPanelControl mostrarPanel={mostrarFormulario}>
            <FiltroListaDevoluciones />
          </StyledPanelControl>

          {/* Tabla de ventas */}
          <StyledContenidoPrincipal>
            <TablaDevoluciones
              devoluciones={devoluciones}
              manejarDevolucionDetalles={manejarDevolucionDetalles}
              manejarMostrarModalDevolucion={manejarMostrarModalDevolucion}
            />

            <PaginacionDevolucion page={page} pages={pages} search={search} />
          </StyledContenidoPrincipal>
        </StyledGridContainer>

        {/* Mostrar ventana con detalles del ajuste inventario */}
        {mostrarModalDevolucion && (
          <VentanaMostrarDevolucion
            devolucion={devolucion}
            mostrarDevolucion={mostrarModalDevolucion}
            manejarCerrarVentana={manejarCerrarVentana}
          />
        )}
      </>
    )
  );
};

export default DevolucionesLista;
