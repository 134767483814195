import React, { useRef } from "react";
import {
  StyledClienteContenedor,
  StyledClienteInfoContenedor,
  StyledButtonContainer,
  StyledBoton,
} from "./styles/FormularioClienteSalidaRuta.styles";

const FormularioClienteSalidaRuta = ({
  cliente,
  ruta,
  manejarConfirmarCliente,
  manejarCancelarCliente,
}) => {

  // Obtener referencias de botones
  const confirmarRef = useRef(null);
  const editarRef = useRef(null);
  const eliminarRef = useRef(null);

  return (
    <StyledClienteContenedor confirmado={cliente.confirmado}>
      <StyledClienteInfoContenedor confirmado={cliente.confirmado}>
        <p>
          <span>ID:</span> {cliente.id}
        </p>
        <p>
          <span>NOMBRE:</span> {cliente.NOMBRE}
        </p>
        <p>
          <span>RUTA:</span> {ruta.rutaNombre}
        </p>
      </StyledClienteInfoContenedor>

      <StyledButtonContainer>
        
        {!cliente.confirmado ? (
          <StyledBoton
            color="green"
            ref={confirmarRef}
            onFocus={() => confirmarRef.current.blur()}
            onClick={() =>manejarConfirmarCliente(cliente.id)}
          >
            <i className="fa-solid fa-check" />
          </StyledBoton>
        ) : (
          <StyledBoton
            color="blue"
            ref={editarRef}
            onFocus={() => editarRef.current.blur()}
            onClick={() => manejarConfirmarCliente(cliente.id)}
          >
            <i className="fa-solid fa-pen-to-square" />
          </StyledBoton>
        )}

        <div>
          <StyledBoton
            color="red"
            disabled={cliente.confirmado}
            ref={eliminarRef}
            onFocus={() => eliminarRef.current.blur()}
            onClick={() => manejarCancelarCliente(cliente.id)}
          >
            <i className="fa-solid fa-xmark" />
          </StyledBoton>
        </div>
      </StyledButtonContainer>
    </StyledClienteContenedor>
  );
};

export default FormularioClienteSalidaRuta;
