import styled from "styled-components";
import { Form } from "react-bootstrap";
import { StyledButton } from "../../general/styles/Boton.styles";

// Estilos de los campos del formulario
export const StyledFormGroup = styled(Form.Group)`
  width: 400px;
  height: 100%;

  label {
    color: var(--font-color-label);
    font-weight: var(--font-weight-label);
    font-size: var(--font-size-label);
    margin-top: 0.8rem;
  }

  input,
  select {
    color: var(--font-color-input);
    font-weight: var(--font-weight-input);
    font-size: var(--font-size-input);
    margin-bottom: 1rem;
    height: calc(var(--font-size-input) + 25px);
    padding-top: 0px;
    padding-bottom: 0;
    max-width: 90%;

    @media screen and (max-width: 900px){
      margin: 0rem;
    }
  }

  @media screen and (max-width: 900px){
    width: 100%;
    height: 100%;
  }
`;

// Estilos del boton
export const StyledBoton = styled(StyledButton)`
  max-width: 200px;
  height: 50px;
  margin: 10px 0;
  background-color: var(--active-green-button);
  color: white;
  font-weight: bold;
  font-size: 0.9rem;
  box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.5);

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: var(--hover-green-button);
    box-shadow: 0px 2px 5px 2px rgba(0, 161, 0, 0.8);
    color: var(--white-color);
  }

  &:disabled {
    background-color: var(--disabled-green-button);
  }
`;
