import styled, { keyframes } from "styled-components";
import { StyledButton } from "../../general/styles/Boton.styles";

export const StyledClienteContenedor = styled.div`
  box-shadow: ${props => props.confirmado== true ? 
    '1px 1px 5px 2px rgba(255, 255, 255, 0.7)':
    '1px 1px 5px 2px rgba(255, 255, 255, 0.8)'
  };
  height: 160px;
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  background: ${props => props.confirmado == true ? 
    'none':
    'linear-gradient(rgba(255, 255, 255, 0.8) 10%, transparent 95%)'
  };
  background-color: ${props => props.confirmado==true ? 
    'rgba(220, 220, 220, 0.15)':
    'rgba(255,255,255,0.3)'
  };
  border-radius: 10px;
  transition: background 1s;
  animation: ${props => props.confirmado ?  colorChange: 'none'} 1s ease-in 
             ${props => !props.confirmado ? colorChangeInv: 'none'} 1s ease-out;

  @media screen and (max-width: 450px){
    height: 180px;
    gap: 7px;
  }
`;

export const StyledClienteInfoContenedor = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 5px;
  flex-wrap: wrap-reverse;
  width: 100%;
  font-size: 17px;
  justify-content: left;
  padding-top: 1rem;
  padding-left: 10px;

  p{
    color: ${props => props.confirmado ? 'rgb(255,255,255)':'rgb(0,0,0)'};
  }

  span {
    color: ${props => props.confirmado ? 'rgb(255,255,255)':'rgb(0,0,0)'};
    font-weight: bold;
  }

  @media screen and (max-width: 450px){
    font-size: 12px;
  }
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
  height: 100%;
  justify-content: space-between;
  align-items: end;
  padding: 4px 30px;

  @media screen and (max-width: 400px){
    justify-content: space-around;
    padding: 4px 4px;
  }
  
`;

export const StyledBoton = styled(StyledButton)`
  width: 90px;
  font-weight: bold;
  border-radius: 25px;

  @media screen and (max-width: 1000px){
    width: 70px;

    @media screen and (max-width: 400px){
      width: 50px;
      padding: 8px 10px;
    }
  }
`;

const colorChange = keyframes`
  from{
    background: none;
  }
  to{
    bakcground: linear-gradient(rgba(255, 255, 255, 0.8) 5%, transparent 90%);
  }
`;

const colorChangeInv = keyframes`
  from{
    background: linear-gradient(rgba(255, 255, 255, 0.8) 5%, transparent 90%);
  }
  to{
    bakcground: none;
  }
`;
