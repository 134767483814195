import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { pedirSalidaRutasLista } from "../actions/salidaRutaActions";

import Loader from "../componentes/general/Loader";
import Mensaje from "../componentes/general/Mensaje";
import TablaSalidasRuta from "../componentes/SalidaRuta/TablaSalidasRuta";
import VentanaMostrarModalSalidaRuta from "../componentes/SalidaRuta/VentanaMostrarModalSalidaRuta";
import FiltroListaSalidaRutas from "../componentes/SalidaRuta/FiltroListaSalidaRutas";

import { RESET_SALIDA_RUTA_DETALLES } from "../constantes/salidaRutaConstantes";

// Estilos de la pagina
import {
  StyledContainer,
  StyledGridContainer,
  StyledTitulo,
  StyledContenidoPrincipal,
  StyledBotonPanel,
} from "./styles/SalidaRutasLista.styles";
import { useMostralModalSalidaRuta } from "../lib/hooks/useMostralModalSalidaRuta";
import { StyledFormContainer } from "../componentes/SalidaRuta/styles/FiltroListaSalidaRutas.styles";
import PaginacionSalidaRuta from "../componentes/SalidaRuta/PaginacionSalidaRuta";

const SalidaRutaLista = () => {
  // Verificar si es repartidor
  const role = JSON.parse(localStorage.getItem("role"));
  // Funcion para disparar las acciones
  const dispatch = useDispatch();
  // Funcion para nevagar en la aplicacion
  const navigate = useNavigate();

  // Function para obtener el search param en el url
  const location = useLocation();
  const search = location.search;

  // Obtener el estado desde el Redux store
  const salidaRutaLista = useSelector((state) => state.salidaRutaLista);
  const { loading, salidaRutas, error, page, pages } = salidaRutaLista;

  // Obtener la referencia del boton
  const panelMovilRef = useRef(null);

  // Hook para mostrar y ocultar panel de control en pantallas pequenas
  const [mostrarFormulario, setMostrarFormulario] = useState(true);

  // Custom hook para mostrar modal con detalles de salida ruta
  const {
    salidaRuta,
    mostrarModalSalidaRuta,
    manejarMostrarModalSalidaRuta,
    manejarCerrarVentana,
  } = useMostralModalSalidaRuta(salidaRutas);

  // useEffect para pedir salida rutas
  useEffect(() => {
    // Si no hay salidaRutas, disparar la accion de pedir salidaRutas

    const source = axios.CancelToken.source();

    if (!salidaRutas) {
      console.log("search", search);

      dispatch(pedirSalidaRutasLista(search, role, source.token));
    }

    return () => {
      source.cancel("Petición de lista salida ruta cancelada");
    };
  }, [dispatch, search, salidaRutas]);

  // useEffect para pedir salida rutas
  useEffect(() => {
    // Si el parametros search cambia, disparar la accion de pedir salidaRutas

    const source = axios.CancelToken.source();

    dispatch(pedirSalidaRutasLista(search, role, source.token));

    return () => {
      source.cancel("Petición de lista salida ruta cancelada");
    };
  }, [dispatch, search]);

  const manejarSalidaRutaDetalles = (id) => {
    // Redireccionar a la pagina de la salidaRuta
    dispatch({ type: RESET_SALIDA_RUTA_DETALLES });
    navigate(`/salida-rutas/${id}`);
  };

  const manejarSalidaRutaResumen = (id) => {
    // Redireccionar a la pagina del resumen de la salida a ruta
    navigate(`/resumen-salida-ruta/${id}`);
  };

  const manejarSalidaRutaVenta = (e, salidaRutaId) => {
    e.stopPropagation();
    // alert(salidaRutaId);
    // dispatch({ type: RESET_SALIDA_RUTA_VENTA });
    navigate(`/venta-salida-ruta/${salidaRutaId}`);
  };

  // Renderizar loading si se estan cargando las rutas
  if (loading) return <Loader />;

  // Renderizar mensaje de error si el servidor regresa un error al pedir la lista de salidas a ruta
  if (error)
    return (
      <StyledContainer>
        <Mensaje variant="danger">
          Hubo un error al cargar la lista de salidas a ruta
        </Mensaje>
      </StyledContainer>
    );

  return (
    salidaRutas && (
      <>
        <StyledGridContainer>
          <StyledTitulo>Salida Rutas</StyledTitulo>

          <div>
            <StyledBotonPanel
              color="standard"
              fontcolor="var(--active-blue-button)"
              ref={panelMovilRef}
              onFocus={() => panelMovilRef.current.blur()}
              onClick={() => setMostrarFormulario((state) => !state)}
              state={mostrarFormulario}
            >
              <i className="fa-solid fa-arrow-right" />
            </StyledBotonPanel>
          </div>

          <StyledFormContainer mostrarpanel={mostrarFormulario}>
            <FiltroListaSalidaRutas />
          </StyledFormContainer>

          <StyledContenidoPrincipal>
            <TablaSalidasRuta
              salidaRutas={salidaRutas}
              manejarMostrarModalSalidaRuta={manejarMostrarModalSalidaRuta}
              manejarSalidaRutaDetalles={manejarSalidaRutaDetalles}
              manejarSalidaRutaResumen={manejarSalidaRutaResumen}
              manejarSalidaRutaVenta={manejarSalidaRutaVenta}
            />

            <PaginacionSalidaRuta page={page} pages={pages} search={search} />
          </StyledContenidoPrincipal>
        </StyledGridContainer>

        {/* Mostrar venta */}
        {mostrarModalSalidaRuta && (
          <VentanaMostrarModalSalidaRuta
            salidaRuta={salidaRuta}
            mostrarModalSalidaRuta={mostrarModalSalidaRuta}
            manejarCerrarVentana={manejarCerrarVentana}
          />
        )}
      </>
    )
  );
};

export default SalidaRutaLista;
