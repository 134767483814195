import {
  StyledBoton,
  StyledBotonContainer,
  StyledForm,
  StyledFormControlContainer,
  StyledFormGroup,
} from "./styles/FiltroListaSalidaRutas.styles";
import { guardarFiltros } from "../../lib/utilis/salidaRutas";
import { useFiltroSalidaRutas } from "../../lib/hooks/useFiltrosSalidaRutas";
import { useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";
import { useRef } from "react";

const FiltroListaSalidaRutas = () => {
  // Funcion para navegar de regreso a la pagina de lista salida a rutas con el url modificado
  const navigate = useNavigate();

  // Obtener referencia del boton
  const actualizarRef = useRef(null);

  // Custom hook para manejar el estado del filtro
  const {
    buscar,
    filtrarPor,
    ordenarPor,
    fechaInicio,
    fechaFinal,
    url,
    modificarFiltro,
  } = useFiltroSalidaRutas();

  const manejarSubmit = (e) => {
    e.preventDefault();

    // Guardar el valor de los filtros para recuperarlos despues de renderizar el componente
    guardarFiltros(buscar, filtrarPor, ordenarPor, fechaInicio, fechaFinal);
    // Volver a renderizar la pagina de la lista salidas a ruta con el url actualizado
    navigate(url);
  };

  return (
    <StyledForm onSubmit={manejarSubmit}>
      <StyledFormGroup>
        <Form.Label htmlFor="filtrarPor">BUSCAR COINCIDENCIA:</Form.Label>
        <Form.Control
          as="select"
          id="filtrarPor"
          name="filtrarPor"
          value={filtrarPor}
          onChange={(e) => modificarFiltro(e)}
        >
          <option value="atiende">Por empleado que atiende</option>
          <option value="repartidor_nombre">Por repartidor</option>
          <option value="status">Por status</option>
        </Form.Control>

        <Form.Control
          type="text"
          autoComplete="off"
          value={buscar}
          name="buscar"
          onChange={(e) => modificarFiltro(e)}
        />
      </StyledFormGroup>

      <StyledFormGroup>
        <Form.Label htmlFor="ordenarPor">ORDENAR TABLA:</Form.Label>
        <Form.Control
          as="select"
          id="ordenarPor"
          name="ordenarPor"
          value={ordenarPor}
          onChange={(e) => modificarFiltro(e)}
        >
          <option value="fecha_recientes">
            Por fecha (Más recientes primero)
          </option>
          <option value="fecha_antiguos">
            Por fecha (Más antiguas primero)
          </option>
          <option value="atiende">Por el nombre del que atiende</option>
          <option value="repartidor">Por el nombre del repartidor</option>
        </Form.Control>
      </StyledFormGroup>

      <StyledFormGroup>
        <Form.Label>FILTRAR ENTRE RANGO DE FECHAS</Form.Label>
        <StyledFormControlContainer>
          <Form.Control
            type="date"
            id="fechaInicio"
            name="fechaInicio"
            value={fechaInicio}
            onChange={(e) => modificarFiltro(e)}
          />
          <Form.Control
            type="date"
            id="fechaFinal"
            name="fechaFinal"
            value={fechaFinal}
            onChange={(e) => modificarFiltro(e)}
          />
        </StyledFormControlContainer>
      </StyledFormGroup>

      <StyledBotonContainer>
        <StyledBoton
          type="submit"
          color="green"
          ref={actualizarRef}
          onFocus={() => actualizarRef.current.blur()}
        >
          Actualizar Lista
        </StyledBoton>
      </StyledBotonContainer>
    </StyledForm>
  );
};

export default FiltroListaSalidaRutas;
