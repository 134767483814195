import React from "react";
// import { useMediaQuery } from "react-responsive";
import {
  StyledBoton,
  StyledBotonContainer,
  StyledLockContainer,
  TableStyled,
} from "./styles/TablaSalidasRuta.styles";
import { useMediaQuery } from "react-responsive";
import { useRef } from "react";
import UseScreenSize from "../general/UseScreenSize";
import { formatearFecha } from "../../utilitis";

const TablaSalidasRuta = ({
  salidaRutas,
  manejarMostrarModalSalidaRuta,
  manejarSalidaRutaDetalles,
  manejarSalidaRutaResumen,
  manejarSalidaRutaVenta,
}) => {
  // Determinar si el ancho de la pantalla es small o menor
  // const isSmallViewport = useMediaQuery({ maxWidth: 768 });
  // const shouldShow = !isSmallViewport;

  // Verificar si es administrador para renderizar algunas propiedades
  const isAdmin = JSON.parse(localStorage.getItem("isAdmin"));

  // Verificar si es repartidor
  const role = JSON.parse(localStorage.getItem("role"));

  // Obtener referencia del boton
  const botonRef = useRef(null);
  const ventaRef = useRef(null);
  const resumenRef = useRef(null);

  const isSmallViewport = useMediaQuery({ maxWidth: 768 });

  const shouldshow = !isSmallViewport;
  const { ancho, alto } = UseScreenSize();

  const show = true;

  // Renderizar la tabla de salidas a ruta
  return (
    <TableStyled striped hover>
      <thead>
        <tr>
          {ancho > 450 ? <th>ID</th> : null}

          {ancho > 1060 || (ancho <= 900 && ancho > 660) ? (
            <th>ATIENDE</th>
          ) : null}

          {ancho > 540 ? <th>STATUS</th> : null}

          {role !== "REPARTIDOR" && <th>ACCIONES</th>}

          {show && (
              <th>REPARTIDOR</th>
          )}
          <>
            {(isAdmin || role === "REPARTIDOR") && <th>VENTA</th>}
            <th>RESUMEN</th>
          </>
        </tr>
      </thead>

      <tbody>
        {salidaRutas.map((sr) => (
          <tr key={sr.id} onClick={() => manejarMostrarModalSalidaRuta(sr.id)}>
            {ancho > 450 ? <td>{sr.id}</td> : null}

            {ancho > 1060 || (ancho <= 900 && ancho > 660) ? (
              <td>{sr.ATIENDE}</td>
            ) : null}

            {ancho > 540 ? <td>{sr.STATUS}</td> : null}

            {role !== "REPARTIDOR" && (
              <td>
                <StyledBotonContainer>
                  <Icon
                    ref={botonRef}
                    onFocus={() => botonRef.current.blur()}
                    status={sr.STATUS}
                    id={sr.id}
                    manejarSalidaRutaDetalles={manejarSalidaRutaDetalles}
                  />
                </StyledBotonContainer>
              </td>
            )}
            {/* Aqui estan los botones restantes para acceder a las otras paginas de forma temporal
            Solo cambien la variable 'show' a true */}
            {show && (
              <>
                <td>{sr.REPARTIDOR_NOMBRE}</td>
              </>
            )}

            <>
              {(isAdmin || role === "REPARTIDOR") && (
                <td>
                  <StyledBotonContainer>
                    <StyledBoton
                      color="standard"
                      fontcolor={'var(--active-blue-button)'}
                      onClick={(e) => manejarSalidaRutaVenta(e, sr.id)}
                      ref={ventaRef}
                      disabled={
                        sr.STATUS === "CANCELADO" || sr.STATUS === "REALIZADO"
                      }
                      onFocus={() => ventaRef.current.blur()}
                    >
                      <i className="fa-solid fa-cart-shopping"></i>
                    </StyledBoton>
                  </StyledBotonContainer>
                </td>
              )}

              <td>
                <StyledBotonContainer>
                  <StyledBoton 
                    color="standard"
                    fontcolor={'var(--active-blue-button)'}
                    ref={resumenRef}
                    onClick={() => manejarSalidaRutaResumen(sr.id)}
                    onFocus={() => resumenRef.current.blur()}
                  >
                    <i className="fa-solid fa-receipt"></i>
                  </StyledBoton>
                </StyledBotonContainer>
              </td>
            </>
          </tr>
        ))}
      </tbody>
    </TableStyled>
  );
};

const Icon = ({ status, id, manejarSalidaRutaDetalles }) => {
  switch (status) {
    case "PENDIENTE":
      return (
        <StyledBoton
          color="standard"
          fontcolor="var(--active-blue-button)"
          onClick={() => manejarSalidaRutaDetalles(id)}
        >
          <i className="fa-regular fa-pen-to-square" />
        </StyledBoton>
      );
    case "PROGRESO":
      return (
        <StyledBoton
          color="standard"
          fontcolor="var(--active-blue-button)"
          onClick={() => manejarSalidaRutaDetalles(id)}
        >
          <i className="fa-solid fa-truck-ramp-box" />
        </StyledBoton>
      );

    case "CANCELADO":
      return (
        <StyledLockContainer
          color="standard"
          fontcolor="var(--active-red-button)"
          disabled={true}
        >
          <i className="fa-solid fa-rectangle-xmark" />
        </StyledLockContainer>
      );
    case "REALIZADO":
      return (
        <StyledLockContainer
          color="standard"
          fontcolor="var(--active-green-button)"
          disabled={true}
        >
          <i className="fa fa-check" />
        </StyledLockContainer>
      );
  }
};

export default TablaSalidasRuta;
