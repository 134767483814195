
import {
  REQUEST_USUARIO_LISTA,
  SUCCESS_USUARIO_LISTA,
  FAIL_USUARIO_LISTA,
} from "../constantes/usuarioConstantes";
import axiosInstance from "../lib/axiosConfig";
import axios from "axios";
import { actualizarAccessToken } from "./sesionActions";

export const pedirUsuariosLista = (cancelToken) => async (dispatch) => {
  dispatch({ type: REQUEST_USUARIO_LISTA });

  // setTimeout(async () => {
  try {

    const { data } = await axiosInstance.get(
      "api/usuarios/?role=repartidor",
      {
        cancelToken,
      }
    );

    dispatch({ type: SUCCESS_USUARIO_LISTA, payload: data });
  } catch (error) {
    if (axios.isCancel(error)) console.log(error.message);
    dispatch({ type: FAIL_USUARIO_LISTA, payload: error.message });

    // Redirect user to "/" page if error is due to expired token
    if (error.response && error.response.status === 401) {
      dispatch(actualizarAccessToken(pedirUsuariosLista, cancelToken));
    }
  }
  // }, 1500);
};
