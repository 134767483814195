import styled from 'styled-components';
import { StyledButton } from '../../componentes/general/styles/Boton.styles';

export const Main = styled.div`
  height: 88vh;
  width: 100%;
  padding: 1rem 2rem;

  display: grid;
  grid-template-columns: repeat(2,1fr);
  grid-template-rows: 0.3fr 3fr;
  grid-template-areas: 'Header Header' 
                       'Content Content';
  
  @media screen and (max-width: 768px) {
    padding: 1rem;
    overflow: hidden;
  }
`;

export const Header = styled.div`
  grid-area: Header;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-bottom: 1rem;

  h1, h3 {
    color: white;
    text-align: center;
    margin: 0;
  }

  h1 {
    font-size: 38px;
  }

  h3 {
    font-size: 22px;
  }

`;

export const Contenido = styled.div`
  grid-area: Content;
  width: 100%;
  flex: 1;
  max-height: 75vh;

  display: flex;

  @media screen and (max-width: 768px) {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 0.2fr 4fr;
    grid-template-areas: 'Selector'
                         'Board';
    max-height: 80vh;
  }
`;

export const Productos = styled.div`
  flex: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 69vh;
  overflow-y: auto;

  h3{
    color: white;
  }
`;

export const Clientes = styled.div`
  flex: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 69vh;
  overflow-y: auto;

  & h3{
    color: white;
  }
`;

export const Selector = styled.div`
  grid-area: Selector;
  width: 50%;
  min-width: 180px;
  max-width: 250px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 10px 10px 0px 0px;
  background-color: white;
`;

export const Board = styled.div`
  grid-area: Board;
  background-color: rgba(230,230,255,0.3);
  border-radius: 0px 10px 10px 10px;
  height: 100%;
  max-height: 60vh;
  overflow-y: auto;
`;

export const SelectorButton = styled.button`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(rgb(255,255,255),55%,rgb(220,220,220),80%,rgb(180,180,180));
  color: ${props => props.selected ? 'blue':'black'};
  font-weight: ${props => props.selected ? 'bolder':'normal'};
  border-radius: ${props => props.side=='left' ? '10px 0 0 0':'0 10px 0 0'};
  border: 1px solid black;
  box-shadow: ${props => props.selected ? 
  'inset 1px 2px 4px 1px rgba(0,0,0,0.6)':
  '0px 2px 8px 2px rgba(0,0,0,0.4)'
  };
  transition: 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  z-index: ${props => props.selected ? '2':'5'};
  
  &:hover {
    transform: ${props => props.selected ? '':'scale(107%)'};
    filter: ${props => props.selected ? '':'brightness(105%)'};
    box-shadow: ${props => props.selected ? 
      '':'0px 2px 8px 2px rgba(255,255,255,0.6)'
    };
    color: ${props => props.selected ? '':'rgb(0,0,220)'};
    font-weight: ${props => props.selected ? '':'bold'};
  }
`;

export const StyledBotonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

export const StyledBoton = styled(StyledButton)`
  max-width: 150px;
`;