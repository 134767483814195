import styled from "styled-components";
import { Table } from "react-bootstrap";
import { StyledButton } from "../../general/styles/Boton.styles";

export const StyledTable = styled(Table)`
  flex: 1;
  margin: 0;
  padding: 0;

  thead {
    height: 8vh;
  }

  thead,
  tbody {
    display: table;
    width: 100%;
    table-layout: fixed;
    color: white;
  }

  th,
  td {
    text-align: center;
    color: white !important;
    vertical-align: middle;

    @media screen and (max-width: 768px) {
      color: black !important;

      @media screen and (max-width: 380px) {
        font-size: 14px;

        @media screen and ((max-width: 340px)) {
          font-size: 13px;
        }
      }
    }
  }
`;

export const StyledBoton = styled(StyledButton)`
  width: 50px;
  height: 30px;
  padding: 0;
  color: ${(props) => props.fontcolor};

  i {
    font-size: 15px;
  }
`;

export const StyledLockContainer = styled(StyledButton)`
  width: 50px;
  height: 30px;
  padding: 0;
  font-size: 25px;
  justify-content: center;
  align-items: center;
  display: flex;
  color: ${(props) => props.fontcolor};
  /* left: 0.5vw; */

  &:disabled {
    color: ${(props) => props.fontcolor};
    background-color: var(--disabled-standard-color);
  }
`;
