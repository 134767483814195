import { Form } from "react-bootstrap";
import {
  StyledFormGroup,
  StyledBoton,
} from "./styles/FormularioSalidaRutaClientes.styles";
import { DAY_WEEK } from "../../lib/hooks/useSalidaRuta";
import { useRef } from "react";

const FormularioSalidaRutaClientes = ({
  manejarContinuar,
  salidaRuta,
  manejarModificarSalidaRuta,
  rutasSalidaRuta,
  clientesFormulario,
  usuarios,
  isValid,
  manejarModificarRepartidor,
  manejarModificarObservaciones,
  manejarSeleccionarCliente,
}) => {
  // Obtener referencia del boton
  const seleccionarRef = useRef(null);

  return (
    <Form onSubmit={manejarContinuar}>
      {/* Seleccionar ruta. La ruta no es obligatoria al seleccionar generar la salida ruta */}
      <StyledFormGroup controlId="ruta">
        <Form.Label>RUTA</Form.Label>
        <Form.Control
          as="select"
          value={salidaRuta.rutaNombre}
          onChange={(e) => {
            // Esta funcion recibe el nombre de la ruta y el dia de la ruta
            manejarModificarSalidaRuta(e.target.value, salidaRuta.rutaDay);
          }}
        >
          <option value="">Seleccione una ruta</option>
          {rutasSalidaRuta.map((r) => (
            <option key={r.id} value={r.NOMBRE}>
              {r.NOMBRE}
            </option>
          ))}
        </Form.Control>
      </StyledFormGroup>

      <StyledFormGroup controlId="day">
        <Form.Label>DIAS DE LA SEMANA:</Form.Label>

        <Form.Control
          as="select"
          value={salidaRuta.rutaDay}
          onChange={(e) => {
            manejarModificarSalidaRuta(salidaRuta.rutaNombre, e.target.value);
          }}
          disabled={!salidaRuta.rutaNombre}
        >
          {DAY_WEEK.map((day) => (
            <option value={day} key={day}>
              {day}
            </option>
          ))}
        </Form.Control>
      </StyledFormGroup>

      <StyledFormGroup controlId="clientesDisponibles">
        <Form.Label>CLIENTES DISPONIBLES</Form.Label>
        <Form.Control
          as="select"
          // defaultValue={0}
          onChange={(e) => manejarSeleccionarCliente(Number(e.target.value))}
        >
          <option value={0}>Selecciona un cliente</option>
          {clientesFormulario.map((c) => (
            <option key={c.id} value={c.id}>
              {c.NOMBRE}
            </option>
          ))}
        </Form.Control>
      </StyledFormGroup>

      <StyledFormGroup controlId="repartidor">
        <Form.Label>REPARTIDOR</Form.Label>
        <Form.Control
          as="select"
          // defaultValue={0}
          value={salidaRuta.repartidorId}
          onChange={(e) => {
            manejarModificarRepartidor(Number(e.target.value));
          }}
        >
          <option value={0}>Selecciona un repartidor</option>
          {usuarios.map((usuario) => (
            <option key={usuario.empleado_id} value={usuario.empleado_id}>
              {usuario.name}
            </option>
          ))}
        </Form.Control>
      </StyledFormGroup>

      <StyledFormGroup controlId="observaciones">
        <Form.Label>OBSERVACIONES</Form.Label>
        <Form.Control
          type="text"
          value={salidaRuta.observaciones}
          onChange={(e) => manejarModificarObservaciones(e.target.value)}
        ></Form.Control>
      </StyledFormGroup>

      <StyledFormGroup>
        <StyledBoton
          type="submit"
          color="green"
          ref={seleccionarRef}
          onFocus={() => seleccionarRef.current.blur()}
          disabled={!isValid}
        >
          Seleccionar productos
        </StyledBoton>
      </StyledFormGroup>
    </Form>
  );
};
export default FormularioSalidaRutaClientes;
