import React from "react";

import { useRef } from 'react';
import { useMediaQuery } from "react-responsive";
import { TableStyled, StyledBoton } from "./styles/TablaRutaDias.style";

const TablaRutas = ({
  rutas,
  manejarMostrarDetallesRuta,
  manejarRutaDetalles,
  // manejarResetRuta,
}) => {
  // Determinar si el ancho de la pantalla es small o menor
  const isSmallViewport = useMediaQuery({ maxWidth: 768 });
  const shouldShow = !isSmallViewport;

  // Obtener referencia del boton
  const editRef = useRef(null);

  // Renderizar tabla de rutas
  return (
    <TableStyled striped hover>
      <thead>
        <tr>
          {shouldShow ? (
            <>
              <th>ID</th>
              <th>REPARTIDOR</th>
              <th>DIA</th>
            </>
          ) : (
            <>
              <th>NOMBRE</th>
            </>
          )}

          <th>EDITAR</th>
        </tr>
      </thead>

      <tbody>
        {rutas.map((r) => (
          <tr key={r.id} onClick={() => manejarMostrarDetallesRuta(r.id)}>
            {shouldShow ? (
              <>
                <td>{r.id}</td>
                <td>{r.REPARTIDOR_NOMBRE}</td>
                <td>{r.DIA}</td>
              </>
            ) : (
              <>
                <td>{r.NOMBRE}</td>
              </>
            )}
            <td>
              <StyledBoton 
                color='standard'
                ref={editRef}
                onFocus={() => editRef.current.blur()}
                onClick={() => manejarRutaDetalles(r.id)}
              >
                <i className="fa-solid fa-pen-to-square"/>
              </StyledBoton>
            </td>
          </tr>
        ))}
      </tbody>
    </TableStyled>
  );
};

export default TablaRutas;
