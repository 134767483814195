import {
  FAIL_PRODUCTO_LISTA,
  REQUEST_PRODUCTO_LISTA,
  SUCCESS_PRODUCTO_LISTA,
} from "../constantes/productoConstantes";
import axiosInstance from "../lib/axiosConfig";
import axios from "axios"
import { actualizarAccessToken } from "./sesionActions";

// Creador de acciones para pedir los productos del backend
export const pedirProductosLista = (cancelToken) => async (dispatch) => {
  dispatch({ type: REQUEST_PRODUCTO_LISTA });

  // setTimeout(async () => {
  // Intentar pedir lista de productos al backend
  try {

    // Recibir respuesta del backend y guardarla en data
    const { data } = await axiosInstance.get("api/productos",
      {
        cancelToken,
      }
    );

    dispatch({ type: SUCCESS_PRODUCTO_LISTA, payload: data });
  } catch (error) {
    if (axios.isCancel(error)) console.log(error.message);
    // Si el backend responde con error de tipo 401 (no autorizado) intentar actualizar el token
    if (error.response && error.response.status === 401) {
      dispatch(actualizarAccessToken(pedirProductosLista, cancelToken));
    } else {
      dispatch({ type: FAIL_PRODUCTO_LISTA, payload: error.message });
    }
  }
  // }, 1500);
};
