import { Modal } from 'react-bootstrap';
import styled from 'styled-components';

// Estilos de la ventana emergente
export const StyledModal = styled(Modal)`

  padding: 10px;
`;

// Estilos del header de la ventana emergente
export const StyledModalHeader = styled(Modal.Header)`
  background-color: white;
  border-bottom: 4px solid #d9d9d9;

  & h4 {
    color: black;
    font-weight: bold;
  }
`;

// Estilos del body de la ventana emergente
export const StyledModalBody = styled(Modal.Body)`
  background-color: white;
  color: black;

  & h5 {
    color: black;
    font-weight: bold;
  }
`;

// Estilos del footer de la ventana emergente
export const StyledModalFooter = styled(Modal.Footer)`
  background-color: white;
  border-top: 4px solid #d9d9d9;
`;