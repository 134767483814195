import { Modal } from "react-bootstrap";
import styled from "styled-components";
import { StyledButton } from "../../general/styles/Boton.styles";

// Estilos de la ventana emergente
export const StyledModal = styled(Modal)``;

// Estilos del header de la ventana emergente
export const StyledModalHeader = styled(Modal.Header)`
  background-color: white;
  border-bottom: 4px solid #d9d9d9;
`;

export const StyledModalTitle = styled(Modal.Title)`
  color: var(--font-color-input);
  font-weight: bold;
`;

// Estilos del body de la ventana emergente
export const StyledModalBody = styled(Modal.Body)`
  background-color: white;
  color: black;

  max-height: 400px;
  overflow: auto;

  // Modificación de la scrollbar para Chrome y Edge
  &::-webkit-scrollbar{
    width: 5px;
    background-color: none;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb{
    background-color: rgba(150,150,150);
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb:hover{
    background-color: rgba(100,100,100);
    border-radius: 5px;
  }


  & h5 {
    color: black;
    font-weight: bold;
  }

  @media (max-width: 768px) and (orientation: portrait) {
    padding: 0px 10px;
    max-height: 300px;
  }
`;

// Estilos del boton
export const StyledBoton = styled(StyledButton)`
  color: var(--white-color);
  font-weight: var(--font-weight-label);
`;

export const StyledBotonPrint = styled(StyledBoton)`
  position: fixed;
  bottom: 12px;
  left: 5px;
`;

// Estilos del footer de la ventana emergente
export const StyledModalFooter = styled(Modal.Footer)`
  background-color: white;
  border-top: 4px solid #d9d9d9;
`;
