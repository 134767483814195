import { useEffect, useState } from "react";
import { crearClientesVenta } from "../utilis/ventaSalidaRuta";

export const useClienteVentaSalidaRuta = (salidaRuta) => {
  const [clientesDisponibles, setClientesDisponibles] = useState([]);
  const [cliente, setCliente] = useState({});

  const [productosCliente, setProductosCliente] = useState([]);

  // useEffect para inicializar los clientes, el cliente seleccionado y su lista de precios
  useEffect(() => {
    // Si no hay salidaRuta no hagas nada

    if (!salidaRuta || salidaRuta.STATUS === "CANCELADO") return;

    // Obtener nuevos clientes con sus precios modificados para que incluyan solo los precios de los productos con status de cargado y ademas modifica los precios agragando los campos id, cantidad_producto y STATUS
    const nuevosClientes = crearClientesVenta(salidaRuta);

    setClientesDisponibles(nuevosClientes);
    // Establecer el cliente seleccionado como el primer cliente de la lista de nuevosClientes
    setCliente(nuevosClientes[0]);
    // Establecer el valor de la lista de precios del cliente seleccionado
    setProductosCliente(nuevosClientes[0].precios_cliente);
  }, [salidaRuta]);

  const manejarCambiarCliente = (clienteId) => {
    const clienteSeleccionado = {
      ...clientesDisponibles.find((c) => c.id === clienteId),
    };

    setCliente(clienteSeleccionado);

    setProductosCliente(clienteSeleccionado.precios_cliente);
  };

  const manejarCambiarProductosCliente = (nuevosProductosCliente) => {
    setProductosCliente(nuevosProductosCliente);
  };

  return {
    cliente,
    clientesDisponibles,
    productosCliente,
    manejarCambiarProductosCliente,
    manejarCambiarCliente,
  };
};
