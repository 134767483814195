import React from "react";

import { useMediaQuery } from "react-responsive";
import {
  StyledButtonContainer,
  StyledDeleteButton,
  StyledGenericButton,
  TableStyled,
  StyledTd,
} from "./styles/TablaRutas.styles";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";
import useScreenSize from "../general/UseScreenSize";

const TablaRutas = ({
  rutas,
  manejarMostrarDetallesRuta,
  manejarRutaDetalles,
  manejarBorrarRuta,
}) => {
  // Determinar si el ancho de la pantalla es small o menor
  const isSmallViewport = useMediaQuery({ maxWidth: 768 });
  const shouldShow = !isSmallViewport;
  const { ancho, alto } = useScreenSize();

  // Obtener referencias de los botones
  const editRef = useRef(null);
  const detailRef = useRef(null);
  const delRef = useRef(null);

  const navigate = useNavigate();

  // Verificar si es administrador para renderizar algunas propiedades
  const isAdmin = JSON.parse(localStorage.getItem("isAdmin"));

  const manejarRutaDias = (rutaId) => {
    navigate(`/rutas/${rutaId}/dias/`);
  };

  // Renderizar tabla de rutas
  return (
    <TableStyled striped hover>
      <thead>
        <tr>
          {shouldShow ? (
            <>
              <th>ID</th>
              <th>NOMBRE</th>
              <th>REPARTIDOR</th>
            </>
          ) : (
            <>
              <th>NOMBRE</th>
            </>
          )}

          {ancho >= 400 ? (
            <>
              <th>EDITAR</th>
              <th>DETALLES</th>
            </>
          ) : (
            <th>ADMINISTRAR</th>
          )}

          {isAdmin && ancho >= 400 && <th>BORRAR</th>}
        </tr>
      </thead>

      <tbody>
        {rutas.map((r) => (
          <tr key={r.id} onClick={() => manejarMostrarDetallesRuta(r.id)}>
            {shouldShow ? (
              <>
                <td>{r.id}</td>
                <StyledTd ancho={ancho}>{r.NOMBRE}</StyledTd>
                <td>{r.REPARTIDOR_NOMBRE}</td>
              </>
            ) : (
              <>
                <td>{r.NOMBRE}</td>
              </>
            )}

            {ancho >= 400 ? (
              <td>
                <StyledGenericButton
                  color="standard"
                  ref={editRef}
                  onFocus={() => editRef.current.blur()}
                  onClick={() => manejarRutaDetalles(r.id)}
                >
                  <i className="fa-solid fa-pen-to-square"></i>
                </StyledGenericButton>
              </td>
            ) : null}

            <td>
              <StyledButtonContainer>
                {ancho < 400 ? (
                  <StyledGenericButton
                    color="standard"
                    ref={editRef}
                    onFocus={() => editRef.current.blur()}
                    onClick={() => manejarRutaDetalles(r.id)}
                  >
                    <i className="fa-solid fa-pen-to-square"></i>
                  </StyledGenericButton>
                ) : null}

                <StyledGenericButton
                  color="standard"
                  ref={detailRef}
                  onFocus={() => detailRef.current.blur()}
                  onClick={() => manejarRutaDias(r.id)}
                >
                  <i className="fa-solid fa-circle-info"></i>
                </StyledGenericButton>

                {isAdmin && ancho < 400 && (
                  <StyledDeleteButton
                    ref={delRef}
                    color="red"
                    onFocus={() => delRef.current.blur()}
                    variant="danger"
                    onClick={(e) => manejarBorrarRuta(e, r.id)}
                  >
                    <i className="fa-solid fa-trash"></i>
                  </StyledDeleteButton>
                )}
              </StyledButtonContainer>
            </td>
            {isAdmin && ancho >= 400 && (
              <td>
                <StyledDeleteButton
                  ref={delRef}
                  color="red"
                  onFocus={() => delRef.current.blur()}
                  variant="danger"
                  onClick={(e) => manejarBorrarRuta(e, r.id)}
                >
                  <i className="fa-solid fa-trash"></i>
                </StyledDeleteButton>
              </td>
            )}
          </tr>
        ))}
      </tbody>
    </TableStyled>
  );
};

export default TablaRutas;
