import {
  FAIL_DEVOLUCION_ACTUALIZAR,
  FAIL_DEVOLUCION_DETALLES,
  FAIL_DEVOLUCION_LISTA,
  REQUEST_DEVOLUCION_ACTUALIZAR,
  REQUEST_DEVOLUCION_DETALLES,
  REQUEST_DEVOLUCION_LISTA,
  RESET_DEVOLUCION_ACTUALIZAR,
  RESET_DEVOLUCION_DETALLES,
  RESET_DEVOLUCION_LISTA,
  SUCCESS_DEVOLUCION_ACTUALIZAR,
  SUCCESS_DEVOLUCION_DETALLES,
  SUCCESS_DEVOLUCION_LISTA,
} from "../constantes/devolucionConstantes";

export const devolucionListaReducer = (state = {}, action) => {
  const { type, payload } = action;

  switch (type) {
    case REQUEST_DEVOLUCION_LISTA:
      return { loading: true };

    case SUCCESS_DEVOLUCION_LISTA:
      return {
        loading: false,
        devoluciones: payload.devoluciones,
        page: payload.page,
        pages: payload.pages,
      };

    case FAIL_DEVOLUCION_LISTA:
      return { loading: false, error: payload };

    case RESET_DEVOLUCION_LISTA:
      return {};

    default:
      return state;
  }
};

export const devolucionDetallesReducer = (state = {}, action) => {
  const { type, payload } = action;

  switch (type) {
    case REQUEST_DEVOLUCION_DETALLES:
      return { loading: true };

    case SUCCESS_DEVOLUCION_DETALLES:
      return {
        loading: false,
        devolucion: payload,
      };

    case FAIL_DEVOLUCION_DETALLES:
      return { loading: false, error: payload };

    case RESET_DEVOLUCION_DETALLES:
      return {};

    default:
      return state;
  }
};

export const devolucionActualizarReducer = (state = {}, action) => {
  const { type, payload } = action;

  switch (type) {
    case REQUEST_DEVOLUCION_ACTUALIZAR:
      return { loading: true };

    case SUCCESS_DEVOLUCION_ACTUALIZAR:
      return {
        loading: false,
        success: true,
      };

    case FAIL_DEVOLUCION_ACTUALIZAR:
      return { loading: false, error: payload };

    case RESET_DEVOLUCION_ACTUALIZAR:
      return {};

    default:
      return state;
  }
};
