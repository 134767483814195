import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components'

// Estilos del contenedor principal
export const StyledContainer = styled(Container)`
    position: relative;
    height: 88vh;
    padding: 2rem 0;

    h1 {
        text-align: center;
        color: var(--white-color);
        margin-bottom: 2rem;
    }
`;

// Estilos de las filas
export const StyledRow = styled(Row)`
    width: 100vw;
    display: flex;
    align-items: center;
    --bs-gutter-x: 0;
`;

// Estilos de las columnas
export const StyledCol = styled(Col)`
    display: flex;
    justify-content: center;
`;
 