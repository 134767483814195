import { Table, Button } from "react-bootstrap";
import styled from "styled-components";
import { StyledButton } from "../../general/styles/Boton.styles";

// Estilos de la tabla
export const TableStyled = styled(Table)`
  height: 100%;
  margin: 0;
  padding: 0;
  
  tbody {
    height: 62vh;
    /* width: 100vw; */
    display: block;
    overflow: auto;

    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
    color: white;
  }

  th,
  td {
    text-align: center;
    vertical-align: middle;
    color: white !important;
  }
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: center;

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    @media screen and (max-width: 450px) {
      margin: 0px 4px 0px 5px;
      left: 100px;
    }
  }
`;

export const StyledDeleteButton = styled(StyledButton)`
  i {
    font-size: 20px;
  }

  @media screen and (max-width: 450px) {
    width: 40px;
    height: 40px;
    padding: 5px;

    i {
      font-size: 17px;
    }
  }
`;

export const StyledGenericButton = styled(StyledButton)`
  color: var(--active-blue-button);
  font-weight: bold;

  i {
    font-size: 20px;
  }

  @media screen and (max-width: 450px) {
    width: 40px;
    height: 40px;
    padding: 5px;

    i {
      font-size: 17px;
    }
  }
`;

export const StyledTd = styled.td`
  font-size: ${(props) => (props.ancho < 451 ? "9px" : "")};
`;

export const StyledBoton = styled(StyledButton)`
  width: 35px;
  height: 30px;
  padding: 0;
  font-size: 20px;
  left: 0.5vw;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.fontcolor};
`;

export const StyledLockContainer = styled(StyledButton)`
  padding: 0;
  height: 35px;
  width: 35px;
  font-size: 25px;
  justify-content: center;
  align-items: center;
  display: flex;
  color: ${(props) => props.fontcolor};
  left: 0.5vw;

  &:disabled {
    color: ${(props) => props.fontcolor};
    background-color: var(--disabled-standard-color);
  }
`;
