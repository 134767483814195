import { BrowserRouter, Routes, Route, HashRouter} from "react-router-dom";
import { Toaster } from "react-hot-toast";

import GlobalStyles from "./GlobalStyles";
import { Error404 } from "./paginas/Error404";
// Importar las paginas
import Encabezado from "./componentes/general/Encabezado";
import {
  InicioSesion,
  Home,
  CuentaDetalles,
  RegistrarRuta,
  RutasLista,
  RutaDetalles,
  RutaDiasLista,
  RutaDiasDetalles,
  SalidaRutasLista,
  SalidaRutaDetalles,
  RealizarSalidaRutaProductos,
  RealizarSalidaRutaClientes,
  DevolucionesLista,
  DevolucionDetalles,
  ResumenSalidaRuta,
  RealizarVentaSalidaRuta,
} from "./paginas";
import RequireAuth from "./componentes/general/RequireAuth";

function App() {
  const role = JSON.parse(localStorage.getItem("role"));
  return (
    <div className="App">
      <GlobalStyles />
      <HashRouter>
        <Encabezado />
        <Routes>
          {/* Home */}

          <Route
            path="/"
            element={
              <RequireAuth>
                <Home />
              </RequireAuth>
            }
          />
          {/*Detalles de Cuenta*/}

          <Route
            path="/cuenta"
            element={
              <RequireAuth>
                <CuentaDetalles />
              </RequireAuth>
            }
          />
          {role !== "REPARTIDOR" && (
            <>
              {/* Rutas */}

              <Route
                path="/rutas"
                element={
                  <RequireAuth>
                    <RutasLista />
                  </RequireAuth>
                }
              />

              <Route
                path="/rutas/:id"
                element={
                  <RequireAuth>
                    <RutaDetalles />
                  </RequireAuth>
                }
              />

              <Route
                path="/rutas/:id/dias"
                element={
                  <RequireAuth>
                    <RutaDiasLista />
                  </RequireAuth>
                }
              />

              <Route
                path="/rutas-dias/:id"
                element={
                  <RequireAuth>
                    <RutaDiasDetalles />
                  </RequireAuth>
                }
              />

              <Route
                path="/registrar-ruta"
                element={
                  <RequireAuth>
                    <RegistrarRuta />
                  </RequireAuth>
                }
              />
              {/* Registrar salida a ruta */}

              <Route
                path="/realizar-salida-ruta-clientes"
                element={
                  <RequireAuth>
                    <RealizarSalidaRutaClientes />
                  </RequireAuth>
                }
              />

              <Route
                path="/realizar-salida-ruta-productos"
                element={
                  <RequireAuth>
                    <RealizarSalidaRutaProductos />
                  </RequireAuth>
                }
              />

              {/* Devoluciones */}

              <Route
                path="/devoluciones"
                element={
                  <RequireAuth>
                    <DevolucionesLista />
                  </RequireAuth>
                }
              />

              <Route
                path="/devoluciones/:id"
                element={
                  <RequireAuth>
                    <DevolucionDetalles />
                  </RequireAuth>
                }
              />
            </>
          )}

          {/* Salida rutas */}
          <Route
            path="/salida-rutas"
            element={
              <RequireAuth>
                <SalidaRutasLista />
              </RequireAuth>
            }
          />

          <Route
            path="/salida-rutas/:id"
            element={
              <RequireAuth>
                <SalidaRutaDetalles />
              </RequireAuth>
            }
          />

          {/* Venta salida ruta */}

          <Route
            path="/venta-salida-ruta/:id"
            element={
              <RequireAuth>
                <RealizarVentaSalidaRuta />
              </RequireAuth>
            }
          />
          {/* Resumen Salida ruta */}

          <Route
            path="/resumen-salida-ruta/:id"
            element={
              <RequireAuth>
                <ResumenSalidaRuta />
              </RequireAuth>
            }
          />
          {/* Inicio sesion*/}
          <Route path="/login" element={<InicioSesion />} />
          {/* Error 404 */}

          <Route
            path="*"
            element={
              <RequireAuth>
                <Error404 />
              </RequireAuth>
            }
          />
        </Routes>
        <Toaster />
      </HashRouter>
    </div>
  );
}

export default App;
