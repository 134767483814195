import React from "react";
// Estilos del componente
import {
  StyledModal,
  StyledModalBody,
  StyledModalHeader,
  StyledModalFooter,
  StyledBoton,
} from "./styles/VentanaMostrarDevolucion.styles";
import { useRef } from "react";
import { formatearFecha } from "../../utilitis";

const VentanaMostrarDevolucion = ({
  devolucion,
  mostrarDevolucion,
  manejarCerrarVentana,
}) => {
  // Obtener referencia del boton
  const cerrarRef = useRef(null);

  return (
    devolucion && (
      <StyledModal
        centered
        show={mostrarDevolucion}
        onHide={manejarCerrarVentana}
      >
        <StyledModalHeader>
          <h4>Detalles de la devolucion #{devolucion.id}</h4>
        </StyledModalHeader>

        <StyledModalBody>
          <h5>Datos de la devolucion</h5>

          <p>
            <strong>REPARTIDOR:</strong> {devolucion.REPARTIDOR}
          </p>
          <p>
            <strong>ATIENDE:</strong> {devolucion.ATIENDE}
          </p>
          <p>
            <strong>FECHA:</strong> {formatearFecha(devolucion.FECHA)}
          </p>
          <p>
            <strong>ADMINISTRADOR:</strong> {devolucion.ADMINISTRADOR}
          </p>
          <p>
            <strong>PRODUCTO:</strong> {devolucion.PRODUCTO_NOMBRE}
          </p>
          <p>
            <strong>CANTIDAD:</strong> {devolucion.CANTIDAD_DEVOLUCION}
          </p>
          <p>
            <strong>OBSERVACIONES:</strong> {devolucion.OBSERVACIONES}
          </p>
        </StyledModalBody>

        <StyledModalFooter>
          <StyledBoton
            color="blue"
            ref={cerrarRef}
            onFocus={() => cerrarRef.current.blur()}
            onClick={() => {
              manejarCerrarVentana();
            }}
          >
            Cerrar
          </StyledBoton>
        </StyledModalFooter>
      </StyledModal>
    )
  );
};

export default VentanaMostrarDevolucion;
