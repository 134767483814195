import {
  FAIL_RUTA_ACTUALIZAR,
  FAIL_RUTA_BORRAR,
  FAIL_RUTA_DETALLES,
  FAIL_RUTA_DIA_ACTUALIZAR,
  FAIL_RUTA_DIA_CLIENTES,
  FAIL_RUTA_DIA_DETALLES,
  FAIL_RUTA_DIA_LISTA,
  FAIL_RUTA_LISTA,
  FAIL_RUTA_REGISTRAR,
  FAIL_RUTA_SALIDA_RUTA_LISTA,
  REQUEST_RUTA_ACTUALIZAR,
  REQUEST_RUTA_BORRAR,
  REQUEST_RUTA_DETALLES,
  REQUEST_RUTA_DIA_ACTUALIZAR,
  REQUEST_RUTA_DIA_CLIENTES,
  REQUEST_RUTA_DIA_DETALLES,
  REQUEST_RUTA_DIA_LISTA,
  REQUEST_RUTA_LISTA,
  REQUEST_RUTA_REGISTRAR,
  REQUEST_RUTA_SALIDA_RUTA_LISTA,
  RESET_RUTA_DIA_LISTA,
  RESET_RUTA_LISTA,
  RESET_RUTA_SALIDA_RUTA_LISTA,
  SUCCESS_RUTA_ACTUALIZAR,
  SUCCESS_RUTA_BORRAR,
  SUCCESS_RUTA_DETALLES,
  SUCCESS_RUTA_DIA_ACTUALIZAR,
  SUCCESS_RUTA_DIA_CLIENTES,
  SUCCESS_RUTA_DIA_DETALLES,
  SUCCESS_RUTA_DIA_LISTA,
  SUCCESS_RUTA_LISTA,
  SUCCESS_RUTA_REGISTRAR,
  SUCCESS_RUTA_SALIDA_RUTA_LISTA,
} from "../constantes/rutaConstantes";

import { actualizarAccessToken } from "./sesionActions";
import axiosInstance from "../lib/axiosConfig";
import axios from "axios"

// Recuerda anadir la posibilidad de remover todos los clientes asociados con una ruta o un conjunto de rutas (este ultimo caso seria aplicable para eliminar los clientes en los siete dias de la semana de una ruta)

// Creador de acciones para pedir las rutas del backend
export const pedirRutasLista = (cancelToken) => async (dispatch) => {
  dispatch({ type: REQUEST_RUTA_LISTA });

  // setTimeout(async () => {
  try {

    const { data } = await axiosInstance.get("api/rutas/", {
      cancelToken,
    });

    dispatch({ type: SUCCESS_RUTA_LISTA, payload: data });
  } catch (error) {
    if (axios.isCancel(error)) console.log(error.message);
    // Si el backend responde con un error 401 (no autorizado) intentar actualizar el token
    if (error.response && error.response.status === 401) {
      dispatch(actualizarAccessToken(pedirRutasLista, cancelToken));
    } else {
      dispatch({ type: FAIL_RUTA_LISTA, payload: error.message });
    }
  }
  // }, 1500);
};

// Creador de acciones para pedir la ruta con el id del backend
export const obtenerRutaDetalles = (id) => async (dispatch) => {
  dispatch({ type: REQUEST_RUTA_DETALLES });

  try {

    const { data } = await axiosInstance.get(`api/rutas/${id}/`);

    dispatch({ type: SUCCESS_RUTA_DETALLES, payload: data });
  } catch (error) {
    // Si el backend responde con un error 401 (no autorizado) intentar actualizar el token
    if (error.response && error.response.status === 401) {
      dispatch(actualizarAccessToken(obtenerRutaDetalles, id));
    } else {
      dispatch({ type: FAIL_RUTA_DETALLES, payload: error.message });
    }
  }
};

// Creador de acciones para actualizar ruta del backend
export const actualizarRuta = (ruta) => async (dispatch) => {
  dispatch({ type: REQUEST_RUTA_ACTUALIZAR });

  try {

    const { data } = await axiosInstance.put(
      `api/modificar-ruta/${ruta.id}/`,
      ruta
    );

    dispatch({ type: SUCCESS_RUTA_ACTUALIZAR });
    dispatch({ type: RESET_RUTA_LISTA });
    dispatch({ type: RESET_RUTA_DIA_LISTA });


  } catch (error) {
    // Si el backend responde con un error 401 (no autorizado) intentar actualizar el token
    if (error.response && error.response.status === 401) {
      dispatch(actualizarAccessToken(actualizarRuta, ruta));
    } else {
      dispatch({ type: FAIL_RUTA_ACTUALIZAR, payload: error.message });
    }
  }
};

// Creador de acciones para registrar un nuevo ruta en el backend
export const registrarRuta = (ruta) => async (dispatch) => {
  dispatch({ type: REQUEST_RUTA_REGISTRAR });

  try {

    const { data } = await axiosInstance.post(
      "api/crear-ruta/",
      ruta
    );

    dispatch({ type: SUCCESS_RUTA_REGISTRAR });
    dispatch({ type: RESET_RUTA_LISTA });
    // Tambien necesito borrar del store las rutas que aparecen en registrar salida ruta
    dispatch({ type: RESET_RUTA_SALIDA_RUTA_LISTA });


  } catch (error) {
    // Si el backend responde con un error 401 (no autorizado) intentar actualizar el token
    if (error.response && error.response.status === 401) {
      dispatch(actualizarAccessToken(registrarRuta, ruta));
    } else {
      dispatch({ type: FAIL_RUTA_REGISTRAR, payload: error.message });
    }
  }
};

// Creador de acciones para borrar un ruta en el backend
export const borrarRuta = (id) => async (dispatch) => {
  dispatch({ type: REQUEST_RUTA_BORRAR });

  try {

    const { data } = await axiosInstance.delete(`/api/modificar-ruta/${id}/`);


    dispatch({ type: SUCCESS_RUTA_BORRAR });
    dispatch({ type: RESET_RUTA_LISTA });
    // Tambien necesito borrar del store las rutas que aparecen en registrar salida ruta
    dispatch({ type: RESET_RUTA_SALIDA_RUTA_LISTA });

  } catch (error) {
    // Si el backend responde con un error 401 (no autorizado) intentar actualizar el token
    if (error.response && error.response.status === 401) {
      dispatch(actualizarAccessToken(borrarRuta, id));
    } else {
      dispatch({ type: FAIL_RUTA_BORRAR, payload: error.message });
    }
  }
};

export const pedirRutaDiasLista = (rutaId) => async (dispatch) => {
  dispatch({ type: REQUEST_RUTA_DIA_LISTA });

  try {

    const { data } = await axiosInstance.get(
      `api/rutas/${rutaId}/dias/`
    );

    dispatch({ type: SUCCESS_RUTA_DIA_LISTA, payload: data });
  } catch (error) {
    // Si el backend responde con un error 401 (no autorizado) intentar actualizar el token
    if (error.response && error.response.status === 401) {
      dispatch(actualizarAccessToken(pedirRutaDiasLista));
    } else {
      dispatch({ type: FAIL_RUTA_DIA_LISTA, payload: error.message });
    }
  }
};

// Creador de acciones para pedir la ruta dia con el id del backend
export const obtenerRutaDiaDetalles =
  (rutaId) => async (dispatch) => {
    dispatch({ type: REQUEST_RUTA_DIA_DETALLES });

    try {

      const { data } = await axiosInstance.get(
        `api/ruta-dias/${rutaId}`,
      );

      dispatch({ type: SUCCESS_RUTA_DIA_DETALLES, payload: data });
    } catch (error) {
      // Si el backend responde con un error 401 (no autorizado) intentar actualizar el token
      if (error.response && error.response.status === 401) {
        dispatch(actualizarAccessToken(obtenerRutaDiaDetalles, rutaId));
      } else {
        dispatch({ type: FAIL_RUTA_DIA_DETALLES, payload: error.message });
      }
    }
  };

export const obtenerClientesRutaDia =
  (rutaDiaId) => async (dispatch) => {
    dispatch({ type: REQUEST_RUTA_DIA_CLIENTES });

    try {

      const { data } = await axiosInstance.get(
        `api/ruta-dias/${rutaDiaId}/clientes/`,
      );


      dispatch({
        type: SUCCESS_RUTA_DIA_CLIENTES,
        payload: data,
      });
    } catch (error) {
      // Si el backend responde con un error 401 (no autorizado) intentar actualizar el token
      if (error.response && error.response.status === 401) {
        dispatch(actualizarAccessToken(obtenerClientesRutaDia, rutaDiaId));
      } else {
        dispatch({ type: FAIL_RUTA_DIA_CLIENTES, payload: error.message });
      }
    }
  };

export const actualizarRutaDia = (ruta) => async (dispatch) => {
  dispatch({ type: REQUEST_RUTA_DIA_ACTUALIZAR });

  try {

    const { data } = await axiosInstance.put(
      `api/modificar-ruta-dia/${ruta.id}/`,
      ruta
    );

    dispatch({ type: SUCCESS_RUTA_DIA_ACTUALIZAR });
    dispatch({ type: RESET_RUTA_DIA_LISTA });
    dispatch({ type: RESET_RUTA_LISTA });
    // Tambien necesito borrar del store las rutas que aparecen en registrar salida ruta
    dispatch({ type: RESET_RUTA_SALIDA_RUTA_LISTA });
    // Debo volver a pedir la lista de clientes del bakcend
    // dispatch({ type: RESET_CLIENTE_LISTA });

  } catch (error) {
    // Si el backend responde con un error 401 (no autorizado) intentar actualizar el token
    if (error.response && error.response.status === 401) {
      dispatch(actualizarAccessToken(actualizarRutaDia, ruta));
    } else {
      dispatch({ type: FAIL_RUTA_DIA_ACTUALIZAR, payload: error.message });
    }
  }
};

export const pedirRutasSalidaRutaLista = () => async (dispatch) => {
  dispatch({ type: REQUEST_RUTA_SALIDA_RUTA_LISTA });

  try {

    const { data } = await axiosInstance.get(
      "api/rutas-salida-ruta/",
    );

    dispatch({ type: SUCCESS_RUTA_SALIDA_RUTA_LISTA, payload: data });
  } catch (error) {
    // Si el backend responde con un error 401 (no autorizado) intentar actualizar el token
    if (error.response && error.response.status === 401) {
      dispatch(actualizarAccessToken(pedirRutasSalidaRutaLista));
    } else {
      dispatch({ type: FAIL_RUTA_SALIDA_RUTA_LISTA, payload: error.message });
    }
  }
};
