import { Table } from 'react-bootstrap';
import styled from 'styled-components';
import { StyledButton } from '../../general/styles/Boton.styles';

// Estilos de la tabla
export const TableStyled = styled(Table)`
  height: 100%;

  tbody {
    height: 58vh;
    display: block;
    overflow: auto;
  }

  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
    color: white;
  }

  th,
  td {
    text-align: center;
    vertical-align: middle;
    color: white !important;
  }

  // Modificación de la scrollbar para Chrome y Edge
  ::-webkit-scrollbar{
    width: 7px;
    background-color: rgb(105,133,169);
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb{
    background-color: rgba(230,230,230);
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb:hover{
    background-color: rgba(150,150,150);
    border-radius: 5px;
  }
`;

export const StyledBoton = styled(StyledButton)`
  color: var(--active-blue-button);
  font-weight: bold;

  i{
    font-size: 20px;
  }
`;