import {
  FAIL_LOGIN_USUARIO,
  LOGOUT_USUARIO,
  REQUEST_LOGIN_USUARIO,
  SUCCESS_LOGIN_USUARIO,
} from "../constantes/sesionConstantes";
import { toast } from "react-hot-toast";
import { setToken } from "../lib/tokenManager";
import axiosInstance from "../lib/axiosConfig";

// Estos tres hay que separarlos (ESTOS SON LOS UNICOS QUE NO ENVIAN EL ACCESS TOKEN AL SERVIDOR)
export const login = (username, password) => async (dispatch) => {
  dispatch({ type: REQUEST_LOGIN_USUARIO });

  try {
    // En point de inicio de sesion. Este endpoint esta configurado para regresar no solo el access y refresh token, sino tambien informacion del usuario logeado. Por ejempl, sus perminos, imagen y nombre
    const { data } = await axiosInstance.post("api/token/", {
      username,
      password,
    });
    dispatch({ type: SUCCESS_LOGIN_USUARIO, payload: data.access });

    // Why is all information necessary in the localStorage. Because if you reload the page or close the page only the info in the local storage is recovered. This info is used in several pages of the app it is important to have access to it always. Futher more, this information is not supposed to change in a session, so it doesn't make sense to store it as a state (because state changes)
    localStorage.setItem("accessToken", JSON.stringify(data.access));
    // set token to axios interceptor
    setToken(data.access);
    localStorage.setItem("refreshToken", JSON.stringify(data.refresh));
    // Informacion del usuario
    localStorage.setItem("usuarioId", JSON.stringify(data.id));
    localStorage.setItem("isAdmin", JSON.stringify(data.is_admin));
    localStorage.setItem("name", JSON.stringify(data.name));
    localStorage.setItem("username", JSON.stringify(data.username));
    localStorage.setItem("imagen", JSON.stringify(data.empleado.IMAGEN));
    localStorage.setItem("role", JSON.stringify(data.role));
  } catch (error) {
    dispatch({ type: FAIL_LOGIN_USUARIO, payload: error.message });

    if (error.response && error.response.status === 401) {
      dispatch({ type: FAIL_LOGIN_USUARIO, payload: "Credenciales invalidas" });
    } else {
      dispatch({ type: FAIL_LOGIN_USUARIO, payload: error.message });
    }
  }
};

export const logout = () => {
  return (dispatch) => {
    // Puedes borrar todo el contenido del localStorage usando localStorage.clear(). Este método eliminará todos los pares clave-valor almacenados en el objeto localStorage para ese dominio.
    localStorage.clear();

    dispatch({ type: LOGOUT_USUARIO });

    window.location.href = "#/login";
  };
};

// ESTE ES EL UNICO REQUEST QUE ENVIA EL REFRESH TOKEN AL SERVIDOR
export const actualizarAccessToken =
  (action, ...parametrosOpcionales) =>
    async (dispatch) => {
      try {
        // Obtener refresh token del localStorage
        const refreshToken = JSON.parse(localStorage.getItem("refreshToken"));

        // Crear header con tipo de datos a enviar
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };

        // Endpoint de actualizacion de access token. Recibir respuesta del backend
        const { data } = await axiosInstance.post(
          "api/token/refresh/",
          { refresh: refreshToken },
          config
        );
        const accessToken = data.access;

        // Guardar el accessToken actualizado en el localStorage y el Redux store
        localStorage.setItem("accessToken", JSON.stringify(accessToken));
        // set token to axios interceptor
        setToken(data.access);
        // Set token for Redux store
        dispatch({ type: SUCCESS_LOGIN_USUARIO, payload: accessToken });
        // Volver a disparar la accion en la que ocurrio el error 401
        await dispatch(action(...parametrosOpcionales));
      } catch (error) {
        // Si el refresh token tambien ha caducado, entonces cerrar sesion y pedir al usuario que se vuelve a logear
        toast.error("Tu sesión ha caducado. Inicia sesión de nuevo por favor", {
          duration: 5000,
        });
        dispatch(logout());
      }
    };
