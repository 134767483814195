import { Form } from 'react-bootstrap';
import { styled } from 'styled-components';
import { StyledButton } from '../../general/styles/Boton.styles';

export const StyledFormContainer = styled.div`
  position: relative;
  grid-area: PanelControl;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  min-width: 300px;
  padding: 1rem 0.5rem;

  @media screen and (max-width: 900px){
    margin: 0;
    padding-top: 0;
    position: fixed;
    left: ${props => props.mostrarpanel ? '0':'-100vw'};
    z-index: 2;
    height: 90%;
    background: linear-gradient(
      rgb(54, 54, 82),
      15%,
      rgb(84, 106, 144),
      60%,
      rgb(68, 111, 151)
    );

    transition: ease all 0.5s;
  }
`;


export const StyledForm = styled(Form) `
  display: block;
  flex-direction: column;
  width: 90%;
  max-width: 650px;
  /* flex-direction: column;
  align-items: center;
  justify-content: center;  */
`;

export const StyledFormGroup = styled(Form.Group)`
 label {
  color: var(--font-color-label);
  font-weight: var(--font-weight-label);
  font-size: var(--font-size-label)-5px;
 }

 input,
 select {
  color: var(--font-color-input);
  font-weight: var(--font-weight-input);
  font-size: var(--font-size-input);
  margin-bottom: 0.5rem;
  height: 3.2rem;
 }
`;

export const StyledFormControlContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  input,
  select {
    width: 40%;
  };
`;

export const StyledBotonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StyledBoton = styled(StyledButton)`
  align-self: center;
  margin-top: 1rem;
  width: 50%;
  max-width: 200px;
  height: 35px;
  padding: 10px;
  font-weight: bold;
  font-size: 0.9rem;
  
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 768px) {
    min-width: 160px;
  }
`;