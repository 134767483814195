// Pedir lista de salida rutas
export const REQUEST_SALIDA_RUTA_LISTA = "REQUEST_SALIDA_RUTA_LISTA";
export const SUCCESS_SALIDA_RUTA_LISTA = "SUCCESS_SALIDA_RUTA_LISTA";
export const FAIL_SALIDA_RUTA_LISTA = "FAIL_SALIDA_RUTA_LISTA";
export const RESET_SALIDA_RUTA_LISTA = "RESET_SALIDA_RUTA_LISTA";

// Pedir un solo salida ruta
export const REQUEST_SALIDA_RUTA_DETALLES = "REQUEST_SALIDA_RUTA_DETALLES";
export const SUCCESS_SALIDA_RUTA_DETALLES = "SUCCESS_SALIDA_RUTA_DETALLES";
export const FAIL_SALIDA_RUTA_DETALLES = "FAIL_SALIDA_RUTA_DETALLES";
export const RESET_SALIDA_RUTA_DETALLES = "RESET_SALIDA_RUTA_DETALLES";

// Actualizar un salida ruta
// ESTA LA DEJO PARA CAMBIAR EL STATUS
export const REQUEST_SALIDA_RUTA_ACTUALIZAR = "REQUEST_SALIDA_RUTA_ACTUALIZAR";
export const SUCCESS_SALIDA_RUTA_ACTUALIZAR = "SUCCESS_SALIDA_RUTA_ACTUALIZAR";
export const FAIL_SALIDA_RUTA_ACTUALIZAR = "FAIL_SALIDA_RUTA_ACTUALIZAR";
export const RESET_SALIDA_RUTA_ACTUALIZAR = "RESET_SALIDA_RUTA_ACTUALIZAR";

// Registrar un nuevo salida ruta
export const REQUEST_SALIDA_RUTA_REGISTRAR = "REQUEST_SALIDA_RUTA_REGISTRAR";
export const SUCCESS_SALIDA_RUTA_REGISTRAR = "SUCCESS_SALIDA_RUTA_REGISTRAR";
export const FAIL_SALIDA_RUTA_REGISTRAR = "FAIL_SALIDA_RUTA_REGISTRAR";
export const RESET_SALIDA_RUTA_REGISTRAR = "RESET_SALIDA_RUTA_REGISTRAR";

// No es posible borrar una salida ruta
// Realizar venta de salida a ruta
export const REQUEST_SALIDA_RUTA_VENTA = "REQUEST_SALIDA_RUTA_VENTA";
export const SUCCESS_SALIDA_RUTA_VENTA = "SUCCESS_SALIDA_RUTA_VENTA";
export const FAIL_SALIDA_RUTA_VENTA = "FAIL_SALIDA_RUTA_VENTA";
export const RESET_SALIDA_RUTA_VENTA = "RESET_SALIDA_RUTA_VENTA";

export const REQUEST_SALIDA_RUTA_AVISO_VISITA =
  "REQUEST_SALIDA_RUTA_AVISO_VISITA";
export const SUCCESS_SALIDA_RUTA_AVISO_VISITA =
  "SUCCESS_SALIDA_RUTA_AVISO_VISITA";
export const FAIL_SALIDA_RUTA_AVISO_VISITA = "FAIL_SALIDA_RUTA_AVISO_VISITA";
export const RESET_SALIDA_RUTA_AVISO_VISITA = "RESET_SALIDA_RUTA_AVISO_VISITA";

export const REQUEST_SALIDA_RUTA_DEVOLUCION = "REQUEST_SALIDA_RUTA_DEVOLUCION";
export const SUCCESS_SALIDA_RUTA_DEVOLUCION = "SUCCESS_SALIDA_RUTA_DEVOLUCION";
export const FAIL_SALIDA_RUTA_DEVOLUCION = "FAIL_SALIDA_RUTA_DEVOLUCION";
export const RESET_SALIDA_RUTA_DEVOLUCION = "RESET_SALIDA_RUTA_DEVOLUCION";

export const REQUEST_SALIDA_RUTA_RECARGA = "REQUEST_SALIDA_RUTA_RECARGA";
export const SUCCESS_SALIDA_RUTA_RECARGA = "SUCCESS_SALIDA_RUTA_RECARGA";
export const FAIL_SALIDA_RUTA_RECARGA = "FAIL_SALIDA_RUTA_RECARGA";
export const RESET_SALIDA_RUTA_RECARGA = "RESET_SALIDA_RUTA_RECARGA";
