import { useState, useEffect } from "react";

export const DAY_WEEK = [
  "DOMINGO",
  "LUNES",
  "MARTES",
  "MIERCOLES",
  "JUEVES",
  "VIERNES",
  "SABADO",
];

// Obtener el dia actual para seleccionarlo por default en el formulario de salida ruta
const currentDate = new Date();
const currentDayIndex = currentDate.getDay();
const currentDayName = DAY_WEEK[currentDayIndex];

const getDefaultSalidaRuta = () => ({
  // rutaDayId es el valor que se debe guardar en el localStorage para enviar posteriormente al backend. Cada salidaRuta puede tener una RUTA, que es en realidad un ruta dia.
  rutaDayId: "",
  // El rutaNombre y rutaDay solo sirven para que el usuario seleccione la ruta dia usando nombre y dia y luego filtramos para encontrar la rutaDayId correspondiente
  rutaNombre: "",
  rutaDay: currentDayName,
  // Clientes seleccionados en la salidaRuta
  clientes: [],
  repartidorId: "",
  observaciones: "",
});

// Custom Hook para manejar el estado de la salidaRuta
export const useSalidaRuta = (clientes, rutas) => {
  // Hook para manejar el estado de la salidaRuta
  // La funcion no se llama en el useState, solo se coloca la funcion como tal
  const [salidaRuta, setSalidaRuta] = useState(getDefaultSalidaRuta);

  // Hook para manejar el estado de los clientes disponibles para ser seleccionados
  const [clientesFormulario, setClientesFormulario] = useState([]);

  // Cuando los clientes cambian seleccionar todos los clientes como disponibles para escoger
  useEffect(() => {
    if (!clientes) return;

    setClientesFormulario(clientes);
  }, [clientes]);

  // Cuando se cambia el dia o el nombre de la ruta, todos los campos de la ruta y los productos del formulario se modifican
  const manejarModificarSalidaRuta = (nombre, day) => {
    // Si el usuario deselecciona el nombre de la ruta en el formulario regresar todo a su valor por default
    if (!nombre) {
      setSalidaRuta(getDefaultSalidaRuta());
      // No se porue esto estaba comentado, pero si debe resetear los clientes disponibles en el formulario a TODOS los clientes del Redux store
      setClientesFormulario(clientes);
      return;
    }

    // Si el usuario selecciono un nombre y dia, obtener rutaDiaId y repartidorId
    // Esta funcion toma todas las rutas y encuentra la rutaDayId, y repartidorId con el nombre y dia dados
    const { rutaDayId, repartidorId } =
      findRutaDiaIdAndRepartidorIdByNombreAndDia(nombre, day, rutas);

    // Con la rutaDayId podemos buscar los clientes que tienen esa ruta.
    // Esta funcion separa los clientes en clientes con la rutaDayId y el resto de clientes. Los clientes de la salidaRuta son los que tiene la rutaDayId y el resto son los clientes disponibles que el usuario puede seleccionar
    const [clientesSalidaRuta, clientesDisponibles] = filterClientesByRutaDiaId(
      clientes,
      rutaDayId
    );

    // Establecer clientes del formulario que pueden ser seleccionados
    setClientesFormulario(clientesDisponibles);

    // Establecer ruta con los clientes y repartidorId que obtuvimos en los dos filtros anteriores
    setSalidaRuta((prevSalidaRuta) => ({
      ...prevSalidaRuta,
      rutaDayId,
      rutaNombre: nombre,
      rutaDay: day,
      clientes: clientesSalidaRuta,
      repartidorId,
    }));
  };

  // Esta funcion toma el id del nuevo repartidor y lo asigna como el nuevo repartidorId
  const manejarModificarRepartidor = (newRepartidorId) => {
    setSalidaRuta((prevSalidaRuta) => ({
      ...prevSalidaRuta,
      repartidorId: newRepartidorId,
    }));
  };

  // Funcion para seleccionar el cliente
  const manejarSeleccionarCliente = (clienteId) => {
    // Cliente seleccionado
    const clienteSeleccionado = clientesFormulario.find(
      (c) => c.id === clienteId
    );

    // Cuando se selecciona un cliente su status de confirmado debe ser false
    const clienteActualizado = { ...clienteSeleccionado, confirmado: false };

    // Crear la nueva lista de clientes disponibles
    const nuevosClientesDisponibles = clientesFormulario.filter(
      (c) => c.id !== clienteId
    );
    // Actualizar el estado de clientes disponibles
    setClientesFormulario(nuevosClientesDisponibles);

    // Actualizar clientes salida ruta
    const newClientes = [clienteActualizado, ...salidaRuta.clientes];

    setSalidaRuta((prevSalidaRuta) => ({
      ...prevSalidaRuta,
      clientes: newClientes,
    }));
  };

  // Funcion para modificar status del cliente de la lista de clientes de salida a ruta
  const manejarModificarStatusCliente = (clienteId) => {
    const newClientes = salidaRuta.clientes.map((c) => {
      if (c.id === clienteId) {
        c.confirmado = !c.confirmado;
      }

      return c;
    });

    setSalidaRuta((prevSalidaRuta) => ({
      ...prevSalidaRuta,
      clientes: newClientes,
    }));
  };

  // Funcion para cancelar el cliente de la lista de clientes seleccionados
  const manejarCancelarCliente = (clienteId) => {
    // Encontrar el cliente a remover de salida ruta
    const clienteSeleccionado = salidaRuta.clientes.find(
      (c) => c.id === clienteId
    );

    const clienteActualizado = { ...clienteSeleccionado, confirmado: false };

    // Remover el cliente de salida ruta
    const newClientes = salidaRuta.clientes.filter(
      (cliente) => cliente.id !== clienteId
    );

    // Actualizar clientes salida ruta
    setSalidaRuta((prevSalidaRuta) => ({
      ...prevSalidaRuta,
      clientes: newClientes,
    }));

    // Agregar el cliente al formulario
    const nuevosClientesDisponibles = [
      clienteActualizado,
      ...clientesFormulario,
    ];
    setClientesFormulario(nuevosClientesDisponibles);
  };

  const manejarModificarObservaciones = (newObservaciones) => {
    setSalidaRuta((prevSalidaRuta) => ({
      ...prevSalidaRuta,
      observaciones: newObservaciones,
    }));
  };

  // Cada vez que salidaRuta se modifica esta funcion se llama y regresa isDisabled
  const isValid = getIsValid(salidaRuta);

  return {
    // Salida ruta
    salidaRuta,
    manejarModificarSalidaRuta,

    // Repartidor
    manejarModificarRepartidor,

    // Clientes
    clientesFormulario,
    manejarSeleccionarCliente,
    manejarCancelarCliente,
    manejarModificarStatusCliente,

    // Otros
    manejarModificarObservaciones,
    isValid,
  };
};

function filterClientesByRutaDiaId(clientes, ruta_dia_id) {
  let clientesSalidaRuta = clientes.filter((cliente) =>
    cliente.ruta_dia_ids.includes(ruta_dia_id)
  );

  clientesSalidaRuta = clientesSalidaRuta.map((cliente) => ({
    ...cliente,
    confirmado: true,
  }));

  const clientesDisponibles = clientes.filter(
    (cliente) => !cliente.ruta_dia_ids.includes(ruta_dia_id)
  );

  return [clientesSalidaRuta, clientesDisponibles];
}

function findRutaDiaIdAndRepartidorIdByNombreAndDia(NOMBRE, DIA, routes) {
  const route = routes.find((route) => route.NOMBRE === NOMBRE);

  if (!route) {
    return null; // Return null if the NOMBRE is not found
  }

  const rutaDia = route.ruta_dias.find((ruta) => ruta.DIA === DIA);

  if (!rutaDia) {
    return null; // Return null if the DIA is not found
  }

  return { rutaDayId: rutaDia.id, repartidorId: rutaDia.repartidor_id }; // Return the id for the given DIA
}

// Funcion para desabilitar el boton para continuar con la salida a ruta
const getIsValid = (salidaRuta) => {
  const clientesSalidaRuta = salidaRuta.clientes;

  return (
    clientesSalidaRuta.length > 0 &&
    clientesSalidaRuta.every((p) => p.confirmado) &&
    salidaRuta.repartidorId
  );
};
