import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import FormularioProductoVenta from "../componentes/RealizarVentaSalidaRuta/FormularioProductoVenta";
import FormularioVenta from "../componentes/RealizarVentaSalidaRuta/FormularioVenta";
import {
  StyledBoton,
  StyledBotonPanel,
  StyledCol,
  StyledContainer,
  StyledContenidoPrincipal,
  StyledGridContainer,
  StyledPanelControl,
  StyledRow,
  StyledTitulo,
} from "./styles/RealizarVentaSalidaRuta.styles";
import VentanaMostrarVenta from "../componentes/RealizarVentaSalidaRuta/VentanaMostrarVenta";

import Loader from "../componentes/general/Loader";
import Mensaje from "../componentes/general/Mensaje";

import { obtenerSalidaRutaDetalles } from "../actions/salidaRutaActions";
import { useClienteVentaSalidaRuta } from "../lib/hooks/useClienteVentaSalidaRuta";
import { useProductosVentaSalidaRuta } from "../lib/hooks/useProductosVentaSalidaRuta";
import toast from "react-hot-toast";
import { RESET_SALIDA_RUTA_DETALLES } from "../constantes/salidaRutaConstantes";

const RealizarVentaSalidaRuta = () => {
  // Obtener el id de la salida ruta
  const params = useParams();
  const salidaRutaId = params.id;

  // Obtener referencias de los botones
  const regresarRef = useRef(null);

  // Funcion para disparar los creadores de acciones
  const dispatch = useDispatch();

  // Funcion para navegar en la aplicacion
  const navigate = useNavigate();

  // Obtener la salida ruta desde el Redux store
  const salidaRutaDetalles = useSelector((state) => state.salidaRutaDetalles);
  const { error, salidaRuta, loading } = salidaRutaDetalles;

  // Obtener referencia de botones
  const botonPMRef = useRef(null);

  // Hooks para mostrar resultados de la venta
  const [mostrarVenta, setMostrarVenta] = useState(false);
  // const [imprimirTicket, setImprimirTicket] = useState(false);
  const [venta, setVenta] = useState({});
  // Hook para mostrar y ocultar panel de control en pantallas pequenas
  const [mostrarPanel, setMostrarPanel] = useState(true);

  // Hook personalizado para manejar el estado del cliente y su lista de precios
  const {
    // Esto lo metere en un objeto
    cliente,
    clientesDisponibles,
    productosCliente,
    manejarCambiarCliente,
    manejarCambiarProductosCliente,
    //
  } = useClienteVentaSalidaRuta(salidaRuta);

  const {
    productosVenta,
    manejarLimpiarProductosVenta,
    manejarSeleccionarProducto,
    manejarCambioCantidad,
    manejarConfirmarProducto,
    manejarCancelarProducto,
    isValid,
  } = useProductosVentaSalidaRuta(
    productosCliente,
    manejarCambiarProductosCliente
  );

  useEffect(() => {
    if (!salidaRuta || salidaRuta.id !== Number(salidaRutaId)) {
      dispatch(obtenerSalidaRutaDetalles(salidaRutaId));
    }
  }, [dispatch, salidaRuta, salidaRutaId, navigate]);

  useEffect(() => {
    return () => {
      toast.dismiss();
    };
  }, []);

  const manejarRegresar = () => {
    dispatch({ type: RESET_SALIDA_RUTA_DETALLES });
    navigate("/salida-rutas");
  };

  // Renderizar componente loading si se estan cargando salida ruta
  if (loading) {
    return (
      <Loader />
    );
  }

  // Renderizar mensaje de error si el servidor regresa un error al cargar salida ruta
  if (error) {
    return (
      <StyledContainer fluid>
        <StyledRow>
          <StyledCol>
            <Mensaje variant="danger">
              Hubo un error al cargar la salida ruta
            </Mensaje>
          </StyledCol>
        </StyledRow>
      </StyledContainer>
    );
  }

  return (
    salidaRuta && (
      <>
        <StyledGridContainer>
          <StyledBotonPanel
            color="standard"
            fontcolor={"var(--active-blue-button)"}
            ref={botonPMRef}
            onFocus={() => botonPMRef.current.blur()}
            onClick={() => setMostrarPanel((state) => !state)}
            state={mostrarPanel}
          >
            <i className="fa-solid fa-arrow-right" />
          </StyledBotonPanel>

          <StyledTitulo>Realizar Venta</StyledTitulo>

          {/*Panel de control para registrar datos de ventas*/}
          <StyledPanelControl mostrarpanel={mostrarPanel}>
            <FormularioVenta
              cliente={cliente}
              manejarCambiarCliente={manejarCambiarCliente}
              productosVenta={productosVenta}
              manejarLimpiarProductosVenta={manejarLimpiarProductosVenta}
              clientesDisponibles={clientesDisponibles}
              manejarSeleccionarProducto={manejarSeleccionarProducto}
              productosCliente={productosCliente}
              setVenta={setVenta}
              setMostrarVenta={setMostrarVenta}
              deshabilitarVenta={!isValid}
            />
          </StyledPanelControl>
          {/*Contenido Principal*/}
          <StyledContenidoPrincipal>
            <FormularioProductoVenta
              productos={productosVenta}
              manejarCambioCantidad={manejarCambioCantidad}
              manejarConfirmarProducto={manejarConfirmarProducto}
              manejarCancelarProducto={manejarCancelarProducto}
            />
          </StyledContenidoPrincipal>
        </StyledGridContainer>

        {/* Mostrar Venta */}
        {mostrarVenta && (
          <VentanaMostrarVenta
            venta={venta}
            salidaRutaId={salidaRutaId}
            mostrarVenta={mostrarVenta}
            setMostrarVenta={setMostrarVenta}
            productosVenta={productosVenta}
            cliente={cliente}
          />
        )}
      </>
    )
  );
};

export default RealizarVentaSalidaRuta;
