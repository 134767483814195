import React, { useRef, useState } from "react";

// Componentes
import { StyledTable, StyledBoton } from "./styles/TablaClientes.styles";
import VentanaAvisoVisita from "./VentanaAvisoVisita";

const TablaClientes = ({ salidaRuta, clientes }) => {
  // Funcion para navegar en el dom

  // Obtener referencia del boton
  const avisoRef = useRef(null);

  const [cliente, setCliente] = useState({});
  const [mostrarVenta, setMostrarVenta] = useState(false);

  const manejarAvisoVisita = (clienteId) => {
    const clienteSeleccionado = { ...clientes.find((c) => c.id === clienteId) };

    setCliente(clienteSeleccionado);
    setMostrarVenta(true);
  };

  // Verificar si es administrador para renderizar algunas propiedades
  const isAdmin = JSON.parse(localStorage.getItem("isAdmin"));

  // Verificar si es repartidor
  const role = JSON.parse(localStorage.getItem("role"));
  return (
    <>
      {salidaRuta && (
        <StyledTable striped hover>
          <thead>
            <tr>
              <th>NOMBRE</th>
              <th>STATUS</th>
              <th>AVISO DE VISITA</th>
            </tr>
          </thead>

          <tbody>
            {clientes.map((cliente) => {
              const { id, CLIENTE_NOMBRE, STATUS } = cliente;

              return (
                <tr key={id}>
                  <td>{CLIENTE_NOMBRE}</td>
                  <td>{STATUS}</td>
                  <td>
                    <StyledBoton
                      color="standard"
                      fontcolor={"var(--active-blue-button)"}
                      ref={avisoRef}
                      disabled={
                        !(
                          (isAdmin || role === "REPARTIDOR") &&
                          salidaRuta.STATUS === "PROGRESO" &&
                          cliente.STATUS === "PENDIENTE"
                        )
                      }
                      onFocus={() => avisoRef.current.blur()}
                      onClick={() => manejarAvisoVisita(id)}
                    >
                      <i className="fa fa-address-card" />
                    </StyledBoton>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </StyledTable>
      )}

      <VentanaAvisoVisita
        cliente={cliente}
        salidaRuta={salidaRuta}
        mostrarVenta={mostrarVenta}
        setMostrarVenta={setMostrarVenta}
      />
    </>
  );
};

export default TablaClientes;
